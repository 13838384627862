import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import constants from '../../../common/constants';
import type { ThreatStatus } from '../../../threat.types';
import MultiselectFilter from './MultiselectFilter';

type StatusFiltersProps = {
  values: ThreatStatus[] | undefined;
  onChange: OnApply;
  onClear: OnClear;
  label?: string;
};

const THREAT_STATUS_LIST_ORDER = {
  [constants.THREAT_STATUS.NOT_QUARANTINED]: 0,
  [constants.THREAT_STATUS.QUARANTINED]: 1,
  [constants.THREAT_STATUS.RESOLVED]: 2,
  [constants.THREAT_STATUS.RELEASED]: 3,
} as const;

const options = constants.THREAT_STATUS_LIST.map((value) => ({
  key: value,
  value,
  label: constants.THREAT_STATUS_DISPLAY_NAME_MAP[value],
  icon: constants.THREAT_STATUS_ICON_MAP[value],
  order: THREAT_STATUS_LIST_ORDER[value],
})).sort((a, b) => a.order - b.order);

const StatusFilter = (props: StatusFiltersProps) => {
  const {
    values: newValues,
    onChange,
    onClear,
    label = 'Threat status',
  } = props;

  return (
    <MultiselectFilter
      name="status"
      label={label}
      values={newValues}
      onChange={onChange}
      onClear={onClear}
      options={options}
      maxWidth={280}
      optionCss={{
        '& > div': { gap: '$2' },
        '& > div div': { gap: 6 },
        '&[data-disabled] [role=label] svg': { opacity: 0.5 },
        '&:nth-child(1) [role=label] svg': { color: '$red50' },
        '&:nth-child(2) [role=label] svg': { color: '$blue50' },
        '&:nth-child(3) [role=label] svg': { color: '$green50' },
        '&:nth-child(4) [role=label] svg': { color: '$neutral70' },
      }}
    />
  );
};

export default StatusFilter;
