import type {
  AnyType,
  BoolFilterTypeProps,
  EnumFilterTypeProps,
  FIXME_Any,
  IconComponentProps,
} from '@kandji-inc/nectar-ui';
import {
  Button,
  Flex,
  Table as NectarTable,
  ScrollContainer,
  Tbody,
  Thead,
  Tr,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import type {
  ColumnDefBase,
  ColumnOrderState,
  OnChangeFn,
  PaginationState,
  SortingState,
  TableState,
  VisibilityState,
} from '@tanstack/react-table';
import {
  functionalUpdate,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { i18n } from 'i18n';
/* istanbul ignore file */
// Not sure how to test isDefaultDirty functionality
import * as React from 'react';
import { useHistory, useParams } from 'react-router';

import type { UseQueryResult } from '@tanstack/react-query';
import { xor } from 'lodash';
import { useToggleResizingClass } from 'src/components/table/hooks';
import { Pagination } from 'src/components/ui';
import { useFlags } from 'src/config/feature-flags';
import { InterfaceContext } from 'src/contexts/interface';
import { usePrismUrlContext } from '../../contexts/PrismUrlContext';
import type { GlobalFilters } from '../../hooks';
import {
  useCreatePrismView,
  useDeletePrismView,
  useUpdatePrismView,
} from '../../hooks';
import { getDisplayName } from '../../implemented-category-uris';
import type {
  PrismCategorySchema,
  PrismCategoryTitle,
  PrismCategoryUri,
} from '../../types/prism.types';
import {
  TextCell,
  getHeaderDisplayNameFromMeta,
} from '../../utils/column-helpers/column-utils';
import PrismCategoryFilters from './PrismCategoryFilters';
import PrismColumnEditor from './PrismColumnEditor';
import PrismNavCollapseButton from './PrismNavCollapseButton';
import PrismSavedViewsActionMenu from './PrismSavedViewsActionMenu';
import { PrismExport } from './components/PrismExport';
import PrismTableBody from './components/PrismTableBody';
import PrismTableEmpty from './components/PrismTableEmpty';
import PrismTableHeader from './components/PrismTableHeader';
import PrismViewCreateDropdown from './components/PrismViewCreateDropdown';
import PrismViewRenameModal from './components/PrismViewRenameModal';
import PrismViewSaveDropdown from './components/PrismViewSaveDropdown';
import TableTitle from './components/TableTitle';
import { usePrismPendo } from './hooks/use-prism-pendo';
import { useSavedViewsQuery } from './hooks/use-saved-views-query';
import type { SavedView } from './hooks/use-saved-views-query';
import { useColumns } from './hooks/use-table-columns';
import { useTableState } from './hooks/use-table-state';
import { getNectarExpectedRelativeValue } from './utils/prismFiltersUtils';
import {
  getColumnDefinitionDefaults,
  getTableCss,
  validateViewName,
} from './utils/tableUtils';

declare module '@tanstack/react-table' {
  interface Table<TData> {
    getState: <TState>() => TableState & TState;
    setColumnFilters: <TColumnFilters>(
      update: TColumnFilters | ((prev: TColumnFilters) => TColumnFilters),
    ) => void;
  }

  interface TableMeta<TData> {
    /**
     * Determines fixed columns based on each pair of `column.id` key
     * and `columnDef` config value to be merged with the column's original
     * `columnDef` properties.
     */
    fixedColumns?: Record<string, ColumnDefBase<TData>>;
    /**
     * Determines frozen columns based on each pair of `column.id` key
     * and `columnDef` config value to be merged with the column's original
     * `columnDef` properties.
     */
    frozenColumns?: Record<string, ColumnDefBase<TData>>;
    /**
     * Determines globally hidden columns based on each pair of `column.id` key
     * and `columnDef` config value to be merged with the column's original
     * `columnDef` properties.
     */
    alwaysHiddenColumns?: Record<string, boolean>;
    /**
     * Whether the table state has changed from the default state as defined by
     * its column definitions for column order, visibility, sizing, etc.
     */
    isDefaultDirty?: boolean;
    /**
     * State setter for `isDefaultDirty`.
     */
    setIsDefaultDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  type ColumnMetaFilterType =
    | 'string'
    | 'number'
    | 'time'
    | 'boolean'
    | 'enum'
    | 'date-time'
    | 'dict'
    | 'version';
  type ColumnMetaFilterTypeValueID =
    | '$meta.filterType.string'
    | '$meta.filterType.number'
    | '$meta.filterType.time'
    | '$meta.filterType.boolean'
    | '$meta.filterType.enum'
    | '$meta.filterType.date-time'
    | '$meta.filterType.dict'
    | '$meta.filterType.version';

  type DeviceFamily = 'Mac' | 'iPhone' | 'iPad' | 'AppleTV' | 'all';

  interface ColumnMeta<TData, TValue extends ColumnMetaFilterTypeValueID> {
    /**
     * Display name for showing in the column header.
     */
    displayName?: string;
    /**
     *  Whether this column is hidden by default on initial table load.
     */
    defaultHidden?: boolean;
    /**
     * Whether this column is always visually hidden in the table while
     * allowing its associated data to be used in table operations such as
     * filtering and exporting.
     */
    alwaysHidden?: boolean;
    /**
     * The device family supported by this column.
     */
    deviceFamily?: DeviceFamily[];
    /**
     * The type of filter operation for this column
     */
    filterType?: ColumnMetaFilterType;
    /**
     * The icon to display next to the filter menu button.
     */
    filterIcon?: IconComponentProps['name'];
    /**
     * The priority of this filter in the filter menu for determining filter
     * menu sections. Lower number means higher priority.
     */
    filterMenuPriority?: number;
    /**
     * Whether to show the header popover menu for additional header actions.
     */
    hideThMenu?: boolean;
    /**
     * Function for determining if the filter should be disabled.
     */
    filterDisabled?: (...args: AnyType[]) => boolean;
    /**
     * The options for the given filter operation type (currently `boolean` or
     * `enum` filter types).
     */
    filterOptions?: TValue extends '$meta.filterType.boolean'
      ? BoolFilterTypeProps['boolOptions']
      : TValue extends '$meta.filterType.enum'
        ? EnumFilterTypeProps['enumOptions']
        :
            | BoolFilterTypeProps['boolOptions']
            | EnumFilterTypeProps['enumOptions']
            | undefined;
  }
}

type PrismTableProps = {
  data: FIXME_Any;
  columns: {
    columnDefs: FIXME_Any;
    filterableColumns: {
      filterOptions: {
        value: PrismCategoryUri;
        label: string;
      }[];
    };
  };
  isPaidCategory: boolean;
  uri: PrismCategoryUri | undefined;
  isLoading: boolean;
  prismNavOpen: boolean;
  globalFilters: GlobalFilters;
  computerCount: number | undefined;
  paginationState: PaginationState;
  title: PrismCategoryTitle | undefined;
  togglePrismNavOpen: () => void;
  setEditViewModalOpen: () => void;
  onPaginationChange: OnChangeFn<PaginationState>;
  prismCategorySchema: PrismCategorySchema | undefined;
  sortingState: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
  countQuery: UseQueryResult<
    {
      count: number;
      approximate: boolean;
    },
    Error
  >;
};

const PrismTable = (props: PrismTableProps) => {
  const {
    columns: { columnDefs },
    title = '',
    isPaidCategory,
    data,
    uri,
    isLoading,
    prismNavOpen,
    computerCount,
    globalFilters,
    paginationState: pagination,
    togglePrismNavOpen,
    setEditViewModalOpen,
    onPaginationChange,
    prismCategorySchema,
    sortingState,
    onSortingChange,
    countQuery,
  } = props;

  const routeParams = useParams<{ prismCategory: string }>();
  const savedViewsQuery = useSavedViewsQuery(routeParams.prismCategory);
  const { data: countData, isPending: isCountPending } = countQuery;

  const {
    filter,
    resetFilter,
    blueprints,
    deviceFamilies,
    setViewId,
    viewId,
    replaceFilter,
  } = usePrismUrlContext();

  const columns = useColumns(
    uri || routeParams.prismCategory,
    prismCategorySchema,
  );

  const history = useHistory();

  const { sidebarOpened, bannerTopOffset } = React.useContext(InterfaceContext);

  const toastOffset = React.useMemo(
    () => (sidebarOpened ? '276px' : '98px'),
    [sidebarOpened],
  );

  const [appliedSavedView, setAppliedSavedView] =
    React.useState<SavedView | null>(null);

  const {
    columnFilters,
    columnVisibility,
    columnOrder,
    columnSizing,
    columnSizingInfo,
    isDefaultDirty,
    setColumnFilters,
    setColumnVisibility,
    setColumnOrder,
    setColumnSizing,
    setColumnSizingInfo,
    setIsDefaultDirty,
  } = useTableState(columnDefs, appliedSavedView?.columns);

  const [saveViewOpen, setSaveViewOpen] = React.useState<boolean>(false);
  const [renameViewOpen, setRenameViewOpen] = React.useState<boolean>(false);
  const [viewName, setViewName] = React.useState<string>('');
  const [viewNameError, setViewNameError] = React.useState<string>('');

  const { 'visibility-deprecate-count': ffDeprecateCount } = useFlags([
    'visibility-deprecate-count' as never,
  ]);

  const count = ffDeprecateCount ? countQuery.data?.count : data?.total;

  const table = useReactTable({
    data: data?.data || [],
    columns: columns.columnDefs,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    manualFiltering: true,
    manualPagination: true,
    pageCount: count ? Math.ceil(count / pagination.pageSize) : 1,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onColumnSizingInfoChange: setColumnSizingInfo,
    onPaginationChange,
    onSortingChange: (updater) => {
      const newSorting = functionalUpdate(updater, sortingState);
      onSortingChange(newSorting);
    },
    state: {
      columnFilters,
      columnVisibility,
      columnOrder,
      columnSizing,
      columnSizingInfo,
      pagination,
      sorting: sortingState,
    },
    defaultColumn: {
      header: getHeaderDisplayNameFromMeta,
      cell: TextCell,
      footer: (info) => info.column.id,
      size: 150,
      minSize: 100,
      maxSize: 750,
    },
    meta: {
      fixedColumns: {
        device__name: {
          size: 200,
          minSize: 150,
        },
      },
      alwaysHiddenColumns: {
        device__id: true,
        device__user_id: true,
      },
      isDefaultDirty,
      setIsDefaultDirty,
    },
  });

  const applySavedView = React.useCallback(
    (view: SavedView) => {
      const {
        columnFilters: savedColumnFilters,
        columnOrder: savedColumnOrder,
        columnSizing: savedColumnSizing,
        columnVisibility: savedColumnVisibility,
        // sort_by,
      } = view;

      if (savedColumnFilters) {
        const tableColumns = table.getAllColumns();
        const columnFiltersWithFilterType = savedColumnFilters.map((filter) => {
          const matchingColumn = tableColumns?.find(
            (col) => col.id === filter.columnKey,
          ) as FIXME_Any;
          const filterType = matchingColumn?.columnDef?.meta?.filterType;
          const filterLabel = matchingColumn?.columnDef?.meta?.displayName;

          const constructedFilter: any = {
            key: filter.columnKey,
            type: filterType,
            label: filterLabel,
            operator: Object.keys(filter.value)[0],
            value: Object.values(filter.value)[0],
            id: filter.columnKey,
            boolOptions: [],
            enumOptions: [] as { label: string; value: string }[],
          };

          if (constructedFilter.type === 'boolean') {
            const boolOptions = matchingColumn?.columnDef?.meta?.filterOptions;
            constructedFilter.boolOptions = boolOptions;
          }
          if (constructedFilter.type === 'enum') {
            const values = (constructedFilter?.value as string[]) || [];
            if (values.length) {
              constructedFilter.enumOptions = values.map((val) => ({
                label: val,
                value: val,
              }));
            }
          }
          if (constructedFilter.type === 'date-time') {
            if (constructedFilter.operator === 'or') {
              // shim for prismFilterUtils
              constructedFilter.operator = 'dne';
              const { filters } = filter;
              constructedFilter.selectedDate = new Date(
                filters?.or?.lt as string,
              );
              constructedFilter.value = 'custom_date_range';
            } else if (constructedFilter.operator === 'relative') {
              const { value } = constructedFilter;
              const valueExpectedByNectar = getNectarExpectedRelativeValue(
                value as string,
              );
              constructedFilter.value = valueExpectedByNectar;
              constructedFilter.operator = 'eq';
            } else {
              constructedFilter.value = 'custom_date_range';
              if (Object.keys(filter?.filters).length <= 1) {
                const { operator } = constructedFilter;
                const selectedDate = new Date(
                  filter?.filters?.[operator] as string,
                );
                constructedFilter.selectedDate = new Date(selectedDate);
              } else {
                const from = new Date(filter?.filters?.gte as string);
                const to = new Date(filter?.filters?.lte as string);
                constructedFilter.dateRange = { from, to };
                constructedFilter.selectedDate = null;
                // doing this to differentiate between "is in" and "is between"
                const diffInMilliseconds = Math.abs(
                  from.getTime() - to.getTime(),
                );
                const diffInHours = Math.ceil(
                  diffInMilliseconds / (1000 * 60 * 60),
                );
                const areDatesLessThan24HoursApart = diffInHours <= 24;
                if (areDatesLessThan24HoursApart) {
                  constructedFilter.operator = 'eq';
                  constructedFilter.selectedDate = new Date(from);
                } else {
                  constructedFilter.operator = 'ib';
                }
              }
            }
          }
          return constructedFilter;
        });
        table.setColumnFilters(columnFiltersWithFilterType);

        const formatForSetting = savedColumnFilters.reduce((acc, curr) => {
          const { value, columnKey } = curr;
          return {
            ...acc,
            [columnKey]: value,
          };
        }, {});

        // there is a weird instance where a user could add additional filters while viewing a saved view,
        // and then refresh the page and those additional filters disappear

        replaceFilter(formatForSetting);
      }

      if (savedColumnOrder) {
        table.setColumnOrder(savedColumnOrder);
      }

      if (savedColumnSizing) {
        table.setColumnSizing(savedColumnSizing);
      }

      if (savedColumnVisibility) {
        table.setColumnVisibility(savedColumnVisibility);
      }

      // TODO: handle sort_by
      // if (sort_by) {
      //   onSortingChange(sort_by);
      // }

      setAppliedSavedView(view);
    },
    [replaceFilter, table],
  );

  React.useEffect(() => {
    setAppliedSavedView(null);
  }, [uri]);

  React.useEffect(() => {
    if (savedViewsQuery.data && viewId) {
      const selectedView = savedViewsQuery.data.find(
        (view) => view.id === viewId,
      );
      if (selectedView && selectedView?.id !== appliedSavedView?.id) {
        applySavedView(selectedView);
      }
    } else {
      setAppliedSavedView(null);
    }
  }, [appliedSavedView?.id, applySavedView, savedViewsQuery.data, viewId]);

  useToggleResizingClass(columnSizingInfo.isResizingColumn);

  const { rows } = table.getRowModel();
  const headerGroups = table.getHeaderGroups();
  const allColumns = table.getAllColumns();

  usePrismPendo({
    attributeKey: uri,
    columnVisibility,
    columnOrder,
    columnSizing,
    columnSizingInfo,
  });

  const css = React.useMemo(
    () => getTableCss({ bannerHeight: bannerTopOffset ?? 0 }),
    [bannerTopOffset],
  );

  const columnDefinitionDefaults = () =>
    getColumnDefinitionDefaults(
      table.options.columns,
      appliedSavedView?.columns,
    );
  const handleUpdateColumns = ({
    columnOrder: order,
    columnVisibility: visibility,
  }: {
    columnOrder: ColumnOrderState;
    columnVisibility: VisibilityState;
  }) => {
    table.setColumnOrder(order);
    table.setColumnVisibility(visibility);
  };

  const handleNavigateToAddDevice = React.useCallback(() => {
    history.push('/add-devices');
  }, [history]);

  const resetTableViewState = React.useCallback(() => {
    if (!appliedSavedView) {
      resetFilter();
      table.setColumnSizing(columnDefinitionDefaults().columnSizing);
      table.setColumnOrder(columnDefinitionDefaults().columnOrder);
      table.setColumnVisibility(columnDefinitionDefaults().columnVisibility);
    } else {
      applySavedView(appliedSavedView);
    }
  }, [appliedSavedView, table, resetFilter, applySavedView]);

  const { toast } = useToast();

  const createPrismView = useCreatePrismView({
    onSuccess: (newView) => {
      toast({
        title: 'Success!',
        content: `View ${newView.name} is now available as a saved view in ${title}.`,
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      setSaveViewOpen(false);
      if (newView.id) {
        setViewId(newView.id);
      }
    },
  });

  const renamePrismView = useUpdatePrismView({
    onSuccess: (view) => {
      toast({
        title: 'Success!',
        content: 'Display name has been updated.',
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      setRenameViewOpen(false);
      if (view.id) {
        setViewId(view.id);
      }
    },
  });

  const updatePrismView = useUpdatePrismView({
    onSuccess: (view) => {
      toast({
        title: 'Success!',
        content: `${view.name} has been updated.`,
        variant: 'success',
        style: {
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
      setViewName('');
      setSaveViewOpen(false);
      if (view.id) {
        setViewId(view.id);
      }
    },
  });

  const { 'visibility-fleet-attributes': ffPhaseOne } = useFlags([
    'visibility-fleet-attributes',
  ]);

  const viewSaveParams = React.useMemo(() => {
    const sort_by = `${sortingState[0]?.desc ? '-' : ''}${
      sortingState[0]?.id || ''
    }`;
    const filterEntry = uri ? filter : null;
    const filters = filterEntry || {};
    return {
      sort_by,
      columns,
      filters,
    };
  }, [sortingState, uri, filter, columns]);

  const handleViewRename = React.useCallback(
    (newName: string) => {
      if (!appliedSavedView?.id) {
        return;
      }
      const existingNames =
        savedViewsQuery.data?.map((view) => view.name || '') || [];
      const viewNameError = validateViewName(newName, existingNames);
      setViewNameError(viewNameError);
      if (viewNameError !== '') {
        return;
      }
      renamePrismView.mutate({
        id: appliedSavedView?.id,
        name: newName,
        category: uri,
        sort_by: appliedSavedView?.sort_by,
        columns: appliedSavedView?.columns,
        filters: appliedSavedView?.filters,
      });
    },
    [appliedSavedView, renamePrismView, savedViewsQuery.data, uri],
  );

  const handleViewUpdate = React.useCallback(() => {
    if (!viewId) {
      return;
    }
    const name = viewName || appliedSavedView?.name || '';
    const { sort_by, filters } = viewSaveParams;
    const orderedColumns = columnOrder.map((col) => ({
      name: col,
      visible: !!columnVisibility[col],
      size: columnSizing[col],
    }));
    updatePrismView.mutate({
      id: viewId,
      name,
      category: uri,
      sort_by,
      columns: orderedColumns,
      filters,
    });
  }, [
    appliedSavedView?.name,
    savedViewsQuery.data,
    columnOrder,
    viewId,
    viewName,
    viewSaveParams,
    updatePrismView,
    uri,
  ]);

  const handleViewCreate = React.useCallback(
    (viewName) => {
      const existingNames =
        savedViewsQuery.data?.map((view) => view.name || '') || [];
      const viewNameError = validateViewName(viewName, existingNames);
      setViewNameError(viewNameError);
      if (viewNameError !== '') {
        return;
      }
      const orderedColumns = columnOrder.map((col) => ({
        name: col,
        visible: !!columnVisibility[col],
        size: columnSizing[col],
      }));
      const { sort_by, filters } = viewSaveParams;
      createPrismView.mutate({
        name: viewName,
        category: uri,
        sort_by,
        columns: orderedColumns,
        filters,
      });
    },
    [
      savedViewsQuery.data,
      columnOrder,
      viewSaveParams,
      createPrismView,
      uri,
      columnVisibility,
      columnSizing,
    ],
  );

  const deleteSavedView = useDeletePrismView({
    appliedSavedView: appliedSavedView as SavedView,
    onSuccess: () => {
      resetFilter();
      history.push(history.location.pathname);
      toast({
        title: `${appliedSavedView?.name} has been removed`,
        content: `The saved view will no longer be available in ${getDisplayName(
          appliedSavedView?.category || '',
        )}.`,
        variant: 'default',
        style: {
          // istanbul ignore next
          left: toastOffset,
          bottom: '12px',
          position: 'absolute',
        },
      });
    },
  });

  const isEmpty = rows.length === 0;
  // need this here cause otherwise Babel transpilation fails in tests
  const tableCss = {
    ...css.tableContainer,
    ...(isEmpty ? { borderBottom: 'none' } : {}),
  };

  /**
   * Determines whether the table filters have changed from the default or saved view state.
   */
  const hasNewSavedViewFilters = React.useMemo(() => {
    if (!appliedSavedView || !appliedSavedView?.filters) {
      return false;
    }
    const appliedSavedViewFilterKeys = Object.keys(appliedSavedView?.filters);
    // @ts-expect-error -- TODO fix this
    const columnFilterKeys = columnFilters.map((item) => item.key);
    return xor(columnFilterKeys, appliedSavedViewFilterKeys).length > 0;
  }, [appliedSavedView, columnFilters]);

  const tableViewChanged =
    (appliedSavedView ? hasNewSavedViewFilters : !!columnFilters.length) ||
    table.options.meta?.isDefaultDirty;

  return (
    <Flex flow="column" flex="1" css={css.container}>
      <Flex css={css.toolbar} flow="column" justifyContent="start" gap="md">
        <Flex justifyContent="space-between">
          {uri && (
            <TableTitle
              savedViewsQuery={savedViewsQuery}
              title={title}
              uri={uri}
              isPaidCategory={isPaidCategory}
            />
          )}

          <Flex>
            <PrismNavCollapseButton
              isOpen={prismNavOpen}
              onClick={togglePrismNavOpen}
            />
            <PrismColumnEditor
              title={title}
              columns={allColumns}
              columnOrder={columnOrder}
              handleUpdateColumns={handleUpdateColumns}
              sidebarOpened={sidebarOpened}
              columnVisibility={columnVisibility}
              columnDefaults={columnDefinitionDefaults()}
            />
            <PrismExport
              blueprintIds={blueprints}
              deviceFamilies={deviceFamilies}
              category={uri}
              filters={filter}
              sorting={sortingState}
              title={title}
              columnVisibility={columnVisibility}
            />
            {appliedSavedView && ffPhaseOne && (
              <PrismSavedViewsActionMenu
                appliedSavedView={appliedSavedView}
                setRenameViewOpen={setRenameViewOpen}
                deleteView={deleteSavedView}
              />
            )}
          </Flex>
        </Flex>

        <Flex flow="row">
          <PrismCategoryFilters table={table} />
          <Flex flow="row" gap="sm" css={{ ml: 'auto', alignSelf: 'flex-end' }}>
            {tableViewChanged && (
              <Button variant="subtle" compact onClick={resetTableViewState}>
                {i18n.t('Reset table')}
              </Button>
            )}
            {(tableViewChanged || viewId) &&
              ffPhaseOne &&
              (viewId ? (
                <PrismViewSaveDropdown
                  viewName={viewName}
                  viewNameError={viewNameError}
                  handleViewCreate={handleViewCreate}
                  handleViewUpdate={handleViewUpdate}
                  setViewNameError={setViewNameError}
                />
              ) : (
                <PrismViewCreateDropdown
                  viewName={viewName}
                  viewNameError={viewNameError}
                  handleViewCreate={handleViewCreate}
                  setViewNameError={setViewNameError}
                />
              ))}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flow="column"
        justifyContent="space-between"
        css={{ height: '100%' }}
      >
        <ScrollContainer
          aria-label={title}
          css={tableCss}
          showScrollShadowRight
        >
          <NectarTable aria-label={title} css={css.table}>
            <Thead data-pinned>
              {headerGroups.map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <PrismTableHeader
                      key={header.id}
                      header={header}
                      columnVisibility={columnVisibility}
                      columnOrder={columnOrder}
                      isResizingColumn={columnSizingInfo.isResizingColumn}
                      columnFilters={columnFilters}
                      selectedPrismCategory={prismCategorySchema}
                      onSort={header.column.getToggleSortingHandler()}
                      sortState={header.column.getIsSorted() || 'none'}
                    />
                  ))}
                </Tr>
              ))}
            </Thead>
            {isEmpty ? null : (
              <Tbody>
                <PrismTableBody rows={rows} />
              </Tbody>
            )}
          </NectarTable>
        </ScrollContainer>
        {isEmpty ? (
          <PrismTableEmpty
            globalFilters={globalFilters}
            attributeFilters={filter}
            computerCount={computerCount}
            onNavigateToAddDevice={handleNavigateToAddDevice}
            isLoading={isLoading}
          />
        ) : null}
        <Flex
          flow="column"
          flex="1"
          justifyContent="end"
          css={css.paginationContainer}
        >
          {!isLoading && !isCountPending && rows.length > 0 && (
            <Pagination
              currentPage={pagination.pageIndex + 1}
              totalItems={countData?.count || 0}
              itemsPerPage={pagination.pageSize}
              onPageChange={(page) => table.setPageIndex(page - 1)}
              onItemsPerPageChange={(itemsPerPage) =>
                table.setPageSize(itemsPerPage)
              }
            />
          )}
        </Flex>
      </Flex>
      <PrismViewRenameModal
        isOpen={renameViewOpen}
        viewName={appliedSavedView?.name || ''}
        viewNameError={viewNameError}
        onClose={() => {
          setViewName('');
          setRenameViewOpen(false);
        }}
        onOpenChange={setRenameViewOpen}
        handleViewRename={handleViewRename}
        setViewNameError={setViewNameError}
      />
    </Flex>
  );
};

export default PrismTable;
