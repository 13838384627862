/* istanbul ignore file */
import { Button, Icon } from '@kandji-inc/bumblebee';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { fetchCompanyState } from 'app/_actions/account';
import { links } from 'app/common/constants';
import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';
import { getBlueprints as callGetBlueprints } from '../../../_actions/blueprint';
import {
  setModal as callSetModal,
  setSnackbar as callSetSnackbar,
} from '../../../_actions/ui';
import history from '../../../router/history';
import AddDevicesSelect from '../../common/AddDevicesSelect';
import { formatTime, moment } from '../../common/helpers';
import { InfoBanner } from '../../good/banners';
import AwesomeIconCircle from '../../interface/AwesomeIconCircle';
import { LineLoader } from '../../interface/LineLoader';
import TokenExpirationIndicator from '../../interface/indicators/TokenExpirationIndicator';
import Block from '../elements/Block';
import Description from '../elements/Description';
import Title from '../elements/Title';
import {
  getAppleDeviceInformation,
  getDepIntegration,
  getIntegration,
  saveDEPAccountDefaults,
  syncDevices,
} from './api';

const WhiteCard = styled('div')`
  background: white;
  border-radius: 4px;
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  bottom: ${(props) => (props.sticky ? '0' : 'after')};
  z-index: ${(props) => (props.sticky ? 2 : 'auto')};
  padding: 15px 30px ${(props) => (props.button ? '60px' : '26px')};
  margin-bottom: 20px;
`;

const Form = styled('div')``;

const StyledIcon = styled(Icon)`
  height: 20px;
  width: 20px;
  vertical-align: middle;
`;

const CardHeaderTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 6px;
  margin-bottom: 24px;
  border-bottom: 2px solid rgba(26, 29, 37, 0.05);
`;

const LabelInput = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #1a1d25;
  margin-top: 28px;
  margin-bottom: 10px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BoldText = styled('b')`
  font-weight: 500;
`;

const Link = styled('a')`
  font-weight: 400;
  text-decoration: underline !important;
  cursor: pointer;
`;

const TextInput = styled('input')`
  font-family: var(--font-family-primary);
  appearance: none;
  outline: none;
  background: #ffffff;
  border: 2px solid ${(props) => (props.error ? 'red' : '#e5e5e5')};
  box-sizing: border-box;
  padding: 0 15px;
  border-radius: 4px;
  height: 40px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Indicator = styled.div`
  margin-left: 20px;
  padding: 6px 15px;
  height: 24px;

  background: rgba(82, 197, 72, 0.2);
  border-radius: 3px;

  font-family: var(--font-family-primary);
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #37ac2c;
`;

const LoginWrapper = styled(Block.GreyBlock)`
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  white-space: nowrap;
  padding: 31px 30px;
  > * {
    margin-right: 20px;
  }
`;

const CertInfoBlock = styled('div')`
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 20px;
  grid-template-areas: "title value";
  margin-top: 15px;
`;

const CertInfoTitleWrapper = styled('div')`
  grid-area: title;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CertInfoTitle = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 25px;

  color: #1a1d25;
`;

const CertInfoIcon = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #1a1d25;
`;

const CertInfoValueWrapper = styled(CertInfoTitleWrapper)`
  grid-area: value;
`;

const CertInfoValue = styled(CertInfoTitle)`
  font-weight: 500;
`;

const DefaultBlock = styled(CertInfoBlock)`
  margin-top: -20px;
  align-items: baseline;
`;

const DefaultsTitleWrapper = styled(CertInfoTitleWrapper)`
  align-self: center;
`;

const DefaultsValueWrapper = styled(CertInfoValueWrapper)`
  align-self: center;
  margin-top: -3px;
`;

const DefaultsInfoLabel = styled('span')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 25px;
  color: #1a1d25;
  font-weight: 500;
  width: 169px;
  margin-top: 10px;
`;

const DefaultsInfoValue = styled(DefaultsInfoLabel)`
  font-weight: 400;
  display: flex;
  align-items: center;
  i {
    margin-top: 1px;
  }
  div {
    margin-right: 3px;
  }
`;

const Margin = styled('div')`
  margin: 20px;
`;

const DeviceLabel = styled('div')`
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #181c24;
  white-space: pre-wrap;
`;

const SubLabel = styled(DeviceLabel)`
  color: rgba(26, 29, 37, 0.6);
  padding-bottom: 9px;
`;

const SyncInfo = styled('div')`
  font-family: var(--font-family-primary);
  display: flex;
  align-items: baseline;
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
  color: #181c24;
`;

const SyncTitle = styled('div')`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 14px;
  padding-right: 16px;
  border-right: 1px solid #e2e2e2;
`;

const Buttons = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 30px;
`;

class DepIntegration extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSyncing: false,
      integrationData: {},
      initialIntegrationData: {},
      deviceData: {},
      MDMIntegration: false,
      saveButtonClicked: false,
      settingBlueprint: false,
    };
  }

  componentDidMount() {
    this.reloadInfo();
  }

  onSaveClick = () => {
    const { integrationData } = this.state;
    this.setState({ saveButtonClicked: true });
    const formData = new FormData();
    formData.append('blueprint_id', integrationData.blueprint.id);
    formData.append('email', integrationData.defaults.email);
    formData.append('phone', integrationData.defaults.phone);
    saveDEPAccountDefaults(formData).then(() =>
      this.setState({
        settingBlueprint: false,
        saveButtonClicked: false,
      }),
    );
  };

  onFetchClick = () => {
    const { setSnackbar } = this.props;
    this.setState({ isSyncing: true });
    syncDevices()
      .catch(() => {
        setSnackbar(i18n.common.error());
      })
      .finally(() => {
        this.reloadInfo();
      });
  };

  getCurrentBlueprint() {
    const { integrationData } = this.state;
    const currentBlueprint = get(integrationData, 'blueprint');
    return (
      <DefaultsInfoValue>
        <AwesomeIconCircle
          icon={`ss-icon ${currentBlueprint.icon}`}
          color={currentBlueprint.color || 'aqua-800'}
          size="24px"
          border=""
        />
        <div>{currentBlueprint.name}</div>
      </DefaultsInfoValue>
    );
  }

  getLastDeviceSync() {
    const { integrationData } = this.state;
    const lastDeviceSync = get(integrationData, 'last_device_sync');
    if (!lastDeviceSync) {
      return i18n.t('Pending...');
    }
    return formatTime(lastDeviceSync, null, null, null, false);
  }

  getDeviceCount() {
    const { deviceData } = this.state;
    const deviceCount = get(deviceData, 'total', '0');
    const text = i18n.common.numDevices(get(deviceData, 'total', '0'));
    return deviceCount + text;
  }

  reloadInfo = () => {
    const { setDepLoaded } = this.props;
    const promises = [
      this.props.getBlueprints(),
      getIntegration()
        .then((integrationData) =>
          this.setState({ MDMIntegration: integrationData.days_left !== null }),
        )
        .catch(() => this.setState({ MDMIntegration: false })),
      getDepIntegration()
        .then((integrationData) => {
          const integrationDataForState =
            integrationData.days_left === null ? {} : integrationData;
          this.setState({
            integrationData: integrationDataForState,
            initialIntegrationData: integrationDataForState,
          });
        })
        .catch(() =>
          this.setState({
            integrationData: {},
            initialIntegrationData: {},
          }),
        ),
      getAppleDeviceInformation()
        .then((deviceData) => this.setState({ deviceData }))
        .catch(() => this.setState({ deviceData: {} })),
    ];
    Promise.all(promises).finally(() => {
      this.setState({ isLoading: false, isSyncing: false });
      setDepLoaded();
    });
  };

  render() {
    const { blueprints, setModal } = this.props;
    const {
      isLoading,
      isSyncing,
      integrationData,
      MDMIntegration,
      settingBlueprint,
      saveButtonClicked,
      deviceData,
      initialIntegrationData,
    } = this.state;

    if (isLoading) {
      return <LineLoader />;
    }

    const NOT_AVAILABLE = i18n.t('Not Available');

    return (
      <LoginWrapper>
        <div className="d-flex flex-column w-100">
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: 10 }}
            id="automated-device-enrollment"
          >
            <Title.SubTitle>
              {i18n.t('Automated Device Enrollment')}
            </Title.SubTitle>
            {!isEmpty(integrationData) && (
              <TokenExpirationIndicator daysLeft={integrationData.days_left} />
            )}
            <Button
              className="pendo-configure-ade"
              style={{ marginLeft: 'auto' }}
              kind={!isEmpty(integrationData) ? 'outline' : 'fill'}
              theme="dark"
              disabled={!MDMIntegration}
              onClick={() =>
                history.push(
                  `/my-company/dep-integrations/${
                    isEmpty(integrationData) ? 'new' : 'renew'
                  }`,
                )
              }
            >
              {!isEmpty(integrationData)
                ? i18n.t('Renew Token')
                : i18n.t('Configure')}
            </Button>
          </div>
          <Description.SubDescription
            style={{ whiteSpace: 'normal', marginBottom: 20 }}
          >
            {i18n.t(
              `Automated Device Enrollment (formerly DEP) allows for your
            organization’s devices to automatically enroll into Kandji when they
            are first unboxed and connected to the internet.`,
            )}
          </Description.SubDescription>
          {!MDMIntegration && (
            <InfoBanner
              text={i18n.t(`APNs is required for Automated Device Enrollment`)}
            />
          )}
          {!isEmpty(integrationData) && (
            <>
              {!settingBlueprint ? (
                <WhiteCard>
                  <CardHeaderTitle>
                    {i18n.t('Defaults')}
                    <Button
                      icon="pencil"
                      size="small"
                      theme="dark"
                      kind="link"
                      onClick={() => this.setState({ settingBlueprint: true })}
                    >
                      {i18n.t('Edit')}
                    </Button>
                  </CardHeaderTitle>
                  <DefaultBlock>
                    <DefaultsTitleWrapper>
                      <DefaultsInfoLabel>
                        {i18n.t('Device Enrollment')}
                      </DefaultsInfoLabel>
                      <DefaultsInfoLabel>
                        {i18n.t('Support Phone Number')}
                      </DefaultsInfoLabel>
                      <DefaultsInfoLabel>
                        {i18n.t('Support Email Address')}
                      </DefaultsInfoLabel>
                    </DefaultsTitleWrapper>
                    <DefaultsValueWrapper>
                      {this.getCurrentBlueprint()}
                      <DefaultsInfoValue>
                        {get(integrationData, 'defaults.phone')}
                      </DefaultsInfoValue>
                      <DefaultsInfoValue>
                        {get(integrationData, 'defaults.email')}
                      </DefaultsInfoValue>
                    </DefaultsValueWrapper>
                  </DefaultBlock>
                </WhiteCard>
              ) : (
                <WhiteCard button>
                  <CardHeaderTitle>{i18n.t('Defaults')}</CardHeaderTitle>
                  <Form>
                    <LabelInput>{i18n.t('Default Blueprint')}</LabelInput>
                    <SubLabel>
                      {i18n.t(
                        'Automated Device Enrollment devices will be assigned to the Default Blueprint specified below when they enroll into Kandji. ',
                      ) +
                        i18n.t(
                          'You can override this assignment for individual devices using the Automated Device Enrollment Devices list. ',
                        ) +
                        i18n.t(
                          'To customize the enrollment experience for a Blueprint, assign an Automated Device Enrollment Library Item to it.',
                        )}
                    </SubLabel>
                    <AddDevicesSelect
                      title="None"
                      width="50%"
                      selected={get(integrationData, 'blueprint')}
                      list={blueprints.sort((a, b) =>
                        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
                      )}
                      resetThenSet={(value) =>
                        this.setState({
                          integrationData: {
                            ...integrationData,
                            blueprint: value,
                          },
                        })
                      }
                    />
                    <LabelInput>Default Support Phone</LabelInput>
                    <SubLabel>
                      {i18n.t(
                        `Phone number displayed during Setup Assistant for
                      Automated Device Enrollment devices. You may override this
                      when configuring an Automated Device Enrollment library
                      item.`,
                      )}
                    </SubLabel>
                    <TextInput
                      type="tel"
                      value={integrationData.defaults.phone}
                      placeholder="800-800-8000"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      required
                      onChange={(e) =>
                        this.setState({
                          integrationData: {
                            ...integrationData,
                            defaults: {
                              ...integrationData.defaults,
                              phone: e.target.value.slice(0, 50),
                            },
                          },
                        })
                      }
                      error={
                        !integrationData.defaults.phone && saveButtonClicked
                      }
                    />
                    <LabelInput>{i18n.t('Default Email Address')}</LabelInput>
                    <SubLabel>
                      {i18n.t(
                        `Email address displayed during Setup Assistant for
                      Automated Device Enrollment devices. You may override this
                      when configuring an Automated Device Enrollment library
                      item.`,
                      )}
                    </SubLabel>
                    <TextInput
                      type="email"
                      value={integrationData.defaults.email}
                      placeholder="support@example.com"
                      onChange={(e) =>
                        this.setState({
                          integrationData: {
                            ...integrationData,
                            defaults: {
                              ...integrationData.defaults,
                              email: e.target.value.slice(0, 250),
                            },
                          },
                        })
                      }
                      error={
                        !integrationData.defaults.email && saveButtonClicked
                      }
                    />
                    <Buttons>
                      <>
                        <Button
                          onClick={() =>
                            this.setState({
                              settingBlueprint: false,
                              integrationData: initialIntegrationData,
                            })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            !integrationData.defaults.email ||
                            !integrationData.defaults.phone ||
                            saveButtonClicked
                          }
                          style={{ marginLeft: '10px' }}
                          name={
                            saveButtonClicked
                              ? i18n.t('Saving...')
                              : i18n.t('Save')
                          }
                          onClick={this.onSaveClick}
                        >
                          {i18n.t('Save')}
                        </Button>
                      </>
                    </Buttons>
                  </Form>
                </WhiteCard>
              )}
              {/* ==================== DEVICES ==================== */}
              <WhiteCard>
                <CardHeaderTitle>
                  {i18n.t(`Automated Device Enrollment Devices`)}
                  <SyncInfo>
                    <SyncTitle>
                      {i18n.t('Last fetch ')}
                      <BoldText>{this.getLastDeviceSync()}</BoldText>
                    </SyncTitle>
                    <Button
                      disabled={isSyncing}
                      kind="link"
                      theme="dark"
                      icon="arrows-rotate"
                      onClick={this.onFetchClick}
                      size="small"
                    >
                      {isSyncing ? i18n.t('Fetching') : i18n.t('Fetch now')}
                    </Button>
                  </SyncInfo>
                </CardHeaderTitle>
                <DeviceLabel>
                  <BoldText>{this.getDeviceCount()}</BoldText>
                  {i18n.t(' assigned to Kandji:')}
                </DeviceLabel>
                <CertInfoBlock>
                  <CertInfoTitleWrapper>
                    <CertInfoIcon>
                      {/* <StyledIcon alt="mac" src={macIcon} /> */}
                      <StyledIcon name="desktop" />
                    </CertInfoIcon>
                    <CertInfoIcon>
                      {/* <StyledIcon alt="iphone" src={iphoneIcon} /> */}
                      <StyledIcon name="mobile-button" />
                    </CertInfoIcon>
                    <CertInfoIcon>
                      {/* <StyledIcon alt="ipad" src={ipadIcon} /> */}
                      <StyledIcon name="tablet" />
                    </CertInfoIcon>
                    <CertInfoIcon>
                      <StyledIcon name="apple-tv" />
                    </CertInfoIcon>
                    <CertInfoIcon>
                      {/* <StyledIcon alt="ipods" src={iphoneIcon} /> */}
                      <StyledIcon name="mobile-button" />
                    </CertInfoIcon>
                  </CertInfoTitleWrapper>
                  <CertInfoValueWrapper>
                    <CertInfoValue>
                      {i18n.common.numMacs(get(deviceData, 'Mac', '0'))}
                    </CertInfoValue>
                    <CertInfoValue>
                      {i18n.common.numIPhones(get(deviceData, 'iPhone', '0'))}
                    </CertInfoValue>
                    <CertInfoValue>
                      {i18n.common.numIPads(get(deviceData, 'iPad', '0'))}
                    </CertInfoValue>
                    <CertInfoValue>
                      {i18n.common.numAppleTVs(get(deviceData, 'AppleTV', '0'))}
                    </CertInfoValue>
                    <CertInfoValue>
                      {i18n.common.numIPods(get(deviceData, 'iPod', '0'))}
                    </CertInfoValue>
                  </CertInfoValueWrapper>
                </CertInfoBlock>
                <Margin />
                <DeviceLabel>
                  {i18n.t(' View more details on the ')}

                  <Link
                    onClick={() => {
                      const isEnrollmentPageEnabled = featureFlags.getFlag(
                        'core_022824_enrollment-page',
                      );
                      history.push(
                        isEnrollmentPageEnabled
                          ? links['add-devices']
                          : links.depDevices,
                      );
                    }}
                  >
                    {i18n.t(`Automated Device Enrollment Devices page.`)}
                  </Link>
                </DeviceLabel>
              </WhiteCard>
              {/* ==================== CERT INFO ==================== */}
              <WhiteCard>
                <CertInfoBlock>
                  <CertInfoTitleWrapper>
                    <CertInfoTitle>
                      {i18n.t('Server Token File:')}
                    </CertInfoTitle>
                    <CertInfoTitle>{i18n.t('Server Name:')}</CertInfoTitle>
                    <CertInfoTitle>{i18n.t('Server UUID:')}</CertInfoTitle>
                    <CertInfoTitle>{i18n.t('Admin ID:')}</CertInfoTitle>
                    <CertInfoTitle>
                      {i18n.t('Organization Name:')}
                    </CertInfoTitle>
                    <CertInfoTitle>
                      {i18n.t('Organization Email Address:')}
                    </CertInfoTitle>
                    <CertInfoTitle>
                      {i18n.t('Organization Phone Number:')}
                    </CertInfoTitle>
                    <CertInfoTitle>
                      {i18n.t('Organization Address:')}
                    </CertInfoTitle>
                    <CertInfoTitle>
                      {i18n.t('Token Expiration Date:')}
                    </CertInfoTitle>
                    <CertInfoTitle>
                      {i18n.t('Appleseed Beta Enrolled:')}
                    </CertInfoTitle>
                  </CertInfoTitleWrapper>
                  <CertInfoValueWrapper>
                    <CertInfoValue>
                      {get(integrationData, 'stoken_file_name') ||
                        NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'server_name') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'server_uuid') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'admin_id') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'org_name') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'org_email') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'org_phone') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(integrationData, 'org_address') || NOT_AVAILABLE}
                    </CertInfoValue>
                    <CertInfoValue>
                      {`${moment(
                        get(integrationData, 'access_token_expiry'),
                      ).format('LL')}
                      - ${moment(
                        get(integrationData, 'access_token_expiry'),
                      ).fromNow()}`}
                    </CertInfoValue>
                    <CertInfoValue>
                      {get(
                        integrationData,
                        'appleseed_beta_enrollments',
                      ).toString() || i18n.t('false')}
                    </CertInfoValue>
                  </CertInfoValueWrapper>
                </CertInfoBlock>
              </WhiteCard>
            </>
          )}
          {!isEmpty(integrationData) && (
            <Button
              kind="link"
              theme="dark"
              style={{ marginLeft: 'auto' }}
              onClick={() => setModal('DISCONNECT_AUTO_ENROLL')}
            >
              {i18n.t(`Disconnect Automated Device Enrollment`)}
            </Button>
          )}
        </div>
      </LoginWrapper>
    );
  }
}

DepIntegration.propTypes = {
  blueprints: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  currentCompany: state.company,
  blueprints: state.data.blueprints,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSnackbar: callSetSnackbar,
      setModal: callSetModal,
      fetchCompanyState,
      getBlueprints: callGetBlueprints,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DepIntegration);
