import { Box, Flex, Icon, type IconNames } from '@kandji-inc/nectar-ui';
import EDRTooltip from '../../common/components/EDRTooltip';
import constants from '../common/constants';
import getFormatCount from '../common/utils/getFormatCount';
import type { ThreatStatus } from '../threat.types';

const ThreatStatusIconText = (props: {
  label: number;
  icon: IconNames;
  color: string;
  tooltip: string;
}) => {
  const { label, icon, color, tooltip } = props;

  return (
    <EDRTooltip side="bottom" label={tooltip}>
      <Flex gap="xs" alignItems="center" pr1>
        <Icon name={icon} color={`var(--color-${color})`} />
        <Box css={{ width: 20 }}>{getFormatCount(label)}</Box>
      </Flex>
    </EDRTooltip>
  );
};

const getStatusLabel = (status: ThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP[status];

const getStatusIcon = (status: ThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

type ThreatListStatusSummaryBadgesProps = {
  compact?: boolean;
  notQuarantined: number;
  quarantined: number;
  resolved: number;
  released: number;
};

const ThreatListStatusSummaryBadges = (
  props: ThreatListStatusSummaryBadgesProps,
) => {
  const {
    compact = false,
    notQuarantined,
    quarantined,
    resolved,
    released,
  } = props;

  const iconSize = compact ? 15 : 20;

  return (
    <Flex
      alignItems="center"
      gap={compact ? 'sm' : 'md'}
      css={{
        svg: {
          width: iconSize,
          height: iconSize,
          flexShrink: 0,
        },
      }}
    >
      <ThreatStatusIconText
        label={notQuarantined}
        icon={getStatusIcon(constants.THREAT_STATUS.NOT_QUARANTINED)}
        color="red-50"
        tooltip={getStatusLabel(constants.THREAT_STATUS.NOT_QUARANTINED)}
      />
      <ThreatStatusIconText
        label={quarantined}
        icon={getStatusIcon(constants.THREAT_STATUS.QUARANTINED)}
        color="blue-50"
        tooltip={getStatusLabel(constants.THREAT_STATUS.QUARANTINED)}
      />
      <ThreatStatusIconText
        label={resolved}
        icon={getStatusIcon(constants.THREAT_STATUS.RESOLVED)}
        color="green-50"
        tooltip={getStatusLabel(constants.THREAT_STATUS.RESOLVED)}
      />
      <ThreatStatusIconText
        label={released}
        icon={getStatusIcon(constants.THREAT_STATUS.RELEASED)}
        color="neutral-70"
        tooltip={getStatusLabel(constants.THREAT_STATUS.RELEASED)}
      />
    </Flex>
  );
};

export default ThreatListStatusSummaryBadges;
