import { Box, Flex, Loader } from '@kandji-inc/nectar-ui';
import { EDRSidePanel } from 'src/features/edr/common/components/EDRSidePanel';
import { useGetVulnerability } from '../../hooks/use-get-vulnerability';
import useVulnerability from '../../store';
import { Body } from './body';
import { Header } from './header';

const VulnerabilitySidePanel = (props: {
  selectedVulnerabilityKeys: { id: string; appName: string } | null;
  clearSelectedVulnerabilityKeys: () => void;
}) => {
  const { selectedVulnerabilityKeys, clearSelectedVulnerabilityKeys } = props;
  const setSelectedVulnerability = useVulnerability(
    (state) => state.setSelectedVulnerability,
  );

  const { data, isLoading } = useGetVulnerability(
    selectedVulnerabilityKeys.id,
    selectedVulnerabilityKeys.appName,
    [selectedVulnerabilityKeys.id, selectedVulnerabilityKeys.appName],
  );
  setSelectedVulnerability(data);

  const shouldShowVulnerability = !isLoading && data;

  const loader = (
    <Flex
      hFull
      wFull
      flow="column"
      alignItems="center"
      justifyContent="center"
      gap="sm"
      data-testid="loading-vulnerability"
    >
      <Loader size="lg" />
    </Flex>
  );

  const header = shouldShowVulnerability ? (
    <Header selectedVulnerability={data} />
  ) : (
    <Box />
  );

  const body = shouldShowVulnerability ? (
    <Body selectedVulnerability={data} />
  ) : (
    loader
  );

  return (
    <EDRSidePanel
      onClose={clearSelectedVulnerabilityKeys}
      header={header}
      body={body}
      css={{ containerCss: { zIndex: 2 } }}
    />
  );
};

export { VulnerabilitySidePanel };
