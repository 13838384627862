import {
  Badge,
  Flex,
  Heading,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import featureFlags from 'src/config/feature-flags';
import { usePrismUrlContext } from 'src/features/visibility/prism/contexts/PrismUrlContext';
import type { PrismCategoryUri } from 'src/features/visibility/prism/types/prism.types';
import { SavedViewsDropdown } from '../SavedViewsDropdown';
import FleetTitleTooltipContent from './PrismTitleTooltipContent';
import TableHeaderIconButton from './TableHeaderIconButton';

interface TableTitleProps {
  title: string;
  uri: PrismCategoryUri;
  isPaidCategory: boolean;
  savedViewsQuery: any;
}

const AddOnFeatureBadge = () => (
  <Badge
    icon="gem"
    color="purple"
    css={{
      alignSelf: 'center',
    }}
  >
    {i18n.t('Add-On Feature')}
  </Badge>
);

const TableTitle = (props: TableTitleProps) => {
  const { title, uri, isPaidCategory, savedViewsQuery } = props;

  const { viewId, removeViewId, resetFilter } = usePrismUrlContext();
  const handleResetToDefault = () => {
    removeViewId();
    resetFilter();
  };

  const ffPrismPhaseOne = featureFlags.getFlag('visibility-fleet-attributes');

  const getTableTitle = () => {
    if (ffPrismPhaseOne) {
      return viewId ? (
        <Heading
          as="h3"
          size="2"
          css={{
            color: '$neutral90',
            fontSize: '$4',
            '& > span': {
              '&:hover': {
                textDecoration: 'underline',
              },
            },
          }}
        >
          <span role="button" onClick={handleResetToDefault}>
            {title}
          </span>{' '}
          /
        </Heading>
      ) : (
        <Heading
          as="h3"
          size="2"
          css={{
            color: '$neutral90',
            fontSize: '$4',
          }}
        >
          {title}
        </Heading>
      );
    }

    return (
      <Heading
        as="h2"
        size="2"
        css={{
          color: '$neutral90',
        }}
      >
        {title}{' '}
      </Heading>
    );
  };

  return (
    <TooltipProvider>
      <Flex alignItems="center" gap="xs">
        {getTableTitle()}

        {ffPrismPhaseOne ? (
          <SavedViewsDropdown savedViewsQuery={savedViewsQuery} />
        ) : null}

        <Tooltip
          side="bottom"
          // @ts-expect-error -- TODO fix this
          content={(handleClose) => (
            <FleetTitleTooltipContent
              prismCategoryUri={uri}
              onClose={handleClose}
            />
          )}
          shadow="shadow"
          hideArrow
          theme="light"
          css={{ zIndex: 5, p: '$4', color: '$neutral90' }}
          maxWidth="320px"
          align="start"
          trigger="click"
          interactive
        >
          <TableHeaderIconButton icon="circle-info" />
        </Tooltip>
        {isPaidCategory && AddOnFeatureBadge()}
      </Flex>
    </TooltipProvider>
  );
};

export default TableTitle;
