// istanbul ignore file
import { Button, Flex, Label, TextField, styled } from '@kandji-inc/nectar-ui';
import type { Column } from '@tanstack/react-table';
import { i18n } from 'i18n';
import React from 'react';
import { ColumnList } from './ColumnList';
import {
  DataTypeFilter,
  DeviceFamilyFilter,
} from './column-editor-filter-buttons';
import { filterColumns } from './utils';

export const ReorderTab = ({
  columns,
  columnOrder,
  columnVisibility,
  onColumnVisibilityChange,
  onColumnOrderChange,
}: {
  columns: {
    pinnedColumns?: string[];
    columnDefs?: Column<{}, unknown>[];
    alwaysHiddenColumns?: string[];
  };
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  onColumnVisibilityChange: (visibility: Record<string, boolean>) => void;
  onColumnOrderChange: (newOrder: string[]) => void;
}) => {
  const visibleColumns = React.useMemo(
    () =>
      columns.columnDefs?.filter(
        (column) =>
          columnVisibility[column.id] !== false &&
          !columns.alwaysHiddenColumns?.includes(column.id),
      ),
    [columns],
  );

  const [sortedColumns, setSortedColumns] = React.useState(columnOrder);

  React.useEffect(() => {
    setSortedColumns(columnOrder);
  }, [columnOrder]);

  const reorderColumns = (newOrder) => {
    const sortedColumns = newOrder.map((column) => column.id);
    setSortedColumns(sortedColumns);
    const globalOrder: string[] = [];
    let newOrderIndex = 0;
    columnOrder.forEach((columnId) => {
      if (!sortedColumns.includes(columnId)) {
        globalOrder.push(columnId);
      } else {
        globalOrder.push(sortedColumns[newOrderIndex]);
        newOrderIndex++;
      }
    });
    onColumnOrderChange(globalOrder);
  };

  return (
    <>
      <Label above>{i18n.t('Drag to reorder')}</Label>
      <ColumnList
        columns={{ ...columns, columnDefs: visibleColumns || [] }}
        columnOrder={sortedColumns}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onColumnOrderChange={reorderColumns}
      />
    </>
  );
};

export const ShowHideTab = ({
  columns,
  columnOrder,
  columnVisibility,
  onColumnVisibilityChange,
  onColumnOrderChange,
}: {
  columns: {
    pinnedColumns?: string[];
    columnDefs?: Column<{}, unknown>[];
    alwaysHiddenColumns?: string[];
  };
  columnOrder: string[];
  columnVisibility: Record<string, boolean>;
  onColumnVisibilityChange: (visibility: Record<string, boolean>) => void;
  onColumnOrderChange: (newOrder: string[]) => void;
}) => {
  const [search, setSearch] = React.useState('');
  const [deviceFamilyFilter, setDeviceFamilyFilter] = React.useState<string[]>(
    [],
  );
  const [dataTypeFilter, setDataTypeFilter] = React.useState<string[]>([]);

  const filteredColumns = filterColumns(
    columns,
    search,
    deviceFamilyFilter,
    dataTypeFilter,
  );

  const isFiltersApplied =
    deviceFamilyFilter.length > 0 || dataTypeFilter.length > 0;

  return (
    <>
      <SearchFiltersContainer>
        <TextField
          icon="magnifying-glass"
          placeholder={i18n.t('Search for a field')}
          css={{ width: '100%' }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <FilterButtonsContainer>
          <DeviceFamilyFilter
            deviceFamilyFilter={deviceFamilyFilter}
            setDeviceFamilyFilter={setDeviceFamilyFilter}
          />
          <DataTypeFilter
            dataTypeFilter={dataTypeFilter}
            setDataTypeFilter={setDataTypeFilter}
          />
          {isFiltersApplied && (
            <Button
              compact
              variant="subtle"
              onClick={() => {
                setDeviceFamilyFilter([]);
                setDataTypeFilter([]);
              }}
            >
              {i18n.t('Clear')}
            </Button>
          )}
        </FilterButtonsContainer>
      </SearchFiltersContainer>
      <Label above>{i18n.t('Available fields')}</Label>
      <ColumnList
        columns={{ ...columns, columnDefs: filteredColumns || [] }}
        columnOrder={columnOrder}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onColumnOrderChange={onColumnOrderChange}
        enableReorder={false}
      />
    </>
  );
};

const SearchFiltersContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  alignSelf: 'stretch',
});

const FilterButtonsContainer = styled(Flex, {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  gap: '4px var(--Primitive-Spacing-space-8, 8px)',
  alignSelf: 'stretch',
  flexWrap: 'wrap',
  background: '#FFF',
  paddingLeft: '2px',
});
