import { Button, ButtonGroup, setClass } from '@kandji-inc/bumblebee';
import Tippy from '@tippyjs/react';
import { Setting } from 'features/library-items/template';
import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import featureFlags from 'src/config/feature-flags';
import webloginicon from '../../assets/mac-login-preview.svg';
import authmode from '../../assets/macloginuser.svg';
import questionMark from '../../assets/question.svg';
import webLogin from '../../assets/web-login-preview.png';
import KandjiLoginService from '../../service/kandji-login-service';
import bgLight from '../common/assets/bg-default-monterey.jpg';
import bgLightSonoma from '../common/assets/bg-macos-sonoma-light.jpg';
import help from '../common/assets/help2.svg';
import logoExample from '../common/assets/kandji-bee-logo-transparent.png';
import footerLogo from '../common/assets/kandji-footer-logo.svg';
import power from '../common/assets/power.svg';
import restartRight from '../common/assets/restart-right.svg';
import restart from '../common/assets/restart.svg';
import sleep from '../common/assets/sleep.svg';
// import bgSonomaHorizon from '../../assets/sonoma-horizon-time.png';
import bgSonomaHorizon from '../common/assets/sonoma-no-time.png';
import sonomaTimeClock from '../common/assets/sonoma-time-clock.png';
import tip from '../common/assets/tip.svg';
import Header from '../common/header';
import ToggleButton from '../common/toggle-button';
import Drawer from './drawer';
import './login-screen.css';
import { i18n } from 'src/i18n';

const loginTabs = {
  WEB_LOGIN: 'web-login',
  LOCAL_LOGIN: 'local-login',
};

const osTabs = {
  OLDER_VERSIONS: 'olderVersions',
  MACOS_14_AND_LATER: 'macOS14AndLater',
};
const toggleButtons = Object.values(loginTabs);
const toggleOsTabsButtons = Object.values(osTabs);

const LoginScreen = (props) => {
  const {
    setting,
    authSetting,
    update,
    beforeEditModel,
    isDisabled,
    isNewOsTab,
    onOsTabChange,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [customUsername, setCustomUsername] = useState();
  const [loginTab, setLoginTab] = useState(loginTabs.LOCAL_LOGIN);

  const restoreData = (data) =>
    Object.keys(data).forEach((key) => update(key, data[key]));

  useEffect(() => {
    if (customUsername) {
      if (setting.isCustomUsername) {
        customUsername.value = setting.customUsername || 'Username';
      } else {
        customUsername.value = 'Username';
      }
    }
  }, [customUsername, setting.customUsername, setting.isCustomUsername]);

  useEffect(() => {
    if (authSetting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN) {
      setLoginTab(toggleButtons[0]);
    } else if (
      authSetting.mode === KandjiLoginService.authenticationMode.MAC_LOGIN
    ) {
      setLoginTab(toggleButtons[1]);
    }
  }, [authSetting.mode]);

  const LDFFpassportSonoma = featureFlags.getFlag('LIT-WWDC23-passport-sonoma');
  const LDFFpassportRefresh = featureFlags.getFlag(
    'DC-998-passport-ui-refresh',
  );
  const isWebLoginModeSelected =
    authSetting.idp &&
    authSetting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Login window</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Customize the appearance and functionality of the login window.
        </p>
      </Setting.SubHeader>
      <div className="k-klogin-preview">
        <div className="b-flex-vc">
          <div className="b-flex-g b-flex-vc b-flex-btw b-flex1">
            <div className="b-flex-g b-flex-vc">
              <Button
                disabled={isDisabled}
                onClick={() => {
                  setLoginTab(loginTabs.LOCAL_LOGIN);
                  setIsDrawerOpen(true);
                }}
              >
                Customize
              </Button>
              <Button
                onClick={() => setIsExpanded((p) => !p)}
                icon={isExpanded ? 'angle-up' : 'angle-down'}
                kind="link"
                size="small"
              >
                {isExpanded ? 'Collapse preview' : 'Expand preview'}
              </Button>
            </div>
            <div className="b-flex-g b-flex-vc">
              {authSetting.idp &&
                authSetting.mode ===
                  KandjiLoginService.authenticationMode.WEB_LOGIN && (
                  <Tippy
                    content={
                      <p className="b-txt li-passport__login-help__txt">
                        When the user is online, Passport will display Web Login
                        with your IdP portal. If a network is unavailable,
                        Passport will automatically fallback to Local Login.
                      </p>
                    }
                    placement="bottom"
                    theme="blueprint"
                  >
                    <div className="li-passport__login-help b-flex-vc b-flex-hc">
                      <img src={questionMark} alt="" />
                    </div>
                  </Tippy>
                )}
              <div className="k-ks-toggle">
                <ButtonGroup
                  initialSelected={isNewOsTab ? 1 : 0}
                  activeWatch={isNewOsTab ? 1 : 0}
                  onToggle={(index) => onOsTabChange(Boolean(index))}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <ToggleButton
                    className="b-txt k-ks-dark-blue"
                    onClick={() => onOsTabChange(false)}
                  >
                    {i18n.t('Older versions')}
                  </ToggleButton>
                  <ToggleButton
                    className="b-txt k-ks-dark-blue"
                    onClick={() => onOsTabChange(true)}
                    style={{ width: 'max-content' }}
                  >
                    {i18n.t('macOS 14 and later')}
                  </ToggleButton>
                </ButtonGroup>
              </div>
              {authSetting.idp &&
                authSetting.mode ===
                  KandjiLoginService.authenticationMode.WEB_LOGIN && (
                  <div className="k-ks-toggle">
                    <ButtonGroup
                      initialSelected={loginTab === loginTabs.WEB_LOGIN ? 0 : 1}
                      activeWatch={toggleButtons.indexOf(loginTab)}
                      onToggle={(index) => setLoginTab(toggleButtons[index])}
                    >
                      <ToggleButton
                        className="b-txt k-ks-dark-blue"
                        onClick={() => setLoginTab(loginTabs.WEB_LOGIN)}
                      >
                        {i18n.t('Web Login')}
                      </ToggleButton>
                      <ToggleButton
                        className="b-txt k-ks-dark-blue"
                        onClick={() => setLoginTab(loginTabs.LOCAL_LOGIN)}
                      >
                        {i18n.t('Local Login')}
                      </ToggleButton>
                    </ButtonGroup>
                  </div>
                )}
            </div>
          </div>
        </div>
        {isExpanded && (
          <div className="k-klogin-preview-row k-klogin-login-preview">
            <div
              className="k-klogin-preview-image"
              style={{
                backgroundImage: `url(${
                  (setting.isDesktopBg &&
                    (setting.desktopBg?.dataUrl || setting.desktopBgUrl)) ||
                  (LDFFpassportRefresh && isNewOsTab && bgSonomaHorizon) ||
                  (LDFFpassportSonoma && bgLightSonoma) ||
                  bgLight
                })`,
              }}
            />
            <div
              className="k-klogin-datetime"
              style={{
                backgroundImage: `url(${
                  LDFFpassportRefresh && isNewOsTab && sonomaTimeClock
                })`,
              }}
            />
            <div className="k-klogin-login-preview-inner">
              <Header
                setting={setting}
                isNewOs={isNewOsTab}
                isWebLoginTab={loginTab === loginTabs.WEB_LOGIN}
                isWebLoginModeSelected={isWebLoginModeSelected}
              />
              <div className="k-klog-pform">
                <div
                  className={setClass(
                    'k-klog-pform-logo',
                    !setting.isLogo && 'pform-logo-hide',
                  )}
                >
                  {loginTab === loginTabs.LOCAL_LOGIN && (
                    <div className="k-klog-pform-logo-inner">
                      <img
                        hidden={!setting.isLogo}
                        draggable="false"
                        src={
                          (setting.isLogo &&
                            (setting.logoUrl || setting.logo?.dataUrl)) ||
                          logoExample
                        }
                        alt="logo"
                      />
                    </div>
                  )}
                </div>
                {loginTab === loginTabs.WEB_LOGIN && (
                  <div className="li-passport__web-login-tab">
                    <img className="" src={webLogin} alt="" />
                    <div className="li-passport__web-login-tab-helper b-txt-ctrl9">
                      For preview purposes only. Users will see your{' '}
                      {KandjiLoginService.idpHumanNames[authSetting.idp] ||
                        'identity provider'}{' '}
                      authentication portal.
                    </div>
                  </div>
                )}
                {loginTab === loginTabs.LOCAL_LOGIN && (
                  <div
                    className="k-klog-pform-inputs"
                    style={{
                      bottom: isNewOsTab ? '-245px' : 0,
                    }}
                  >
                    <div className="k-klog-pform-input">
                      <input type="text" ref={setCustomUsername} />
                    </div>
                    <div className="k-klog-pform-input">
                      <p>Password</p>
                    </div>
                    <div
                      className={setClass(
                        'k-klog-pform-forgot-pwd',
                        setting.isIncludePasswordUrl &&
                          isDrawerOpen &&
                          '--show',
                      )}
                    >
                      <p>
                        If you forgot your password, you can reset it by{' '}
                        <i>clicking here</i>.
                      </p>
                      <p style={{ display: 'none' }}>
                        <span>
                          <img
                            className="k-klog-forgot-pwd-img"
                            alt="Restart"
                            draggable={false}
                            src={restartRight}
                          />
                        </span>
                        <p>Restart and show password reset options</p>
                      </p>
                      <div className="k-klog-forgot-pwd-tip">
                        <img draggable={false} alt="Message" src={tip} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="k-klog-pmsg"
                style={isNewOsTab ? { bottom: '175px' } : {}}
              >
                {setting.lockMessageOption === 'custom' && (
                  <p>{setting.customLockMessage}</p>
                )}
              </div>
              <div className="k-klog-pfooter">
                <div className="k-klog-footer-button --help">
                  <ReactSVG className="k-klog-footer-svg" src={help} />
                  <p>Help</p>
                </div>
                {!isNewOsTab && (
                  <div className="k-klog-power-buttons">
                    <div
                      hidden={!setting.isShutdownButton}
                      className="k-klog-footer-button --power"
                    >
                      <ReactSVG className="k-klog-footer-svg" src={power} />
                      <p>Shut Down</p>
                    </div>

                    <div
                      hidden={!setting.isRestartButton}
                      className="k-klog-footer-button --restart"
                    >
                      <ReactSVG className="k-klog-footer-svg" src={restart} />
                      <p>Restart</p>
                    </div>

                    <div
                      hidden={!setting.isSleepButton}
                      className="k-klog-footer-button --sleep"
                    >
                      <ReactSVG className="k-klog-footer-svg" src={sleep} />
                      <p>Sleep</p>
                    </div>

                    {authSetting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN &&
                      loginTab === loginTabs.WEB_LOGIN && (
                        <div className="k-klog-footer-button">
                          <ReactSVG
                            className="k-klog-footer-svg"
                            src={authmode}
                          />
                          <p>Local Login</p>
                        </div>
                      )}

                    {authSetting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN &&
                      loginTab === loginTabs.LOCAL_LOGIN && (
                        <div className="k-klog-footer-button">
                          <ReactSVG
                            className="k-klog-footer-svg"
                            src={webloginicon}
                          />
                          <p>Web Login</p>
                        </div>
                      )}
                  </div>
                )}
                <div className="k-klog-footer-logo">
                  <img draggable="false" src={footerLogo} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer
        isVisible={isDrawerOpen}
        setIsVisible={setIsDrawerOpen}
        setting={setting}
        authSetting={authSetting}
        update={update}
        onClose={() => restoreData(beforeEditModel)}
      />
    </Setting.Card>
  );
};

export default LoginScreen;
