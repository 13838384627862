import { Button, Flex } from '@kandji-inc/nectar-ui';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import useDebouncedState from 'src/features/compliance/Policy/Devices/useDebouncedState';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import EDRSearchFilter from 'src/features/edr/common/components/Filters/EDRSearchFilter';
import { useShallow } from 'zustand/react/shallow';
import { SeverityMultiSelect } from '../../common/table/filters/severity-multi-select';
import { DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS } from '../../constants';
import {
  defaultAllVulnerabilitiesFilter,
  defaultDateFilter,
} from '../../constants';
import useVulnerability from '../../store';

const FilterBar = () => {
  const [filter, setFilter] = useVulnerability(
    useShallow((state) => [
      state.allVulnerabilitiesFilter,
      state.setAllVulnerabilitiesFilter,
    ]),
  );

  const [debouncedSearchValue, setSearchValue, searchValue] =
    useDebouncedState<string>('', 250);

  useEffect(
    /* istanbul ignore next*/ () => {
      setFilter({ term: debouncedSearchValue });
    },
    [debouncedSearchValue],
  );

  useEffect(
    /* istanbul ignore next */ () => {
      setSearchValue(filter.term);
    },
    [filter],
  );

  useEffect(() => {
    return () => setFilter(defaultAllVulnerabilitiesFilter);
  }, []);

  return (
    <Flex p3 gap="md" css={{ paddingLeft: '$5' }}>
      <EDRSearchFilter
        name="vulnerability"
        value={searchValue}
        onChange={(_, value) => setSearchValue(value as string)}
        onClear={() => setSearchValue('')}
        placeholder="Search ID and Application"
      />

      <SeverityMultiSelect
        severity={filter.severity}
        setSeverity={(value) => setFilter({ severity: value })}
      />

      <EDRDetectionDateFilter
        filter={filter.firstDetected}
        label="First detected"
        options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        onChange={
          /*  istanbul ignore next */ (_, fields: DetectionDateFilterFields) =>
            setFilter({ firstDetected: fields })
        }
        onClear={
          /*  istanbul ignore next */ () =>
            setFilter({ firstDetected: defaultDateFilter })
        }
      />

      <EDRDetectionDateFilter
        filter={filter.latestDetected}
        label="Last detected"
        options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        onChange={
          /* istanbul ignore next */ (_, fields: DetectionDateFilterFields) =>
            setFilter({ latestDetected: fields })
        }
        onClear={
          /*  istanbul ignore next */ () =>
            setFilter({ latestDetected: defaultDateFilter })
        }
      />

      {!isEqual(filter, defaultAllVulnerabilitiesFilter) && (
        <Button
          variant="subtle"
          compact
          onClick={() => setFilter(defaultAllVulnerabilitiesFilter)}
        >
          Clear all
        </Button>
      )}
    </Flex>
  );
};

export { FilterBar };
