import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import ClassificationFilterDropdown from '../../common/components/ThreatListFilters/ClassificationFilterDropdown';
import StatusFilter from '../../common/components/ThreatListFilters/StatusFilter';
import constants from '../../common/constants';
import type { FiltersState } from '../../threat.types';

type ThreatListFilterFieldsProps = {
  filters: FiltersState;
  onApply: OnApply;
  onClear: OnClear;
  malwareCount: number | undefined;
  pupCount: number | undefined;
  benignCount: number | undefined;
  unknownCount: number | undefined;
};

const ThreatListFilterFields = (props: ThreatListFilterFieldsProps) => {
  const {
    filters,
    onApply,
    onClear,
    malwareCount,
    pupCount,
    benignCount,
    unknownCount,
  } = props;

  return (
    <>
      <EDRDetectionDateFilter
        filter={filters.detectionDate}
        onChange={onApply}
        onClear={onClear}
        options={constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
      />
      <StatusFilter
        values={filters.status}
        onChange={onApply}
        onClear={onClear}
      />
      <ClassificationFilterDropdown
        value={filters.classification}
        onChange={onApply}
        onClear={onClear}
        malwareCount={malwareCount}
        pupCount={pupCount}
        benignCount={benignCount}
        unknownCount={unknownCount}
      />
    </>
  );
};

export default ThreatListFilterFields;
