import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';
import featureFlags from 'src/config/feature-flags';
import './app-store-apps.css';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { deviceTypes } from 'features/library-items/library/common';
import {
  InstallationCard,
  SSLink,
  useSsInstall,
} from 'features/library-items/template';
import ActivityTab from '../../common/activity-tab';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithoutCustomIconPreset from '../../common/self-service-card/drawer/presets/without-custom-icon-preset';
import { MsAuthenticatorSettingCard } from './additional-settings/ms-authenticator/ms-authenticator-setting-card';
import OktaDeviceTrust from './additional-settings/okta/okta-device-trust';
import ConfigurationCard from './configuration-card';
import AppStoreAppsProvider from './hooks/app-store-apps-provider';
import initial from './initial-state';
import LicensesCard from './licenses-card';
import OptionsCard from './options-card';
import ReturnToServiceCard from './return-to-service/return-to-service-card';
import AppStoreAppsService, {
  appStoreAppsService,
} from './service/app-store-apps-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useAppStoreAppsService from './service/use-app-store-apps-service';
import { getConfigCardBanners } from './utils/get-app-config-banners';
import { getExtraBanners } from './utils/get-extra-banners';
import { getExtraSummary } from './utils/get-extra-summary';

const getDeviceFamily = (supportedDeviceFamilies) => {
  const keyLabelMap = {
    mac: deviceTypes.MAC,
    ipad: deviceTypes.IPAD,
    iphone: deviceTypes.IPHONE,
    ipod: deviceTypes.IPHONE,
    tv: deviceTypes.TV,
  };

  const devices = Object.keys(keyLabelMap)
    .filter((key) => supportedDeviceFamilies[key])
    .map((key) => keyLabelMap[key]);

  const devicesWithRemovedDuplications = Array.from(new Set(devices));

  return devicesWithRemovedDuplications;
};

const installationOptions = [
  {
    value: AppStoreAppsService.installationTypes.CONTINUOUSLY_ENFORCE,
    label: 'Install and Continuously Enforce',
  },
  {
    value: AppStoreAppsService.installationTypes.NO_ENFORCEMENT,
    label: 'Install on-demand from Self Service',
  },
];

const installationHelper = (
  <p className="b-txt-light">
    Determine how this App Store app should be installed. Setting this option to
    install and continuously enforce will allow this item to also be optionally
    available in Self Service. Setting this option to Install on-demand from
    Self Service will not manage the app until the app is installed via Self
    Service. Once done the App will respect your global Apps and Books update
    settings. <SSLink />
  </p>
);

const AppStoreAppsPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();

  const isKandjiSelfService = AppStoreAppsService.getIsKandjiSelfService(model);
  const updateInstallation = useCallback(update('installation', setModel), []);
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateOptions = useCallback(update('options', setModel), []);
  const returnToServiceSettings = useCallback(
    update('returnToService', setModel),
    [],
  );
  const updateConfiguration = useCallback(
    update('configuration', setModel),
    [],
  );
  const updateOktaDeviceTrust = useCallback(
    update('oktaDeviceTrust', setModel),
    [],
  );
  const updateMsDeviceCompliance = useCallback(
    update('msDeviceCompliance', setModel),
    [],
  );

  const isOktaDeviceTrust = model.bundleId === 'com.okta.mobile';
  const msdcMsAuthenticatorFF = featureFlags.getFlag(
    'splint-msdc-microsoft-authenticator-li',
  );
  const isMsAuthenticator =
    msdcMsAuthenticatorFF /* istanbul ignore next -- temp for feature flag */ &&
    /* istanbul ignore next -- temp for feature flag */
    model.bundleId === 'com.microsoft.azureauthenticator';

  const isSsForced = useSsInstall({
    installType: model.installation.type,
    ssType: AppStoreAppsService.installationTypes.NO_ENFORCEMENT,
    updateSs,
  });

  const isOktaDeviceTrustEnabled = model?.oktaDeviceTrust?.isEnabled;

  const isConfigCardDisabled = isOktaDeviceTrustEnabled || pageState.isDisabled;

  return (
    <AppStoreAppsProvider>
      <LibraryItemPage
        {...props}
        crumb={model.name}
        isNameEditable={false}
        service={appStoreAppsService}
        triggerValidation={triggerValidation}
        summaryInfoProps={{
          name: 'App Store App',
          description:
            'Buy App Store apps in Apple Business Manager and assign them to your organization’s devices.',
          publisher: model.publisher,
          devices: getDeviceFamily(model.supportedDeviceFamilies),
          base_device_families: getDeviceFamily(model.baseDeviceFamilies),
          requirements: model.osRequirements,
        }}
        transformFromApi={transformFromApi}
        transformToApi={transformToApi}
        ActivityTab={ActivityTab}
        isDeletable={false}
        StatusTab={NewStatusTab}
        getStatusTabProps={getNewStatusTabProps}
        supportsRules
        supportsInstallOn
        extraBanners={getExtraBanners(model.bundleId, { pageState })}
        extraSummary={getExtraSummary(model.bundleId, { pageState })}
      >
        {!isKandjiSelfService &&
          (model.supportedDeviceFamilies.mac ||
            model.supportedDeviceFamilies.ipad ||
            model.supportedDeviceFamilies.iphone) && (
            <>
              <InstallationCard
                setting={model.installation}
                update={updateInstallation}
                isDisabled={pageState.isDisabled}
                options={installationOptions}
                helper={installationHelper}
              />
              <SelfServiceCard
                setting={model.selfService}
                update={updateSs}
                isDisabled={pageState.isDisabled}
                defaults={{
                  icon: model.icon,
                  name: model.name,
                  shortDescription: model.subtitle,
                  longDescription: model.description,
                }}
                canBeDisabled={!isSsForced}
                validationDep={validationDep}
                isSubmitted={pageState.isSubmitted}
                DrawerContent={WithoutCustomIconPreset}
              />
            </>
          )}
        <LicensesCard setting={model.licenses} />

        {isOktaDeviceTrust && (
          <>
            <OktaDeviceTrust
              setting={model.oktaDeviceTrust}
              appConfigurationSetting={model.configuration}
              update={updateOktaDeviceTrust}
              isDisabled={pageState.isDisabled}
              isEditing={pageState.isEditing}
            />
          </>
        )}

        {isMsAuthenticator && (
          <MsAuthenticatorSettingCard
            setting={model.msDeviceCompliance}
            update={updateMsDeviceCompliance}
            isDisabled={pageState.isDisabled}
            isEditing={pageState.isEditing}
          />
        )}

        <OptionsCard
          setting={model.options}
          update={updateOptions}
          isDisabled={pageState.isDisabled}
          isMac={model.supportedDeviceFamilies.mac}
        />

        {featureFlags.getFlag('lit_01182024_ios-vpp-app-rts-section') &&
          isKandjiSelfService && (
            <ReturnToServiceCard
              setting={model.returnToService}
              update={returnToServiceSettings}
              isDisabled={pageState.isDisabled}
              isMac={model.supportedDeviceFamilies.mac}
            />
          )}

        {/* istanbul ignore next */}
        {!isKandjiSelfService && (
          <ConfigurationCard
            setting={model.configuration}
            update={updateConfiguration}
            isDisabled={isConfigCardDisabled}
            supportedDeviceFamilies={model.supportedDeviceFamilies}
            isSubmitted={pageState.isSubmitted}
            isEditing={pageState.isEditing}
            validationDep={validationDep}
            cardBanner={getConfigCardBanners(model, pageState)}
          />
        )}
      </LibraryItemPage>
    </AppStoreAppsProvider>
  );
};

const AppStoreApps = () => {
  const msdcMsAuthenticatorFF = featureFlags.getFlag(
    'splint-msdc-microsoft-authenticator-li',
  );

  /* istanbul ignore next -- temp for feature flag */
  const initialState = msdcMsAuthenticatorFF
    ? {
        ...initial,
        msDeviceCompliance: {
          isEnabled: false,
        },
      }
    : initial;

  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useAppStoreAppsService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <AppStoreAppsPage {...pageProps} testId="app-store-apps-v2" />;
};

export default AppStoreApps;
