import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import NoResultsFoundImg from '../../assets/no-results-found.svg';

const NoResultsFound = () => (
  <Flex alignItems="center" justifyContent="center" flow="column" hFull>
    <img src={NoResultsFoundImg} alt="no-results-found" />
    <Heading size="4" css={{ fontWeight: '$medium', paddingTop: '$3' }}>
      No results found
    </Heading>
    <Text>Try changing the filter or search with different keywords.</Text>
  </Flex>
);

export { NoResultsFound };
