import type {
  DateFilterEnumOption,
  DateFilterTypeDetails,
  DateFilterTypeHandlerArgs,
} from '@kandji-inc/nectar-ui';
import { DateFilterType } from '@kandji-inc/nectar-ui';
import type {
  DetectionDateFilterFields,
  OnApply,
  OnClear,
} from '../../common.types';
import { DETECTION_DATE_SELECT_OPERATOR_OPTIONS } from '../../constants';

const mapStateToComponent = (
  key: string,
  label: string,
  state: DetectionDateFilterFields,
): DateFilterTypeDetails => {
  const { dateRangeFrom, dateRangeTo, operator, selectedDate, value } = state;

  const filterFields: DateFilterTypeDetails = {
    key,
    label,
    value: value || '',
    operator: operator || '',
    dateRange: {
      from: dateRangeFrom ? new Date(dateRangeFrom) : null,
      to: dateRangeTo ? new Date(dateRangeTo) : null,
    },
    selectedDate: selectedDate ? new Date(selectedDate) : null,
  };

  return filterFields;
};

const mapComponentToState = (
  fields: DateFilterTypeHandlerArgs,
): DetectionDateFilterFields => {
  const {
    filterValue,
    operatorValue,
    dateRange: { from, to },
    selectedDate,
  } = fields;

  const state: DetectionDateFilterFields = {
    value: filterValue || '',
    operator: operatorValue || '',
    dateRangeFrom: from?.toISOString() || '',
    dateRangeTo: to?.toISOString() || '',
    selectedDate: selectedDate?.toISOString() || '',
  };

  // Clear all custom_date_range dependent fields to keep the state clean.
  // Possibly a Nectar bug rather than a feature. This logic should probably live in Nectar.
  if (state.value !== 'custom_date_range') {
    state.dateRangeFrom = '';
    state.dateRangeTo = '';
    state.selectedDate = '';
    state.operator = '';
  }

  // Get rid of keys with empty values to keep the state clean.
  // This logic should probably live in Nectar
  const newState = Object.fromEntries(
    Object.entries(state).filter(([_, value]) => value !== ''),
  );

  return newState;
};

type DetectionDateFilterProps = {
  filter: DetectionDateFilterFields | undefined;
  onChange: OnApply;
  onClear: OnClear;
  options: ReadonlyArray<DateFilterEnumOption>;
  maxWidth?: number;
  label?: string;
};

const EDRDetectionDateFilter = (props: DetectionDateFilterProps) => {
  const {
    filter,
    onChange,
    onClear,
    options,
    maxWidth = 410,
    label = 'Detection Date',
  } = props;

  const filterName = 'detectionDate';

  const filterFields: DateFilterTypeDetails = mapStateToComponent(
    filterName,
    label,
    filter || {},
  );

  const handleApply = (fields: DateFilterTypeHandlerArgs) => {
    const state = mapComponentToState(fields);
    onChange(filterName, state);
  };

  return (
    <DateFilterType
      key={filterName}
      filter={filterFields}
      operatorOptions={DETECTION_DATE_SELECT_OPERATOR_OPTIONS}
      enumOptions={options}
      handleApply={handleApply}
      resetOnClear={false}
      handleClear={() => onClear(filterName)}
      // @ts-expect-error -- handleRemove is not optional, but it's not needed here
      handleRemove={undefined}
      showRemove={false}
      showCancel={false}
      clearLabel="Clear"
      dropdownProps={{
        css: {
          zIndex: 3,
        },
        contentProps: {
          align: 'start',
        },
      }}
      buttonProps={{ maxWidth, itemMaxWidth: 200 }}
      testId="detection-date-filter"
    >
      {(filterButtonProps) => (
        <>
          {`${label}${filterButtonProps.selectedFilters.length ? ': ' : ''}`}
          <strong>
            {filterButtonProps.selectedFilters[0]?.value ===
              'custom_date_range' && filterButtonProps.operator?.label}
          </strong>
        </>
      )}
    </DateFilterType>
  );
};

export default EDRDetectionDateFilter;
