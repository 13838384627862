/* istanbul ignore file */
declare global {
  interface Window {
    dataLayer: any[];
  }
}

window.dataLayer = window.dataLayer || [];

function gtag(...args: any[]) {
  window.dataLayer.push(args);
}

function gtagEvent(eventName: string) {
  window.dataLayer.push({ event: eventName });
}

export const initializeGTM = () => {
  if (
    process.env.REACT_APP_ENV === 'PRD' ||
    process.env.REACT_APP_ENV === 'PRD-EU'
  ) {
    const gtmId = 'GTM-KLSQWBHS';

    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s) as HTMLScriptElement,
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode?.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmId);

    console.log('[Google Tag Manager] Initialized');
  } else {
    console.log(
      '[Google Tag Manager] Not initialized (non-production environment)',
    );
  }
};

export { gtag, gtagEvent };
