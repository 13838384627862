import {
  Box,
  Chip,
  Flex,
  Icon,
  Text,
  Tooltip,
  TooltipProvider,
} from '@kandji-inc/nectar-ui';
import type { DropdownMenuOption } from '@kandji-inc/nectar-ui';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import EDRCopyButton from 'src/features/edr/common/components/EDRCopyButton';
import { highlightedText } from 'src/pages/ADEListView/utils/highlightText';
import TableActionsDropdown from '../../../threat/common/components/ThreatListTable/TableActionsDropdown';
import { HighlightedTextWithTooltip } from '../../components/highlighted-text-with-tooltip';
import { SeverityProgressChips } from '../../components/severity-progress-chips';
import { SeverityTooltip } from '../../components/severity-tooltip';
import { formatDate } from '../../helpers';
import useVulnerability, { type VulnerabilityState } from '../../store';
import type { Vulnerability } from '../../vulnerability.types';

const getVulnerabilityIdCell = (
  info: CellContext<Vulnerability, string>,
  filter:
    | VulnerabilityState['allVulnerabilitiesFilter']
    | VulnerabilityState['deviceVulnerabilitiesFilter'],
) => {
  const { cve_id } = info.row.original;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Flex
      flow="row"
      gap="xs"
      alignItems="center"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {highlightedText(cve_id, filter.term)}
      {isHovering && <EDRCopyButton value={cve_id} />}
    </Flex>
  );
};

const getApplicationCell = (
  info: CellContext<Vulnerability, string>,
  filter:
    | VulnerabilityState['allVulnerabilitiesFilter']
    | VulnerabilityState['deviceVulnerabilitiesFilter'],
) => {
  const { app_name } = info?.row?.original;

  /* istanbul ignore next */
  if (!app_name) {
    return null;
  }

  return (
    <HighlightedTextWithTooltip
      text={app_name}
      searchTerm={filter.term}
      width={150}
    />
  );
};

const getVersionsHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>Version</Text>
      <Tooltip side="top" content="Latest version">
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getVersionsCell = (info: CellContext<Vulnerability, string>) => {
  const { versions } = info?.row?.original;

  /* istanbul ignore next */
  if (!versions || !versions.length) {
    return '-';
  }

  return (
    <Flex gap="xs">
      <Text>{`${versions[0]}${versions.length > 1 ? ',' : ''}`}</Text>
      {versions.length > 1 && (
        <Tooltip
          content={versions.slice(1).join(', ')}
          side="bottom"
          theme="dark"
          css={{ zIndex: 100 }}
        >
          <Text> +{versions.length - 1}</Text>
        </Tooltip>
      )}
    </Flex>
  );
};

const getSeverityCell = (info: CellContext<Vulnerability, string>) => {
  const { severity } = info?.row?.original;

  /* istanbul ignore next */
  if (!severity || severity === 'Undefined') {
    return (
      <Text variant="secondary" css={{ fontStyle: 'italic' }}>
        Pending
      </Text>
    );
  }

  return (
    <Flex>
      <SeverityTooltip severity={severity}>
        <Flex>
          <SeverityProgressChips severity={severity} />
          <Text>{severity}</Text>
        </Flex>
      </SeverityTooltip>
    </Flex>
  );
};

const getCVSSScoreCell = (info: CellContext<Vulnerability, string>) => {
  const { cvss_score } = info?.row?.original;

  /* istanbul ignore next */
  if (!cvss_score) {
    return (
      <Text variant="secondary" css={{ fontStyle: 'italic' }}>
        Pending
      </Text>
    );
  }

  return <Text>{cvss_score}</Text>;
};

const getDetectionDateCell = (date: string) => (
  <Text title={formatDate({ date, showTimestamp: true })}>
    {formatDate({ date })}
  </Text>
);

const getDevicesHeader = () => (
  <TooltipProvider>
    <Flex gap="xs" alignItems="center">
      <Text css={{ fontWeight: '$medium' }}>Devices</Text>
      <Tooltip side="top" content="Devices impacted" css={{ zIndex: 2 }}>
        <Box>
          <Icon name="circle-info" size="sm" color="var(--color-neutral80)" />
        </Box>
      </Tooltip>
    </Flex>
  </TooltipProvider>
);

const getDevicesCell = (info: CellContext<Vulnerability, string>) => {
  const { device_count } = info?.row?.original;

  /* istanbul ignore next */
  if (!device_count) {
    return null;
  }

  return (
    <Chip
      title="Devices impacted"
      color="unfilled"
      iconLeft={{ icon: 'sf-desktop-computer', size: 'sm' }}
      label={device_count}
    />
  );
};

const getActionsDropdownCell = (info: CellContext<Vulnerability, string>) => {
  const { cve_link } = info?.row?.original;

  /* istanbul ignore next */
  if (!cve_link) {
    return null;
  }

  const options: DropdownMenuOption[] = [
    {
      label: 'Search NVD',
      icon: 'nvd',
      onClick: (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(cve_link, '_blank');
      },
    },
  ];

  return <TableActionsDropdown options={options} testId="actions-dropdown" />;
};

export const getColumns = (
  props: { isOnDeviceRecord: boolean } = { isOnDeviceRecord: false },
) => {
  const { isOnDeviceRecord } = props;
  const columnHelper = createColumnHelper<Vulnerability>();

  const filterType = isOnDeviceRecord
    ? 'deviceVulnerabilitiesFilter'
    : 'allVulnerabilitiesFilter';
  const filter = useVulnerability((state) => state[filterType]);

  const columns: ColumnDef<Vulnerability>[] = [
    columnHelper.accessor('cve_id', {
      id: 'cve_id',
      cell: (row) => getVulnerabilityIdCell(row, filter),
      header: () => 'Vulnerability ID',
      minSize: 230,
    }),
    columnHelper.accessor('app_name', {
      id: 'app_name',
      cell: (row) => getApplicationCell(row, filter),
      header: () => 'Application',
      // @ts-ignore
      title: null,
      minSize: 190,
    }),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('versions', {
            id: 'versions',
            cell: getVersionsCell,
            header: getVersionsHeader,
            enableSorting: false,
            // @ts-ignore
            title: null,
            minSize: 200,
          }),
        ]
      : []),
    ...(isOnDeviceRecord
      ? [
          columnHelper.accessor('app_version', {
            id: 'app_version',
            cell: (row) => row.getValue(),
            header: getVersionsHeader,
            enableSorting: false,
            // @ts-ignore
            title: null,
            minSize: 200,
          }),
        ]
      : []),
    columnHelper.accessor('severity', {
      id: 'severity',
      cell: getSeverityCell,
      header: () => 'Severity',
      // @ts-ignore
      title: null,
      minSize: 220,
    }),
    columnHelper.accessor('cvss_score', {
      id: 'cvss_score',
      cell: getCVSSScoreCell,
      header: () => 'CVSS score',
      maxSize: 120,
    }),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('first_detection_date', {
            id: 'first_detection_date',
            cell: (row) => getDetectionDateCell(row.getValue()),
            header: () => 'First detected',
          }),
        ]
      : []),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('latest_detection_date', {
            id: 'latest_detection_date',
            cell: (row) => getDetectionDateCell(row.getValue()),
            header: () => 'Last detected',
          }),
        ]
      : []),
    ...(!isOnDeviceRecord
      ? [
          columnHelper.accessor('device_count', {
            id: 'device_count',
            cell: getDevicesCell,
            header: getDevicesHeader,
            // @ts-ignore
            title: 'Devices impacted',
            maxSize: 120,
          }),
        ]
      : []),
    ...(isOnDeviceRecord
      ? [
          columnHelper.accessor('detection_datetime', {
            id: 'detection_datetime',
            cell: (row) => getDetectionDateCell(row.getValue()),
            header: () => 'Detection date',
          }),
        ]
      : []),
    columnHelper.accessor((row) => row, {
      id: 'actions',
      cell: getActionsDropdownCell,
      enableSorting: false,
      maxSize: 40,
    }),
  ];

  return columns;
};
