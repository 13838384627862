/* istanbul ignore file */
import { $t, getI18nLocale, render, t, ut } from '@kandji-inc/nectar-i18n';

import { common } from './common';

const DEFAULT_I18N_PROVIDER = 'noop';
const DEFAULT_I18N_LOCALE = 'en';

const LOCALES = {
  ENGLISH: DEFAULT_I18N_LOCALE,
  GERMAN: 'de_DE',
  SPANISH: 'es_ES',
  JAPANESE: 'ja_JP',
} as const;

const createMap =
  <TMap extends Record<string, () => string>>(map: TMap) =>
  (variable: keyof TMap) => {
    if (variable in map) {
      const translationFn = map[variable];
      if (translationFn) {
        return translationFn();
      }
    }
    throw new Error(`Missing translation for variable: ${String(variable)}`);
  };

export const i18n = {
  getLocale: getI18nLocale, // wrapped to consolidate imports
  render, // wrapped to consolidate imports
  t, // wrapped to consolidate imports
  ut, // wrapped to consolidate imports
  $t, // wrapped to consolidate imports
  createMap,
  common,
  DEFAULT_I18N_PROVIDER,
  DEFAULT_I18N_LOCALE,
  LOCALES,
};
