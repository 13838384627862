/* istanbul ignore file */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { billingTypes } from 'src/app/common/constants';
import { useAuth0 } from 'src/auth0';
import { webApiAudience } from 'src/config';
import { gtagEvent } from 'src/config/gtm';

const useIsFirstTrialUserSession = () => {
  const { getIdTokenClaims } = useAuth0();
  const { ownerId, currentUserId, billingType } = useSelector((state: any) => ({
    ownerId: state.account?.company?.owner,
    currentUserId: state.account?.user?.id,
    billingType: state.account?.company?.billing_type,
  }));

  const isOwner = !!ownerId && !!currentUserId && ownerId === currentUserId;

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        const claims = await getIdTokenClaims();
        const userLoginCount =
          claims[`${webApiAudience}claims/user/loginsCount`];
        if (userLoginCount === 1) {
          gtagEvent('trial.account_success');
        }
      } catch (error) {
        console.error('Error fetching claims', error);
      }
    };

    if (isOwner && billingType === billingTypes.AUTOMATED_TRIAL) {
      fetchClaims();
    }
  }, [getIdTokenClaims, isOwner, billingType]);
};

export default useIsFirstTrialUserSession;
