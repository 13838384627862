import { Button, Chip, Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import { usePrismAIContext } from '../../contexts/PrismAIContext';

export const ChatHeader = ({
  onCreateNewChat,
  onOpenChange,
}: {
  onCreateNewChat: () => void;
  onOpenChange: () => void;
}) => {
  const { isFullWidth, toggleWidth } = usePrismAIContext();
  return (
    <Header>
      <Flex gap="sm" alignItems="center">
        <Text as="h2">{i18n.t('Kandji AI')}</Text>
        {/* <Button compact variant="subtle">
          <Icon name="fa-angle-down-small" />
        </Button> */}
        <Button
          compact
          variant="subtle"
          onClick={() => onCreateNewChat()}
          data-testid="create-new-chat-button"
        >
          <PenToSquareIcon />
        </Button>
        <Chip
          label={i18n.t('Alpha')}
          color="neutral"
          css={{
            // TODO: move to Nectar as a subtle variant
            border: '1px solid $neutral30',
            background: 'rgba(255, 255, 255, 0.00)',
            color: '$neutral60',
          }}
        />
      </Flex>
      <Flex gap="sm" alignItems="center">
        <Button
          compact
          variant="subtle"
          onClick={toggleWidth}
          aria-label={i18n.t('Toggle chat panel width')}
          data-testid="resize-icon"
        >
          {isFullWidth ? (
            <Icon name="arrow-down-left-and-arrow-up-right-to-center" />
          ) : (
            <Icon name="arrow-up-right-and-arrow-down-left-from-center" />
          )}
        </Button>
        <Button
          compact
          variant="subtle"
          onClick={onOpenChange}
          data-testid="close-button"
        >
          <Icon name="fa-xmark-large-close" />
        </Button>
      </Flex>
    </Header>
  );
};

const Header = styled(Flex, {
  display: 'flex',
  paddingTop: '24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  flexShrink: 0,

  '& h2': {
    color: '$neutral90',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '36px /* 150% */',
    letterSpacing: '-0.8px',
  },

  '& svg': {
    height: '20px',
    width: '20px',
    color: '#505E71',
  },
});

const PenToSquareIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9055 2.35832C14.8272 1.43606 16.3229 1.43606 17.2446 2.35832L17.6415 2.75587C18.5632 3.67823 18.5632 5.17361 17.6415 6.09532L11.1732 12.5669C10.8878 12.849 10.5368 13.0556 10.1498 13.1672L6.86969 14.1053C6.59416 14.184 6.29895 14.1053 6.09559 13.8757C5.8955 13.7018 5.81678 13.4066 5.8955 13.1311L6.83361 9.85101C6.94513 9.46396 7.15177 9.11299 7.43386 8.82762L13.9055 2.35832ZM16.1031 3.47158C15.8243 3.16424 15.3257 3.16424 15.0174 3.47158L14.0629 4.42576L15.575 5.93787L16.5295 4.95385C16.8378 4.67504 16.8378 4.17647 16.5295 3.86913L16.1031 3.47158ZM8.349 10.284L7.79795 12.2028L9.71679 11.6518C9.848 11.6157 9.9628 11.5468 10.0579 11.4517L14.4631 7.04982L12.951 5.5377L8.54909 9.94285C8.45396 10.038 8.38508 10.1528 8.349 10.284ZM8.22764 3.63854C8.66389 3.63854 9.01486 3.99115 9.01486 4.42576C9.01486 4.86201 8.66389 5.21297 8.22764 5.21297H4.55395C3.82938 5.21297 3.24192 5.80011 3.24192 6.52501V15.4468C3.24192 16.1717 3.82938 16.7589 4.55395 16.7589H13.4758C14.2007 16.7589 14.7878 16.1717 14.7878 15.4468V11.7731C14.7878 11.3369 15.1388 10.9859 15.575 10.9859C16.0113 10.9859 16.3622 11.3369 16.3622 11.7731V15.4468C16.3622 17.0409 15.0699 18.3333 13.4758 18.3333H4.55395C2.95983 18.3333 1.66748 17.0409 1.66748 15.4468V6.52501C1.66748 4.93089 2.95983 3.63854 4.55395 3.63854H8.22764Z"
      fill="#505E71"
    />
  </svg>
);
