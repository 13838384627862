/* istanbul ignore file */
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Icon,
  Separator,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { AccountContext } from 'src/contexts/account';
import { i18n } from 'src/i18n';
import { paths } from '../blueprints/common';
import { useGetLibrary } from '../library-items/data-service/library/useGetLibraryItems';
import CheckComplete from './assets/checkbox.svg';
import DoubleMac from './assets/double-mac.png';
import { useGetWalkthrough } from './useGetWalkthrough';
import { useMutateWalkthrough } from './useMutateWalkthrough';
import { getSteps } from './walkthrough-steps';

const StepIcon = styled(Flex, {
  width: '32px',
  height: '32px',
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$neutral50',
  borderRadius: '3px',
  border: '1px solid #D7E1ED',
  background: '#FFF',
  boxShadow: '0px 3.82px 9.3px 0px rgba(37, 55, 73, 0.15)',

  variants: {
    isComplete: {
      true: {
        boxShadow: 'unset',
        background: 'unset',
        border: 'unset',

        '& img': {
          width: '17px',
          height: '17px',
        },
      },
    },
  },
});

const Walkthrough = () => {
  const history = useHistory();
  const { currentCompany, userId, userFirstName, userLastName } =
    useContext(AccountContext);
  const [stepsCompleted, setStepsCompleted] = useState([]);
  const [activeStep, setActiveStep] = useState('explore');
  const { data: libraryItemsRes } = useGetLibrary({
    loc: 'webList',
  });
  const { data: walkthroughCompletionData, refetch } = useGetWalkthrough();
  const { mutateAsync } = useMutateWalkthrough();
  const hasLibraryItems = (libraryItemsRes as any)?.data?.length > 0;

  const markStep = (key, value) => {
    mutateAsync({
      [key]: value,
    })
      .catch(() => console.log(`Failed to update trial step - '${key}'`))
      .finally(refetch);
  };

  const steps = getSteps(
    stepsCompleted,
    markStep,
    walkthroughCompletionData,
    hasLibraryItems,
  );
  const stepsInOrder = steps
    .filter((step) => !step.isViewOnly)
    .map((step) => step.id);

  useEffect(() => {
    if (walkthroughCompletionData) {
      const completedKeys = Object.keys(walkthroughCompletionData).filter(
        (key) => stepsInOrder.includes(key) && walkthroughCompletionData[key],
      );
      setStepsCompleted(completedKeys);

      const [firstAvailableStep] = stepsInOrder.filter(
        (step) => !completedKeys.includes(step),
      );

      if (firstAvailableStep) {
        setActiveStep(firstAvailableStep);
      }
    }
  }, [walkthroughCompletionData]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test' && stepsCompleted.length) {
      window.pendo?.track('Trial Dashboard', {
        tenantId: currentCompany?.id,
        userId,
        userFirstName,
        userLastName,
        stepsCompleted,
      });
    }
  }, [stepsCompleted]);

  const isComplete = stepsCompleted.length === stepsInOrder.length;

  return (
    <Box>
      <Card css={{ padding: 0 }}>
        <Flex alignItems="center" gap="md" p4>
          <Heading size="5">{i18n.t('Get set up')}</Heading>
          <Flex alignItems="center" gap="xs" css={{ color: '$neutral70' }}>
            <Text
              variant="secondary"
              css={{ fontSize: '12px', fontWeight: 500 }}
            >
              {isComplete
                ? i18n.t('All done!')
                : i18n.t('{stepsCompleted}/{stepsInOrder} done', {
                    stepsCompleted: stepsCompleted.length,
                    stepsInOrder: stepsInOrder.length,
                  })}
            </Text>
            {isComplete && <Icon name="party-horn" size="sm" />}
          </Flex>
        </Flex>

        <Separator />

        {isComplete ? (
          <Flex
            id="trial_walkthrough_complete"
            css={{ height: '458px', backgroundColor: '$neutral05' }}
            gap="lg"
            alignItems="center"
            flow="column"
            py5
          >
            <Flex flow="column" gap="xs" alignItems="center">
              <img
                src={DoubleMac}
                alt="mac"
                style={{ width: '427px', height: '215px' }}
              />
              <Flex
                flow="column"
                alignItems="center"
                css={{ maxWidth: '357px' }}
              >
                <Heading size="2">{i18n.t('All systems go.')}</Heading>
                <Text css={{ lineHeight: '20px' }}>
                  {i18n.t(
                    "You're all set to unlock the full power of Kandji. Let us know if you have any questions, we're here to help.",
                  )}
                </Text>
                <Grid columns="2" rows="2" gap="3" css={{ marginTop: '24px' }}>
                  <Button
                    compact
                    icon={{ name: 'grid-2' }}
                    onClick={() => history.push(links.library)}
                  >
                    {i18n.t('Explore Library')}
                  </Button>
                  <Button
                    compact
                    icon={{ name: 'kandji-blueprint' }}
                    onClick={() => history.push(paths.root)}
                  >
                    {i18n.t('Explore Blueprints')}
                  </Button>
                  <Button
                    compact
                    icon={{ name: 'circle-plus' }}
                    onClick={() => history.push('add-devices?tab=manual')}
                  >
                    {i18n.t('Add devices')}
                  </Button>
                  <Button
                    compact
                    icon={{ name: 'sf-desktop-computer' }}
                    onClick={() =>
                      history.push(
                        'my-company/integrations#automated-device-enrollment',
                      )
                    }
                  >
                    {i18n.t('Manage ADE')}
                  </Button>
                </Grid>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          // @ts-expect-error
          <Accordion collapsible onValueChange={setActiveStep}>
            {steps.map((step) => {
              const {
                id,
                title,
                description,
                icon,
                Image,
                Actions,
                isDismissable,
                timeToComplete,
                isViewOnly,
                iconCss,
              } = step;
              const isStepComplete = stepsCompleted.includes(id);

              if (activeStep === id) {
                return (
                  <Flex
                    id={`${id}_active`}
                    key={id}
                    css={{
                      minHeight: '215px',
                      maxHeight: '255px',
                      gap: '40px',
                      borderBottom: '1px solid #D7E1ED',

                      '&:last-child': {
                        borderBottom: 'none',
                      },
                    }}
                  >
                    <Flex
                      flex="1"
                      gap="md"
                      css={{ padding: '46px 16px', paddingBottom: '16px' }}
                    >
                      <StepIcon css={{ '& svg': iconCss }}>
                        <Icon name={icon} />
                      </StepIcon>
                      <Box></Box>
                      <Flex flow="column" gap="sm">
                        <Box>
                          {timeToComplete && (
                            <Text
                              size="1"
                              variant="secondary"
                              css={{ fontStyle: 'italic' }}
                            >
                              {timeToComplete}
                            </Text>
                          )}
                          <Heading
                            size="3"
                            css={{
                              fontWeight: 500,
                            }}
                          >
                            {title}
                          </Heading>
                          <Text>{description}</Text>
                        </Box>
                        <Actions />
                        {isDismissable && (
                          <Box>
                            <Button
                              id={`dismiss_${id}`}
                              variant="subtle"
                              css={{ marginTop: '24px' }}
                              onClick={() => markStep(`skip_${id}`, true)}
                            >
                              {i18n.t('Dismiss this step')}
                            </Button>
                          </Box>
                        )}
                      </Flex>
                    </Flex>
                    <Flex
                      css={{
                        backgroundColor: '$neutral05',
                        maxWidth: '392px',
                        minWidth: '360px',
                      }}
                    >
                      <Image />
                    </Flex>
                  </Flex>
                );
              }

              const Row = (
                <Flex
                  id={`${id}_${isStepComplete ? 'completed' : 'incomplete'}`}
                  p4
                  alignItems="center"
                  gap="md"
                  flex="1"
                >
                  <StepIcon
                    css={{ '& svg': iconCss }}
                    isComplete={isStepComplete}
                  >
                    {isStepComplete ? (
                      <img src={CheckComplete} alt="check" />
                    ) : (
                      <Icon name={icon} />
                    )}
                  </StepIcon>
                  <Text
                    size="2"
                    css={{
                      fontWeight: 500,
                      color: isStepComplete ? '$neutral40' : 'inherit',
                      textDecoration: isStepComplete ? 'line-through' : 'none',
                    }}
                  >
                    {title}
                  </Text>
                </Flex>
              );

              return (
                <AccordionItem
                  key={id}
                  value={id}
                  css={{
                    borderBottom: '1px solid #D7E1ED',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  {isViewOnly ? (
                    Row
                  ) : (
                    <AccordionTrigger
                      disabled={isStepComplete}
                      customIcon={isStepComplete ? <span /> : null}
                    >
                      {Row}
                    </AccordionTrigger>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        )}
      </Card>
    </Box>
  );
};

export default Walkthrough;
