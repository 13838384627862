import { i18n } from 'i18n';

const findOption = (value, options) => options.find((v) => v.value === value);

const optionsCountRange = [
  { value: '110', label: '1-10' },
  { value: '1150', label: '11-50' },
  { value: '51100', label: '51-100' },
  { value: '101200', label: '101-200' },
  { value: '201500', label: '201-500' },
  { value: '5001000', label: '501-1,000' },
  { value: '10015000', label: '1,001-5,000' },
  { value: '500110000', label: '5,001-10,000' },
  { value: '1000110000', label: '10,001-20,000' },
  { value: '20000', label: '20,000+' },
];

const optionsIndustry = [
  {
    value: 'advertisingmarketingservices',
    label: 'Advertising & Marketing Services',
  },
  { value: 'agriculture', label: 'Agriculture' },
  { value: 'biotechpharmaceuticals', label: 'Biotech & Pharmaceuticals' },
  { value: 'computeritservices', label: 'Computer & IT Services' },
  { value: 'constructioncontracting', label: 'Construction & Contracting' },
  { value: 'consultingservices', label: 'Consulting Services' },
  { value: 'distributionwholesale', label: 'Distribution & Wholesale' },
  { value: 'education', label: 'Education' },
  { value: 'energyutilities', label: 'Energy/Utilities' },
  {
    value: 'engineeringarchitecturedesign',
    label: 'Engineering, Architecture & Design',
  },
  { value: 'financeaccounting', label: 'Finance & Accounting' },
  { value: 'government', label: 'Government' },
  { value: 'healthcareservices', label: 'Healthcare Services' },
  { value: 'insurance', label: 'Insurance' },
  {
    value: 'logistictransportationservices',
    label: 'Logistic & Transportation Services',
  },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'mediapublishing', label: 'Media/Publishing' },
  { value: 'nonprofit', label: 'Nonprofit' },
  { value: 'other', label: 'Other' },
  { value: 'professionalservices', label: 'Professional Services' },
  { value: 'realestate', label: 'Real Estate' },
  { value: 'restauranthospitality', label: 'Restaurant & Hospitality' },
  { value: 'retail', label: 'Retail' },
  {
    value: 'staffingfirmrecruitingagency',
    label: 'Staffing Firm/Recruiting Agency',
  },
  { value: 'techsoftware', label: 'Tech/Software' },
  { value: 'telecommunications', label: 'Telecommunications' },
];

const optionsLocaleFactory = () => [
  { value: i18n.LOCALES.ENGLISH, label: `${i18n.t('English')}` },
  { value: i18n.LOCALES.GERMAN, label: `${i18n.t('German')} (German)` },
  { value: i18n.LOCALES.SPANISH, label: `${i18n.t('Spanish')} (Spanish)` },
  { value: i18n.LOCALES.JAPANESE, label: `${i18n.t('Japanese')} (Japanese)` },
];

export { findOption, optionsCountRange, optionsIndustry, optionsLocaleFactory };
