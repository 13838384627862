import { Box, Heading, Tabs } from '@kandji-inc/nectar-ui';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { links } from 'src/app/common/constants';
import featureFlags from 'src/config/feature-flags';
import useAccount from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import { i18n } from 'src/i18n';
import { getEDRPaths } from './route';
import ThreatList from './threat/ThreatList';
import { VulnerabilitiesTable } from './vulnerability/all-vulnerabilities/vulnerabilities-table';

const tabsContainerCss = {
  '& > div[data-nectar-styled-name="group"]': {
    zIndex: 1,
  },
};

type Tab = { path: string; label: string | JSX.Element };
type AvailableTabs = { threats: Tab; vulnerability?: Tab };

const getTab = ({
  availablePaths,
  url,
}: { availablePaths: AvailableTabs; url: string }) => {
  const paths = Object.values(availablePaths);
  const tab = paths.find((path) => url.includes(path.path));
  return tab?.path || availablePaths.threats.path;
};

const noop = (v: any) => v;

const ThreatTabs = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { bannerTopOffset } = useContext(InterfaceContext);
  const { currentCompany }: any = useAccount();

  const vulnFlag = featureFlags.getFlag('vm_06252024_vuln-poc');
  const vulnSKU =
    currentCompany?.feature_configuration?.vulnerability_management?.enabled;
  const EDR_PATHS = getEDRPaths();

  // istanbul ignore next
  const AVAILABLE_EDR_PATHS = vulnSKU
    ? EDR_PATHS
    : { threats: EDR_PATHS.threats };

  const tabs = {
    threats: ThreatList,
    ...(vulnFlag ? { vulnerability: VulnerabilitiesTable } : {}),
  };

  const currentTab = getTab({
    availablePaths: AVAILABLE_EDR_PATHS,
    url: match.url,
  });

  const threatPageStyle = {
    margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
    height: `calc(100vh - ${bannerTopOffset}px)`,
    position: 'relative',
    backgroundColor: '$neutral0',
    overflow: 'hidden',

    "& > div > div > [role='tablist']": {
      padding: '0 24px',
    },
  };

  const tabsContentStyle = {
    height: `calc(100vh - ${bannerTopOffset}px)`,
    overflow: 'auto',
  };

  return (
    <Box pt4 css={threatPageStyle}>
      <Heading size="1" css={{ padding: '$2 $5 $4' }}>
        {i18n.t('Threats & Vulnerabilities')}
      </Heading>

      <Tabs.Container
        css={tabsContainerCss}
        tabId={currentTab}
        defaultTabId={currentTab}
        tabs={Object.keys(tabs).map((tab) => {
          const threatPath =
            AVAILABLE_EDR_PATHS[tab as keyof typeof AVAILABLE_EDR_PATHS];
          return {
            label: threatPath.label,
            tabId: threatPath.path,
            onClick: /* istanbul ignore next */ (e) => {
              history.push(`${links.threat}/${threatPath.path}`);
            },
          };
        })}
      >
        {Object.keys(tabs).map((tab) => {
          const ThreatComponent = tabs[tab as keyof typeof tabs] || noop;
          return (
            <Tabs.Content
              tabId={
                AVAILABLE_EDR_PATHS[tab as keyof typeof AVAILABLE_EDR_PATHS]
                  .path
              }
              key={tab}
            >
              <Box css={tabsContentStyle}>
                <ThreatComponent />
              </Box>
            </Tabs.Content>
          );
        })}
      </Tabs.Container>
    </Box>
  );
};

export { ThreatTabs };
