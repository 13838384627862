/* istanbul ignore file */
import { Checkbox, Select } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
import PropTypes from 'prop-types';
import React from 'react';
import featureFlags from 'src/config/feature-flags';

import { i18n } from 'src/i18n';
import {
  getEnforcementOptions,
  getForceRestartOptions,
  getLoginAttemptOptions,
  getRemindRestartOptions,
  getRestartOptions,
} from '../../constants';

const Enforcement = ({ update, isDisabled, enforcements }) => {
  const LDFFfileVaultAde = featureFlags.getFlag('LIT-WWDC23-filevault-ade');

  const ENFORCEMENT_OPTIONS = getEnforcementOptions();
  const FORCE_RESTART_OPTIONS = getForceRestartOptions();
  const LOGIN_ATTEMPT_OPTIONS = getLoginAttemptOptions();
  const REMIND_RESTART_OPTIONS = getRemindRestartOptions();
  const RESTART_OPTIONS = getRestartOptions();

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Enforcement')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t('FileVault enforcement')}</p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Configure FileVault to be immediately enforced at the next login or allow the user to defer.',
              )}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              options={Object.values(ENFORCEMENT_OPTIONS)}
              onChange={(selected) => update('enforcementOption', selected)}
              value={enforcements.enforcementOption}
              disabled={isDisabled}
              inputId="enforcement_option"
            />
          </Setting.Controls>
        </Setting.Row>
        {enforcements.enforcementOption?.value ===
          ENFORCEMENT_OPTIONS.ENFORCE_IMMEDIATELY.value && (
          <>
            {LDFFfileVaultAde && (
              <Setting.Row>
                <Setting.Controls>
                  <Checkbox
                    label={i18n.t(
                      'Enforce during Setup Assistant for Automated Device Enrollment (macOS 14+)',
                    )}
                    id="ade_enforce"
                    testId="ade_enforce_checkbox"
                    checked={enforcements.ForceEnableInSetupAssistant}
                    onChange={() =>
                      update(
                        'ForceEnableInSetupAssistant',
                        (checked) => !checked,
                      )
                    }
                    disabled={isDisabled}
                  />
                </Setting.Controls>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'For devices running macOS 14+ that enroll using Automated Device Enrollment, attempt to enforce FileVault during Setup Assistant.',
                    )}
                  </p>
                </Setting.Helpers>
              </Setting.Row>
            )}
            <Setting.Row>
              <Setting.Controls>
                <Checkbox
                  label={i18n.t(
                    'Prompt for restart if FileVault is not enabled',
                  )}
                  id="promp_for_restart"
                  testId="promp_for_restart_checkbox"
                  checked={enforcements.DeferDontAskAtUserLogout}
                  onChange={() =>
                    update('DeferDontAskAtUserLogout', (checked) => !checked)
                  }
                  disabled={isDisabled}
                />
              </Setting.Controls>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {i18n.t(
                    'Configure options to forcibly restart the Mac or remind the end user to restart in order to enforce FileVault encryption.',
                  )}
                </p>
              </Setting.Helpers>
              {enforcements.DeferDontAskAtUserLogout && (
                <Setting.SecondaryControls>
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      <Select
                        compact
                        label={i18n.t('Prompt type')}
                        inputId="restart_options"
                        options={Object.values(RESTART_OPTIONS)}
                        onChange={(selected) =>
                          update('restartOption', selected)
                        }
                        value={enforcements.restartOption}
                        disabled={isDisabled}
                      />
                    </Setting.Controls>
                  </Setting.SecondaryControlsRow>
                  <Setting.SecondaryControlsRow>
                    <Setting.Controls>
                      {enforcements.restartOption?.value ===
                        RESTART_OPTIONS.FORCE.value && (
                        <Select
                          compact
                          label={i18n.t('Force after')}
                          inputId="force_options"
                          options={FORCE_RESTART_OPTIONS}
                          onChange={(selected) =>
                            update('ForceRestartAfter', selected.value)
                          }
                          value={FORCE_RESTART_OPTIONS.find(
                            (opt) =>
                              opt.value === enforcements.ForceRestartAfter,
                          )}
                          disabled={isDisabled}
                        />
                      )}
                      {enforcements.restartOption?.value ===
                        RESTART_OPTIONS.REMIND.value && (
                        <Select
                          compact
                          label={i18n.t('Remind every')}
                          inputId="remind_options"
                          options={REMIND_RESTART_OPTIONS}
                          onChange={(selected) =>
                            update('RemindRestartEvery', selected.value)
                          }
                          value={REMIND_RESTART_OPTIONS.find(
                            (opt) =>
                              opt.value === enforcements.RemindRestartEvery,
                          )}
                          disabled={isDisabled}
                        />
                      )}
                    </Setting.Controls>
                  </Setting.SecondaryControlsRow>
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          </>
        )}
        {enforcements.enforcementOption?.value ===
          ENFORCEMENT_OPTIONS.ALLOW_DEFERRAL.value && (
          <Setting.Row>
            <Setting.Title className="b-txt">
              {i18n.t('User deferral')}
            </Setting.Title>
            <Setting.Controls>
              <Select
                compact
                options={Object.values(LOGIN_ATTEMPT_OPTIONS)}
                onChange={(selected) =>
                  update(
                    'DeferForceAtUserLoginMaxBypassAttempts',
                    selected.value,
                  )
                }
                value={LOGIN_ATTEMPT_OPTIONS.find(
                  (opt) =>
                    opt.value ===
                    enforcements.DeferForceAtUserLoginMaxBypassAttempts,
                )}
                disabled={isDisabled}
              />
            </Setting.Controls>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Enabling FileVault for the first time will require a computer reboot. You can specify the number of login attempts allowed before FileVault is enforced and the reboot occurs.',
                )}
              </p>
            </Setting.Helpers>
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

Enforcement.propTypes = {
  update: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  enforcements: PropTypes.shape({
    enforcementOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    DeferForceAtUserLoginMaxBypassAttempts: PropTypes.number,
    DeferDontAskAtUserLogout: PropTypes.bool,
    restartOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    ForceRestartAfter: PropTypes.number,
    RemindRestartEvery: PropTypes.number,
  }).isRequired,
};

export default Enforcement;
