import { Breadcrumb, Button } from '@kandji-inc/bumblebee';
import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { InterfaceContext } from 'contexts/interface';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useScrollDownKey } from 'src/hooks/useScroll';
import Loader from '../../../../theme/components/atoms/Loader';
import { libraryItemService } from '../../data-service/library-item/library-item-service';
import { getAddLibraryItemsList } from '../api/transformer';
import { categories, getFilteredItems } from '../common';
import LibraryFilter from '../library-filter/library-filter';
import EmptyBeehive from '../library-list/assets/empty-beehive.svg';
/* istanbul ignore file */
import './add-library-item.css';
import { i18n } from 'i18n';
import LibraryItemCategory from './category/library-item-category';

const defaultFilters = {
  searchTerm: '',
  devices: [],
  category: '',
};

const AddLibraryItem = () => {
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);
  const { search } = useLocation();
  const queryParamsRef = useRef(new URLSearchParams(search)?.get('focus'));
  const isThreatEnabled =
    account?.currentCompany?.feature_configuration?.edr?.enabled;
  const { setIsOpenModalFeatureRequest } = useContext(InterfaceContext);
  const [managedItems, setManagedItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [itemFilters, setItemFilters] = useState({ ...defaultFilters });

  const categoriesOrderBy = useMemo(
    () => [
      categories.GENERAL,
      categories.ENROLLMENT,
      categories.AUTO_APPS,
      categories.OPERATING_SYSTEMS,
      categories.IOS_RELEASES,
      categories.IPADOS_RELEASES,
      categories.TVOS_RELEASES,
      categories.MDM_MACOS_RELEASES,
      categories.PROFILES,
      ...(isThreatEnabled ? [categories.SECURITY] : []),
    ],
    [isThreatEnabled],
  );

  const categoriesSelectOrderBy = useMemo(
    () => [
      categories.GENERAL,
      categories.ENROLLMENT,
      categories.AUTO_APPS,
      categories.OPERATING_SYSTEMS,
      categories.PROFILES,
      ...(isThreatEnabled ? [categories.SECURITY] : []),
    ],
    [isThreatEnabled],
  );

  const filtered = getFilteredItems(
    managedItems,
    itemFilters,
    categoriesOrderBy,
    (i) => i.isHidden({ environment, account }),
  );

  const getLibraryCardElements = useCallback(() => {
    const addLibraryContainer = document.getElementsByClassName(
      'li-add-library-item',
    )[0];
    return addLibraryContainer
      ? addLibraryContainer.getElementsByClassName('li-add-library-item__card')
      : undefined;
  }, []);

  useScrollDownKey(getLibraryCardElements, {
    deps: [filtered],
    vertOffset: -150,
    debounceDelay: 250,
  });

  useEffect(() => {
    const focusParam = queryParamsRef.current;
    if (focusParam && managedItems) {
      const toFocus = managedItems
        .map(({ data }) => data)
        .flat()
        .find(
          ({ name, libraryConfig }) =>
            libraryConfig?.name?.toLowerCase() === focusParam.toLowerCase() ||
            name?.toLowerCase() === focusParam.toLowerCase(),
        );
      if (toFocus) {
        setSelectedItem(toFocus);
      }
      queryParamsRef.current = null;
    }
  }, [queryParamsRef, managedItems]);

  useEffect(() => {
    libraryItemService
      .find({ type: 'managed-library' })
      .then((r) => setManagedItems(getAddLibraryItemsList(r.data.results)))
      .catch(console.error);
  }, []);

  const FilteredCategories = useMemo(() => {
    if (!filtered?.length) {
      return (
        <div className="b-flex-vc b-flex-hc b-flex-col">
          <ReactSVG className="b-mb" src={EmptyBeehive} />
          <h3 className="b-h3">{i18n.t('No results found')}</h3>
          <p className="b-txt">
            {i18n.t(
              'Try changing the filter or search with different keywords.',
            )}
          </p>
        </div>
      );
    }

    return filtered.map((categoryData) => (
      <LibraryItemCategory
        key={categoryData.category}
        category={categoryData.category}
        data={categoryData.data}
        onItemSelect={setSelectedItem}
        selectedItem={selectedItem}
        isCategoryOpen={
          selectedItem?.category.toLowerCase() ===
          categoryData.category.toLowerCase()
        }
      />
    ));
  }, [filtered, selectedItem]);

  // if (!isAuditor) {
  //   return <Redirect to="/library" />;
  // }

  if (!managedItems) {
    return <Loader type={Loader.types.LINE} />;
  }

  return (
    <>
      <div className="li-add-library-page">
        <div className="b-flex-btw li-add-library-page__top">
          <div className="li-add-library-page__top-breadcrumb">
            <Breadcrumb
              crumbs={[
                { title: i18n.t('Library'), url: '/library' },
                { title: i18n.t('Add Library Item') },
              ]}
            />
          </div>
          <Button
            icon="arrow-left"
            kind="outline"
            href="/library"
            linkComponent={Link}
          >
            {i18n.t('Back To Library')}
          </Button>
        </div>
        <div className="b-flex-vc b-mb2">
          <h1 className="b-h1">{i18n.t('Add Library Item')}</h1>
        </div>
        <p className="b-txt-light b-mb2 li-add-library-page__description">
          {i18n.t(
            'Choose an item you would like to add to your Library. Once an item has been added and configured, it can be assigned to Blueprints. Don’t see what you’re looking for? Please let us know by submitting ',
          )}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setIsOpenModalFeatureRequest(true);
            }}
            className="decorate-off b-txt li-feature-request"
          >
            {i18n.t('a feature request.')}
          </a>
        </p>
        <LibraryFilter
          onFilter={(category, devices, searchTerm) => {
            setItemFilters({
              category,
              devices,
              searchTerm,
            });
          }}
          categoryOrder={categoriesSelectOrderBy}
          sticky
        />
        <div className="li-add-library-item">{FilteredCategories}</div>
      </div>
    </>
  );
};

export default AddLibraryItem;
