export const DETECTION_DATE_SELECT_OPERATOR_OPTIONS = [
  {
    label: 'is on',
    value: 'eq',
  },
  {
    label: 'on or after',
    value: 'gte',
  },
  {
    label: 'on or before',
    value: 'lte',
  },
  {
    label: 'is between',
    value: 'ib',
  },
] as const;
