// istanbul ignore file
import {
  Badge,
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { SortableList } from '@kandji-inc/nectar-ui';
import { Resizable } from 're-resizable';
import * as React from 'react';

const NavIcon = styled(Icon, {
  minHeight: 20,
  maxHeight: 20,
  minWidth: 20,
  maxWidth: 20,
});

const DragHandle = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  '& > svg': {
    height: '20px',
    width: '20px',
    color: '$icon_secondary',
  },
  display: 'none',
});

const NavItem = styled(Flex, {
  alignItems: 'center',
  cursor: 'pointer',
  padding: '$2',
  gap: '$1',
  height: 40,
  borderRadius: '$1',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: '$neutral05',
    [`& ${DragHandle}`]: {
      display: 'flex',
    },
  },

  [`& ${Text}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '&[data-selected=true]': {
    backgroundColor: '$blue05',
    color: '$blue50',
    [`& ${Text}`]: {
      fontWeight: '$medium',
    },
    [`& ${NavIcon}`]: {
      color: '$blue50',
    },
  },
  variants: {
    isDragging: {
      true: {
        cursor: 'grabbing',
        maxWidth: '400px',
        marginLeft: '4px',
        padding: '8px 4px',
        boxShadow: '0px 1px 4px 0px rgba(15, 19, 23, 0.24)',
      },
    },
    dragDirection: {
      up: {
        borderTop: '1px solid $blue50',
      },
      down: {
        borderBottom: '1px solid $blue50',
      },
    },
  },
  overflow: 'hidden',
});

const ExpandHandle = styled(Box, {
  position: 'absolute',
  top: '50%',
  right: 0,
  marginRight: '-14px',
  cursor: 'pointer',
  zIndex: 3,
  '&:hover': {
    backgroundColor: '$neutral20',
  },
  padding: '$1',
  borderRadius: '$1',
  boxShadow: '$elevation1',
  backgroundColor: '$neutral00',
  display: 'none',
});

const StyledResizable = styled(Resizable, {
  '&:hover': {
    [`& ${ExpandHandle}`]: {
      display: 'block',
    },
  },
});

export const PrismNavList = styled(Flex, {
  flexDirection: 'column',
  overflowX: 'hidden',
  gap: '$1',
  borderRight: '1px solid $neutral30',
  pr: '$5',
  height: 'calc(100vh - 128px)',
  maxHeight: '100%',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '$1',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      background: 'rgba(243, 247, 250)',
      borderRadius: '$1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(80, 94, 113, 0.24)',
      borderRadius: '$1',
      height: '50px',
    },
  },
});

const INITIAL_WIDTH = 200;
const MIN_WIDTH = 60;
const MAX_WIDTH = 400;

export const ViewsNav = ({
  views,
  viewId,
  collapsed,
  onSelectView,
  onViewOrderChange,
  counts,
  countsPending,
  countsError,
  activeViewCount,
  activeViewCountPending,
}: {
  views: { id: string; name: string }[];
  viewId: string | null;
  collapsed: boolean;
  onSelectView: (view: { id: string; name: string }) => void;
  onViewOrderChange: (newOrder: string[]) => void;
  counts: Record<string, number>;
  countsPending: Record<string, boolean>;
  countsError: Record<string, boolean>;
  activeViewCount: number | undefined;
  activeViewCountPending: boolean;
}) => {
  const expanderRef = React.useRef<HTMLDivElement>(null);
  const navListRef = React.useRef<HTMLDivElement>(null);
  const resizedWidth = React.useRef(collapsed ? MIN_WIDTH : INITIAL_WIDTH);

  const [width, setWidth] = React.useState(
    collapsed ? MIN_WIDTH : INITIAL_WIDTH,
  );
  const [userCollapsed, setUserCollapsed] = React.useState(collapsed);

  React.useEffect(() => {
    setUserCollapsed(collapsed);
  }, [collapsed]);

  React.useEffect(() => {
    setWidth(userCollapsed ? 60 : resizedWidth.current);
  }, [userCollapsed]);

  const sortableItems = views.map((view) => {
    const viewName = view.id ? view.name : 'All devices';
    const isActiveView = viewId === view.id;
    const count = isActiveView ? activeViewCount : counts?.[view.id] || 0;
    const isPending = isActiveView
      ? activeViewCountPending
      : countsPending?.[view.id] || false;
    const isError = !isActiveView && (countsError?.[view.id] || false);

    return {
      id: view.id,
      content: view,
      renderItem: ({
        content: view,
        isDragging,
        dragDirection,
        listeners,
        attributes,
      }) => (
        <NavItemContainer
          key={view.id}
          showTooltip={userCollapsed}
          tooltipContent={`${viewName} (${count})`}
        >
          <NavItem
            onClick={() => onSelectView(view)}
            data-selected={viewId === view.id}
            isDragging={isDragging}
            dragDirection={dragDirection}
          >
            <NavIcon name="devices" />
            {!userCollapsed && (
              <>
                <Text css={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
                  {viewName}
                </Text>
                <Badge
                  compact
                  css={{
                    flexShrink: 0,
                    marginLeft: '$1',
                    opacity: isPending || isError ? 0.5 : 1,
                    transition: 'opacity 0.3s ease-in-out',
                    alignSelf: 'center',
                    minWidth: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isPending ? (
                    <Text>...</Text>
                  ) : isError ? (
                    <Text>Error</Text>
                  ) : (
                    <Text>{count?.toLocaleString() ?? ''}</Text>
                  )}
                </Badge>
              </>
            )}
            {!userCollapsed && (
              <DragHandle
                {...listeners}
                {...attributes}
                css={{ cursor: isDragging ? 'grabbing' : 'grab' }}
              >
                <Icon name="grip-dots-vertical" />
              </DragHandle>
            )}
          </NavItem>
        </NavItemContainer>
      ),
      disabled: !view.id,
    };
  });

  return (
    <StyledResizable
      maxWidth={MAX_WIDTH}
      minWidth={MIN_WIDTH}
      size={{ width }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResize={(_e, _dir, ref, _d) => {
        const userCollapsed =
          ref.getBoundingClientRect().width <= MIN_WIDTH + 5;
        setWidth(ref.getBoundingClientRect().width);
        setUserCollapsed(userCollapsed);
        resizedWidth.current = userCollapsed
          ? INITIAL_WIDTH
          : ref.getBoundingClientRect().width;
      }}
      onResizeStart={() => {
        if (navListRef.current) {
          navListRef.current.style['border-right'] =
            '1px solid var(--colors-blue50)';
        }
      }}
      onResizeStop={() => {
        if (navListRef.current) {
          navListRef.current.style['border-right'] =
            '1px solid var(--colors-neutral30)';
        }
      }}
    >
      <PrismNavList ref={navListRef}>
        <SortableList
          items={sortableItems}
          onChange={(newOrder) => {
            const ids = newOrder.map((item) => item.id);
            onViewOrderChange(ids);
          }}
        />
      </PrismNavList>
      <ExpandHandle
        ref={expanderRef}
        onClick={() => {
          setUserCollapsed(!userCollapsed);
        }}
      >
        <NavIcon
          name={userCollapsed ? 'fa-angle-right-small' : 'fa-angle-left-small'}
        />
      </ExpandHandle>
    </StyledResizable>
  );
};

const NavItemContainer = ({
  children,
  showTooltip,
  tooltipContent,
}: {
  children: React.ReactNode;
  showTooltip: boolean;
  tooltipContent: string;
}) => {
  return showTooltip ? (
    <Tooltip content={tooltipContent} side="right" css={{ zIndex: 2 }}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};
