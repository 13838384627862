import type { IconNames } from '@kandji-inc/nectar-ui';
import { Box, Flex, Icon, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { formatDate } from '../../helpers';

type TimelineItem = {
  label: string;
  date: string;
  icon: IconNames;
  theme?: 'default' | 'danger';
};

type TimelineProps = {
  items: TimelineItem[];
};

const Label = styled(Text, {
  color: '$neutral70',
  fontSize: '$1',
});

const Time = styled(Text, {
  fontWeight: '$medium',
  fontSize: '$1',
});

const Timeline = (props: TimelineProps) => {
  const { items } = props;

  const themes = {
    default: {
      color: 'var(--colors-neutral60)',
      backgroundColor: 'var(--colors-neutral10)',
    },
    danger: {
      color: 'var(--colors-red60)',
      backgroundColor: 'var(--colors-red10)',
    },
  };

  const getAlignment = (index: number) => {
    if (index === 0) return 'start';
    if (index === items.length - 1) return 'end';
    return 'center';
  };

  return (
    <Flex flow="column" wFull>
      <Flex justifyContent="space-between" wFull css={{ zIndex: 1 }}>
        {items.map((item, idx) => {
          const { label, date, icon, theme = 'default' } = item;

          const { color, backgroundColor } = themes[theme];

          return (
            <Flex
              flow="column"
              gap="xs"
              alignItems={getAlignment(idx)}
              key={`${idx}-${label}`}
            >
              <Label>{label}</Label>

              <Flex
                alignItems="center"
                justifyContent="center"
                css={{
                  backgroundColor,
                  borderRadius: '$round',
                  height: '20px',
                  width: '20px',
                }}
              >
                <Icon name={icon} color={color} size="xs" />
              </Flex>

              <Tooltip
                side="bottom"
                theme="dark"
                content={formatDate({ date, showUserFriendlyTimestamp: true })}
                css={{ zIndex: 3 }}
              >
                <Time>{formatDate({ date })}</Time>
              </Tooltip>
            </Flex>
          );
        })}
      </Flex>

      <Box
        css={{
          width: '100%',
          height: '1px',
          backgroundColor: '$neutral60',
          position: 'relative',
          bottom: '30px',
        }}
      />
    </Flex>
  );
};

export type { TimelineItem };
export { Timeline };
