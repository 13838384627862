import { getItemConfig } from 'features/library-items/library/api/transformer';

/**
 * Transform Library Items fetched from the API
 * @param {Array} data - a list of Library Items
 * @returns a transformed list of Library Items
 */
const transformLibraryItemsFromApi = (data: object[]) => {
  const result = data?.map((item: any) => ({
    ...item,
    id: item?.id,
    type: item?.type,
    identifier: item?.identifier || null,
    managedLibraryItemId: item?.managed_library_item_id || null,
    name: item?.name,
    instanceName: item?.instance_name || null,
    active: item?.active,
    devices: item?.devices || [],
    icon:
      item?.icon ||
      item?.app_icon ||
      item?.data?.icon ||
      item?.data?.icon_url ||
      null,
    defaultConfiguration: getItemConfig(item),
    createdAt: item?.created_at,
    isSelfService: item?.is_self_service,
    installEnforcement: item?.install_enforcement,
    runsOnMac: item?.runs_on_mac,
    runsOnIphone: item?.runs_on_iphone,
    runsOnIpad: item.runs_on_ipad,
    runsOnTv: item?.runs_on_tv,
    pendingCount: Number.isNaN(item?.pending_count) ? 0 : item?.pending_count,
    successCount: Number.isNaN(item?.success_count) ? 0 : item?.success_count,
    failingCount: Number.isNaN(item?.failing_count) ? 0 : item?.failing_count,
    isUpdateOnly: item?.is_update_only,
  }));

  return result;
};

export { transformLibraryItemsFromApi };
