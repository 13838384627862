import deepcopy from 'deepcopy';
import type { Location } from 'history';
import differenceWith from 'lodash/differenceWith';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import type { Node } from 'reactflow';
import ReactFlow, {
  Background,
  PanOnScrollMode,
  ReactFlowProvider,
  useNodes,
  useNodesInitialized,
  useReactFlow,
} from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Box, Flex, Loader, useDialog } from '@kandji-inc/nectar-ui';

import { paths } from 'src/features/blueprints/common';
import { useIntegrations } from 'src/features/integrations/hooks';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import onWindowUnload from 'src/hooks/onWindowUnload';

import type {
  ConflictModal as ConflictModalProps,
  DraggableLibraryItem,
  LibraryItem,
  NavigationModal,
} from '../../blueprint-flow.types';
import { Notifications } from '../../components';
import FlowControls from '../../components/flow-controls';
import HeaderActions from '../../components/header-actions';
import LibraryItemDrawer from '../../components/library-item-drawer';
import { centerX, centerY, proOptions } from '../../config';
import {
  GRAPH_CONTAINER_ID,
  MAX_ZOOM,
  MIN_ZOOM,
  NODE_TYPES,
  ORIGIN_TYPES,
} from '../../constants';
import { edgeTypes } from '../../edges';
import {
  canDropIntoAssignmentNode,
  differenceInNodes,
  getLibraryItem,
  sortLibraryItems,
} from '../../helpers';
import { useLayout, useMap, useValidation } from '../../hooks';
import { ConflictModal } from '../../modals';
import CancelAssignments from '../../modals/cancel-assignments';
import ConfirmNavigation from '../../modals/confirm-navigation';
import SaveAssignments from '../../modals/save-assignments';
import { nodeTypes } from '../../nodes';
import { Sidebar } from '../../regions';
import { MouseSensor, TouchSensor } from '../../sensors';
import useBlueprint from '../../services/use-blueprint';
import useGetFacets from '../../services/use-get-facets';
import useBlueprintFlow from '../../store';
import ActionBar from './action-bar';
import ExclusionsBadge from './exclusions-badge';
import LibraryOverlay from './library-drag-overlay';

const Assignments = () => {
  const history = useHistory();
  const { create, patch, isLoading } = useBlueprint();
  const [
    setIsReactFlowReady,
    setDraggingLibraryItems,
    clearSelectedAssignmentLibraryItems,
    generateNewFlowId,
    setIsEditingAssignments,
    setIsOptionPressed,
    setIsDeletingNode,
    isDeletingNode,
    setCountOfUserDirectoryIntegrations,
    setHasUserDirectoryIntegration,
    setSelectedAssignmentLibraryItems,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.setIsReactFlowReady,
      state.setDraggingLibraryItems,
      state.clearSelectedAssignmentLibraryItems,
      state.generateNewFlowId,
      state.setIsEditingAssignments,
      state.setIsOptionPressed,
      state.setIsDeletingNode,
      state.isDeletingNode,
      state.setCountOfUserDirectoryIntegrations,
      state.setHasUserDirectoryIntegration,
      state.setSelectedAssignmentLibraryItems,
    ]),
  );
  const [
    isAddingBlueprint,
    isLoadingMap,
    originalBlueprint,
    model,
    isReactFlowReady,
    libraryItems,
    isEditingAssignments,
    selectedAssignmentLibraryItems,
    draggingLibraryItems,
    assignmentsLibrarySort,
    selectedExclusionLibraryItemId,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.isAddingBlueprint,
      state.isLoadingMap,
      state.blueprint,
      state.model,
      state.isReactFlowReady,
      state.libraryItems,
      state.isEditingAssignments,
      state.selectedAssignmentLibraryItems,
      state.draggingLibraryItems,
      state.assignmentsLibrarySort,
      state.selectedExclusionLibraryItemId,
    ]),
  );

  const flowRef = useRef(null);
  const { runValidation } = useValidation();
  const { getNodes, setNodes, setEdges, setCenter, toObject } = useReactFlow();
  const { runLayout, isLoadingRunLayout } = useLayout();
  const nodes = useNodes();
  const prevNodes = useRef<Node[]>(nodes);
  const nodesInitialized = useNodesInitialized();
  const { updateLibraryItemConflicts } = useMap();
  const [conflictModal, setConflictModal] = useState<ConflictModalProps>({
    isOpen: false,
    items: null,
    allDevicesItems: null,
  });
  const [navigationModal, setNavigationModal] = useState<NavigationModal>({
    isOpen: false,
    location: null,
    isConfirmed: false,
  });
  const [isSaveModalOpen, toggleSaveModal] = useDialog(false);
  const [isCancelModalOpen, toggleCancelModal] = useDialog(false);
  const hasSingleLibraryItemSelected =
    Object.keys(selectedAssignmentLibraryItems.items).length === 1;
  useAdjustSidebarChatBubble(
    hasSingleLibraryItemSelected ? { bottom: 0, right: 420 } : { bottom: 0 },
  );

  const { countOfUserDirectoryIntegrations, hasUserDirectoryIntegration } =
    useIntegrations();
  setCountOfUserDirectoryIntegrations(countOfUserDirectoryIntegrations);
  setHasUserDirectoryIntegration(hasUserDirectoryIntegration);

  /* istanbul ignore next */
  const toggleConflictModal = (
    items: Array<LibraryItem>,
    allDevicesItems: Array<LibraryItem>,
    override?: boolean,
  ) =>
    setConflictModal((prev) => ({
      ...prev,
      items,
      allDevicesItems,
      isOpen: override !== undefined ? override : !prev.isOpen,
    }));

  // State cleanup for when saving/cancelling.
  const cleanUp = () => {
    clearSelectedAssignmentLibraryItems();
    setIsDeletingNode(false, []);
  };

  const onSave = async () => {
    const { nodes, edges } = toObject();
    if (isAddingBlueprint) {
      return create({ ...model, nodes, edges }).then(cleanUp);
    }
    return patch({
      ...model,
      nodes,
      edges,
    }).then((r) => {
      cleanUp();

      const { nodes, edges } = r;
      setNodes(nodes);
      setEdges(edges);

      updateLibraryItemConflicts();
    });
  };

  const hasAssignmentBeenChanged = () => {
    const {
      name: originalName,
      description: originalDescription,
      nodes: originalNodes,
    } = originalBlueprint;
    const { name, description } = model;
    const { nodes } = toObject();

    const originalData = originalNodes.map(({ data }) => data);
    const newData = nodes.map(({ data }) => data);

    return (
      Boolean(differenceInNodes(originalData, newData).length) ||
      originalName !== name ||
      originalDescription !== description
    );
  };

  const onCancel = (isConfirmed = false) => {
    const { nodes, edges } = originalBlueprint;
    cleanUp();
    setIsEditingAssignments(false, true);
    setIsDeletingNode(false, []);

    if (isAddingBlueprint) {
      history.push(paths.root, { isConfirmed });
    } else {
      setNodes([]);
      setEdges([]);
      setTimeout(() => {
        setNodes(nodes);
        setEdges(edges);
      });
    }
  };

  /* istanbul ignore next */
  const handleDragStart = (e: DragStartEvent) => {
    const { id: flowId, data } = e.active;
    const { id, origin } = data?.current;
    const draggedItem = {
      id: flowId,
      data: {
        id,
        flowId,
        origin,
      },
    };

    const selectedItems = Object.keys(selectedAssignmentLibraryItems.items)
      .filter(
        (selectedId) =>
          selectedAssignmentLibraryItems.items[selectedId].flowId !== flowId,
      )
      .map((selectedId) => {
        const libraryItem = getLibraryItem(selectedId, libraryItems);
        return {
          id: libraryItem.flowId,
          data: {
            id: libraryItem.id,
            flowId: libraryItem.flowId,
            origin: selectedAssignmentLibraryItems.origin,
          },
        };
      });

    setDraggingLibraryItems({
      origin,
      items: [...selectedItems, draggedItem],
    });
  };

  /* istanbul ignore next */
  const handleDragEnd = (e: DragEndEvent) => {
    const droppableId = e.over?.id;
    const { items } = draggingLibraryItems;

    const handleLibraryItemDrop = (
      draggedItem: DraggableLibraryItem,
    ): [boolean, boolean, boolean, Node<any>[]] => {
      // When an item is placed into the flow, update its origin point from bank to graph
      const draggableItem = {
        id: draggedItem.data.flowId,
        data: {
          ...draggedItem.data,
          origin: ORIGIN_TYPES.graph,
        },
      };

      // Create a copy of the current nodes. A deep copy is necessary as we
      // only want to apply removal changes if a valid move was performed.
      const nodes = deepcopy(getNodes());

      let validity = {
        isValid: false,
        isSCLIConflict: false,
        isAllDevicesConflict: false,
      };
      nodes.forEach((prevNode) => {
        const node = prevNode;

        // Remove the dragged LI from it if the node is where the LI was dragged from
        if (node.id !== droppableId) {
          node.data = {
            ...node.data,
            ...(node.data.items && {
              items: node.data.items?.filter(
                (item: DraggableLibraryItem) =>
                  item.id !== draggedItem.data.flowId,
              ),
            }),
          };
        }

        // Add the dragged LI to it if the node is where the LI was dropped into
        if (node.id === droppableId) {
          const { isValid, isSCLIConflict, isAllDevicesConflict } =
            canDropIntoAssignmentNode({
              droppedItem: draggableItem,
              droppedNode: node,
              getLibraryItem: (id) => getLibraryItem(id, libraryItems),
            });

          if (isValid) {
            node.data = {
              ...node.data,
              items: [...node.data.items, draggableItem],
            };
          }

          validity = { isValid, isSCLIConflict, isAllDevicesConflict };
        }

        return node;
      });

      const { isValid, isSCLIConflict, isAllDevicesConflict } = validity;
      return [isValid, isSCLIConflict, isAllDevicesConflict, nodes];
    };

    if (droppableId) {
      const allItems: Array<[boolean, boolean, LibraryItem, Array<Node>]> =
        items.map((item) => {
          const [isValidMove, isSCLIError, isAllDevicesError, nodes] =
            handleLibraryItemDrop(item);
          if (isValidMove) {
            setNodes(nodes);
            updateLibraryItemConflicts();

            // If an item was dragged into the graph from the bank, regenerate the bank item's
            // `flowId` so a duplicate of the item cannot be dragged into the graph
            if (item.data.origin === ORIGIN_TYPES.bank) {
              generateNewFlowId(item);
            }
          }

          return [
            isSCLIError,
            isAllDevicesError,
            getLibraryItem(item.data.id, libraryItems),
            nodes,
          ];
        });

      const scliErrors = allItems
        .filter(([isSCLIError]) => isSCLIError)
        .map(([, , item]) => item);
      const allDevicesErrors = allItems
        .filter(([_, isAllDevicesError]) => isAllDevicesError)
        .map(([, , item]) => item);

      if (scliErrors.length || allDevicesErrors.length) {
        toggleConflictModal(scliErrors, allDevicesErrors);
      }

      clearSelectedAssignmentLibraryItems();
    }

    setDraggingLibraryItems({
      origin: null,
      items: [],
    });
  };

  const onPromptMessage = (location: Location<{ isConfirmed: boolean }>) => {
    const { state, pathname } = location;
    if (state?.isConfirmed) {
      return true;
    }

    if (hasAssignmentBeenChanged()) {
      setNavigationModal({
        isOpen: true,
        location: pathname,
        isConfirmed: false,
      });
      return false;
    }

    return true;
  };

  const onInit = () => {
    const { nodes, edges } = originalBlueprint;
    setNodes(sortLibraryItemsInGraphNodes(nodes, libraryItems));
    setEdges(edges);
    setCenter(centerX, centerY, {
      zoom: 1,
    });
  };

  useEffect(() => {
    if (!isLoadingRunLayout) {
      runLayout(!isEditingAssignments);
    }
  }, [nodesInitialized, isLoadingRunLayout]);

  // Update Library Item conflicts on page load and when
  // Library Item data has been received from the API
  useEffect(() => {
    /* istanbul ignore next */
    if (libraryItems.length && nodesInitialized) {
      const libraryItemsById = libraryItems.reduce(
        (a, c) => ({ ...a, [c.id]: c }),
        {},
      );
      let removedItems = {};
      const updatedNodes = nodes.map((node: Node<any>) => {
        const nodeHasLi =
          node.type === NODE_TYPES.assignment || node.type === NODE_TYPES.start;
        if (nodeHasLi) {
          const items = node.data.items;
          const existingItems = items.filter(
            (item) => libraryItemsById[item.data.id],
          );

          removedItems = {
            ...removedItems,
            ...differenceWith(
              items,
              existingItems,
              (a, b) => a.data.id === b.data.id,
            ).reduce((a, c) => ({ ...a, [c.data.id]: c }), {}),
          };

          if (existingItems.length !== items.length) {
            const updatedItems = items.filter(
              (item) => libraryItemsById[item.data.id],
            );
            return { ...node, data: { ...node.data, items: updatedItems } };
          }
        }
        return node;
      });

      if (Object.keys(removedItems).length) {
        setSelectedAssignmentLibraryItems((prev) => {
          Object.keys(removedItems).forEach((id) => {
            delete prev.items[id];
          });

          return {
            ...prev,
            lastItemClicked: prev.lastItemClicked.filter(
              (id) => !removedItems[id],
            ),
            origin: Object.keys(prev.items).length ? prev.origin : null,
          };
        });
        setNodes(updatedNodes);
      }

      updateLibraryItemConflicts();
    }
  }, [libraryItems, nodesInitialized]);

  // Update Library Item conflicts if a node is deleted
  useEffect(() => {
    /* istanbul ignore next */
    if (prevNodes.current.length > nodes.length) {
      updateLibraryItemConflicts();
    }
  }, [nodes.length]);

  // Run clean up on unmount
  useEffect(() => () => cleanUp(), []);

  const sortLibraryItemsInGraphNodes = (
    nodes: Array<Node>,
    libraryItems: Array<LibraryItem>,
  ) => {
    if (!libraryItems || !libraryItems.length) {
      return nodes;
    }

    return nodes.map((prevNode) => {
      const originalItems = prevNode.data?.items?.reduce(
        (a, c) => ({ ...a, [c.data.id]: c }),
        {},
      );

      const itemsWithData = prevNode.data?.items?.map((item) =>
        getLibraryItem(item.data.id, libraryItems),
      );

      const sortedItems =
        itemsWithData &&
        sortLibraryItems(assignmentsLibrarySort, itemsWithData)
          // In the unusual case that a LI that no longer exists is still in the map, exclude it
          .filter((item) => item && item.id)
          .map((item) => originalItems[item.id]);

      return {
        ...prevNode,
        ...(prevNode?.data
          ? {
              data: {
                ...prevNode.data,
                ...(sortedItems ? { items: sortedItems } : {}),
              },
            }
          : {}),
      };
    });
  };

  useEffect(() => {
    setNodes((prev) => sortLibraryItemsInGraphNodes(prev, libraryItems));
  }, [libraryItems, assignmentsLibrarySort]);

  window.onkeydown = /* istanbul ignore next */ (e) => {
    if (e.key === 'Alt') {
      setIsOptionPressed(true);
    }
  };

  window.onkeyup = /* istanbul ignore next */ (e) => {
    if (e.key === 'Alt') {
      setIsOptionPressed(false);
    }
  };

  onWindowUnload(
    (e) => {
      if (isEditingAssignments && hasAssignmentBeenChanged()) {
        e.preventDefault();
        e.returnValue = true;
      }

      return null;
    },
    [isEditingAssignments, model],
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
  );

  const selectedLibraryItem = useMemo(
    () => Object.values(selectedAssignmentLibraryItems.items)[0],
    [selectedAssignmentLibraryItems],
  );

  return (
    <Box
      hFull
      css={{
        position: 'relative',
        '.react-flow__node': {
          zIndex: '-1 !important',
        },
        '.react-flow__renderer': {
          overflow: 'hidden',
        },
      }}
      id={GRAPH_CONTAINER_ID}
    >
      {isEditingAssignments && (
        <HeaderActions
          onCancel={() => {
            if (hasAssignmentBeenChanged()) {
              toggleCancelModal(true);
            } else {
              onCancel();
            }
          }}
          onSave={
            /* istanbul ignore next */
            () => {
              const isValid = runValidation(true);
              if (!isValid) {
                return;
              }

              if (!isAddingBlueprint && hasAssignmentBeenChanged()) {
                toggleSaveModal(true);
              } else {
                onSave();
              }
            }
          }
          isLoading={isLoading}
        />
      )}
      <ActionBar />

      {isLoadingMap && (
        <Flex
          hFull
          alignItems="center"
          justifyContent="center"
          data-testid="loading-map"
        >
          <Loader size="md" />
        </Flex>
      )}

      <Flex
        wFull
        css={{
          position: 'relative',
          height: '100%',
          opacity: /* istanbul ignore next */ isLoadingMap ? 0 : 1,
        }}
      >
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
        >
          {isEditingAssignments && <Sidebar />}

          <Flex flex="1" css={{ position: 'relative' }}>
            <ReactFlow
              ref={(r) => {
                flowRef.current = r;
                if (!isReactFlowReady) {
                  setIsReactFlowReady(true);
                }
              }}
              nodeOrigin={[0.5, 0.5]}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              defaultNodes={[]}
              defaultEdges={[]}
              proOptions={proOptions}
              nodesConnectable={false}
              nodesDraggable={false}
              zoomOnDoubleClick={false}
              deleteKeyCode={null}
              onInit={onInit}
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              onPaneClick={
                /* istanbul ignore next */ () =>
                  clearSelectedAssignmentLibraryItems()
              }
              panOnScroll
              panOnScrollMode={PanOnScrollMode.Free}
              onNodesChange={
                /* istanbul ignore next */ () =>
                  runLayout(!isEditingAssignments)
              }
            >
              <Background gap={12} size={3} color="var(--colors-neutral5)" />
              {
                /* istanbul ignore next */ isDeletingNode && (
                  <div
                    style={{
                      position: 'relative',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0,0,0,0.45)',
                    }}
                  />
                )
              }

              <Notifications />
            </ReactFlow>

            <FlowControls />
            <ExclusionsBadge />
          </Flex>

          {(hasSingleLibraryItemSelected || selectedExclusionLibraryItemId) && (
            <LibraryItemDrawer
              item={{
                ...getLibraryItem(
                  selectedExclusionLibraryItemId || selectedLibraryItem?.id,
                  libraryItems,
                ),
                flowId: selectedLibraryItem?.flowId || '',
              }}
            />
          )}

          <LibraryOverlay />
        </DndContext>

        <ConflictModal
          isOpen={conflictModal.isOpen}
          items={conflictModal.items}
          allDevicesItems={conflictModal.allDevicesItems}
          toggle={toggleConflictModal}
        />
        <SaveAssignments
          isOpen={isSaveModalOpen}
          toggle={toggleSaveModal}
          onConfirm={onSave}
        />
        <CancelAssignments
          isOpen={isCancelModalOpen}
          toggle={toggleCancelModal}
          onConfirm={() => onCancel(true)}
          isAddingBlueprint={isAddingBlueprint}
        />
        <ConfirmNavigation
          isOpen={navigationModal.isOpen}
          toggle={(isOpen) =>
            setNavigationModal((prev) => ({ ...prev, isOpen }))
          }
          onConfirm={() =>
            history.push(navigationModal.location, { isConfirmed: true })
          }
        />
        <Prompt when={isEditingAssignments} message={onPromptMessage} />
      </Flex>
    </Box>
  );
};

const AssignmentsPage = () => {
  const [isAddingBlueprint, setIsEditingAssignments, setIsLoadingMap] =
    useBlueprintFlow(
      useShallow((state) => [
        state.isAddingBlueprint,
        state.setIsEditingAssignments,
        state.setIsLoadingMap,
      ]),
    );

  const { isLoading: isLoadingFacets } = useGetFacets();

  useEffect(() => {
    setIsLoadingMap(true);
  }, []);

  useEffect(() => {
    if (isAddingBlueprint) {
      setIsEditingAssignments(true);
    }
    return () => setIsEditingAssignments(false);
  }, [isAddingBlueprint]);

  if (isLoadingFacets) {
    return (
      <Flex
        hFull
        alignItems="center"
        justifyContent="center"
        data-testid="loading-facets"
      >
        <Loader size="md" />
      </Flex>
    );
  }

  return (
    <ReactFlowProvider>
      <Assignments />
    </ReactFlowProvider>
  );
};

export default AssignmentsPage;
