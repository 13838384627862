/* istanbul ignore file */
import { useContext } from 'react';
import { DATE_FORMAT } from 'src/app/components/common/helpers';
import { AccountContext } from 'src/contexts/account';

const formatOrder = (date: Date, format: string): string => {
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getUTCFullYear();

  // Format the date based on the preferred format
  return format
    .replace('DD', day)
    .replace('MM', month)
    .replace('YYYY', String(year));
};

export const formatDate = (
  props: {
    date: string;
    showTimestamp?: boolean; // ex MM/DD/YYYY 12:00:00 AM
    showUserFriendlyTimestamp?: boolean; // ex MM/DD/YYYY at 12:00 AM (PST)
  } = { date: '', showTimestamp: false, showUserFriendlyTimestamp: false },
): string => {
  const { date: dateString, showTimestamp, showUserFriendlyTimestamp } = props;

  const { userSettings } = useContext(AccountContext);

  // Provide default values for timezone and preferredDateFormat
  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultDateFormat = DATE_FORMAT.default;

  const timezone = userSettings.timezone || defaultTimeZone;
  const preferredDateFormat =
    userSettings.preferred_date_format || defaultDateFormat;

  const effectiveTimeZone =
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Ensure the date is interpreted as UTC by appending 'Z'
  const date = new Date(`${dateString}Z`);

  // Base options for formatting the date (DD/MM/YYYY or MM/DD/YYYY based on locale)
  const formattedDate = formatOrder(date, preferredDateFormat);

  // 1. Return just the date if both showTimestamp and isOnTimeline are false
  // Ex. MM/DD/YYYY
  if (!showTimestamp && !showUserFriendlyTimestamp) {
    return formattedDate;
  }

  // 2. Return date with full time if showTimestamp is true and isOnTimeline is false
  // Ex. MM/DD/YYYY 12:00:00 AM
  if (showTimestamp && !showUserFriendlyTimestamp) {
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // for AM/PM format
      timeZone: effectiveTimeZone,
    };
    const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
    return `${formattedDate} ${formattedTime}`;
  }

  // 3. Return date with time and timezone if isOnTimeline is true
  // Ex. MM/DD/YYYY at 12:00 AM (PST)
  if (showUserFriendlyTimestamp) {
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // for AM/PM format
      timeZone: effectiveTimeZone,
    };
    const formattedTime = date.toLocaleTimeString(undefined, timeOptions);

    // Get the user's timezone abbreviation (e.g., CET, PST)
    const timezoneOptions: Intl.DateTimeFormatOptions = {
      timeZone: effectiveTimeZone,
      timeZoneName: 'short', // gets the timezone abbreviation
    };
    const timezone =
      date
        .toLocaleDateString(undefined, timezoneOptions)
        .split(',')
        .pop()
        ?.trim() || '';

    return `${formattedDate} at ${formattedTime} (${timezone})`;
  }

  return formattedDate; // Default return
};

const getTextWidth = (text: string, element: HTMLElement = document.body) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Get the computed style of the element and set it on the canvas context
  const style = window.getComputedStyle(element);
  context.font = `${style.fontSize} ${style.fontFamily}`;

  return context.measureText(text).width;
};

export const displayAndTruncateList = (
  strings: string[],
  maxWidth: number = 530,
  separator = ', ',
  element = document.body,
) => {
  const displayed = [];
  let hidden = [];
  let totalWidth = 0;

  for (let i = 0; i < strings.length; i++) {
    const string = strings[i];
    const stringWidth = getTextWidth(string, element);
    const separatorWidth = i > 0 ? getTextWidth(separator, element) : 0;

    if (totalWidth + stringWidth + separatorWidth > maxWidth) {
      hidden = strings.slice(i); // All remaining strings are hidden
      break;
    }

    displayed.push(string);
    totalWidth += stringWidth + separatorWidth;
  }

  return { displayed, hidden };
};
