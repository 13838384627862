import { createContext } from 'react';
import { initialAuthState } from './auth-state';

const stub = () => {
  throw new Error('Please wrap app in <Auth0Provider>.');
};

const initialContext = {
  ...initialAuthState,
  getAccessTokenSilently: stub,
  loginWithRedirect: stub,
  logout: stub,
  getIdTokenClaims: stub,
};

/**
 * Auth0 Context
 */
const Auth0Context = createContext(initialContext);

export default Auth0Context;
