/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { Vulnerability } from '../vulnerability.types';

type Blueprint = {
  blueprint_id: string;
  blueprint_name: string;
};

const useGetVulnerableDeviceBlueprints = (
  cveId: Vulnerability['cve_id'],
  appName: Vulnerability['app_name'],
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerable-device-blueprints', cveId, ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(
        `vulnerabilities/${cveId}/applications/${appName}/blueprints`,
      ).get(params),
  });

  const data = apiRes?.data;
  const blueprints: Blueprint[] = data?.blueprints || [];

  // Remove duplicates based on the blueprint_id
  const uniqueBlueprints = blueprints.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.blueprint_id === item.blueprint_id),
  );

  return { blueprints: uniqueBlueprints, isLoading };
};

export { useGetVulnerableDeviceBlueprints };
