import { TextField } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';

const MAX_NAME_LENGTH = 32;

type ViewNameInputProps = {
  viewName: string;
  viewNameError: string | undefined;
  placeholder?: string;
  setViewName: (viewName: string) => void;
  setViewNameError: (viewNameError: string) => void;
};

export const ViewNameInput = ({
  viewName,
  viewNameError,
  placeholder = 'Enter a unique name',
  setViewName,
  setViewNameError,
}: ViewNameInputProps) => {
  const viewNameLabel = React.useMemo(() => {
    if (viewNameError) {
      return viewNameError;
    }
    return viewName.length >= MAX_NAME_LENGTH ? 'Max character limit' : '';
  }, [viewNameError, viewName.length]);

  return (
    <TextField
      label={i18n.t('Name')}
      onChange={(e) => {
        setViewName(String(e.target.value).slice(0, MAX_NAME_LENGTH));
        setViewNameError('');
      }}
      placeholder={placeholder}
      state={viewNameError ? 'error' : 'default'}
      value={viewName}
      showClearButton
      data-testid="rename-view-input"
      onClear={() => {
        setViewName('');
        setViewNameError('');
      }}
      hint={{
        label: viewNameLabel,
        charCounter: {
          count: viewName.length,
          max: MAX_NAME_LENGTH,
        },
        variant: viewNameError ? 'error' : 'default',
      }}
    />
  );
};
