import { Flex, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import deviceImagesMap from 'components/common/image-device/map';
/* istanbul ignore file */
import type { ReactNode } from 'react';
import { getDeviceIconByFamily } from 'src/features/visibility/prism/utils/column-helpers/column-utils';

const escapeSpecialCharacters = (text: string) =>
  text?.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

const StyledBackgroundHighlighter = styled('span', {
  backgroundColor: '$yellow30',
});

const BackgroundHighlighter = (children: ReactNode) => (
  <StyledBackgroundHighlighter>{children}</StyledBackgroundHighlighter>
);

export const highlightedText = ({
  text,
  term,
  hightLighter = BackgroundHighlighter,
}: {
  text: string;
  term: string;
  hightLighter?: (children: ReactNode) => JSX.Element;
}) => {
  if (!text || !term) {
    return <span>{text}</span>;
  }

  const parts = text.split(
    new RegExp(`(${escapeSpecialCharacters(term)})`, 'gi'),
  );

  return (
    <span>
      {parts.map((part, i) => {
        const isMatchingSearchText = part.toLowerCase() === term?.toLowerCase();

        return (
          <span key={`highlighted-${part}-${i}`}>
            {isMatchingSearchText ? hightLighter(part) : part}
          </span>
        );
      })}
    </span>
  );
};

type SearchField =
  | 'deviceName'
  | 'deviceId'
  | 'serialNumber'
  | 'model'
  | 'userEmail'
  | 'userName'
  | 'assetTag';

export interface DeviceMetadata {
  deviceId: string;
  serialNumber: string;
  deviceName: string;
  deviceFamily: string;
  model: string;
  modelId: string;
  selected?: boolean;
  userEmail?: string;
  userName?: string;
  assetTag?: string;
}

interface DeviceSearchListProps {
  devices: DeviceMetadata[];
  searchTerm: string;
  searchFields?: SearchField[];
  onSelect: (device: DeviceMetadata) => void;
  options?: {
    displayEmptyUser?: boolean;
  };
}

const getDeviceIcon = (modelId: string) =>
  deviceImagesMap[modelId] ?? getDeviceIconByFamily(modelId);

const StyledSearchListItem = styled(Flex, {
  padding: '$2 $3',
  gap: '$2',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$neutral0',
  '&:hover, &:focus': {
    backgroundColor: '#505E7129',
  },
  '&[data-selected=true]': {
    backgroundColor: '#AACAFF4D',
  },
  '&:not(:last-child)': {
    borderBottom: '1px solid $neutral30',
  },
});

const SearchListItem = ({
  deviceInfo,
  searchTerm,
  searchFields = ['deviceName'],
  onClick,
  options,
}: {
  deviceInfo: DeviceMetadata;
  searchTerm: string;
  searchFields?: SearchField[];
  onClick: () => void;
  options: DeviceSearchListProps['options'];
}) => {
  const {
    serialNumber,
    deviceName,
    deviceFamily,
    model,
    modelId,
    selected,
    userEmail,
    userName,
    assetTag,
  } = deviceInfo;
  const { displayEmptyUser = true } = options;
  const icon = getDeviceIcon(modelId);

  return (
    <StyledSearchListItem data-selected={selected} onClick={onClick}>
      <Flex alignItems="center" justifyContent="center" css={{ flex: 'none' }}>
        <img
          data-testid="device_icon_image"
          height="36"
          width="36"
          src={icon}
          alt={deviceFamily}
        />
      </Flex>
      <Flex flow="column" css={{ color: '$neutral70' }}>
        <Text css={{ fontWeight: '$medium' }}>
          {searchFields.includes('deviceName')
            ? highlightedText({ text: deviceName, term: searchTerm })
            : deviceName}
        </Text>
        <Text css={{ fontSize: '$1' }}>
          {searchFields.includes('model')
            ? highlightedText({ text: model, term: searchTerm })
            : model}{' '}
          -{' '}
          {searchFields.includes('serialNumber')
            ? highlightedText({ text: serialNumber, term: searchTerm })
            : serialNumber}
          {searchFields.includes('assetTag') && assetTag && ' - '}
          {searchFields.includes('assetTag')
            ? highlightedText({ text: assetTag, term: searchTerm })
            : assetTag}
        </Text>
        <Flex
          alignItems="center"
          css={{
            gap: '2px',
            maxHeight: '16px',
            overflowX: 'hidden',
            fontSize: '$1',
          }}
        >
          {userName && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="user" />
              </Flex>
              <Text css={{ flex: 'none' }}>
                {searchFields.includes('userName')
                  ? highlightedText({
                      text: userName,
                      term: searchTerm,
                    })
                  : userName}{' '}
                {userEmail && ' - '}
              </Text>
            </>
          )}
          {userEmail && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="envelope" />
              </Flex>
              <Text css={{ flex: 'none' }}>
                {searchFields.includes('userEmail')
                  ? highlightedText({
                      text: userEmail,
                      term: searchTerm,
                    })
                  : userEmail}
              </Text>
            </>
          )}
          {displayEmptyUser && !userName && !userEmail && (
            <Text css={{ fontStyle: 'italic' }}>User not assigned</Text>
          )}
        </Flex>
      </Flex>
      {selected && (
        <Flex justifyContent="end" css={{ flexGrow: 1, color: '$blue50' }}>
          <Icon size="sm" name="fa-check-14px" />
        </Flex>
      )}
    </StyledSearchListItem>
  );
};

export const DeviceSearchList = ({
  devices,
  searchTerm,
  searchFields,
  onSelect,
  options = {},
}: DeviceSearchListProps) => (
  <>
    {devices.map((device) => (
      <SearchListItem
        key={device.deviceId}
        deviceInfo={device}
        searchTerm={searchTerm}
        searchFields={searchFields}
        onClick={() => onSelect(device)}
        options={options}
      />
    ))}
  </>
);
