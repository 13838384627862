// istanbul ignore file
import { Button, type IconNames, styled } from '@kandji-inc/nectar-ui';
import EDRTooltip from 'src/features/edr/common/components/EDRTooltip';

const StyledButton = styled(Button, {
  width: 28,
  height: 28,
  '& svg': {
    height: 16,
    width: 16,
    flexShrink: 0,
  },
});

type ThreatListSquareButtonProps = {
  tooltip: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  icon: IconNames;
  testId?: string;
  disabled?: boolean;
  onClick: () => void;
};

const ThreatListSquareButton = ({
  tooltip,
  tooltipPosition = 'left',
  icon,
  disabled = false,
  testId,
  onClick,
}: ThreatListSquareButtonProps) => (
  <EDRTooltip side={tooltipPosition} label={tooltip}>
    <StyledButton
      compact
      icon={{ name: icon }}
      variant="subtle"
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
    />
  </EDRTooltip>
);

export default ThreatListSquareButton;
