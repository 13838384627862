import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'src/features/library-items/data-service/library-item/devices';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';
import initialState from '../initial-state';
import SoftwareUpdateService from './software-update-service';

const useInitialValueIfNotPresent = (data, key, initialValue) => {
  if (key in data && data[key] !== null) {
    return data[key];
  }

  return initialValue;
};

function transformSettings(settings) {
  // Check for legacy property and use it if new property isn't set:
  let enforcedSoftwareUpdateDelay;
  if (
    settings.ManagedDeferredInstallDelay &&
    !settings.enforcedSoftwareUpdateDelay
  ) {
    enforcedSoftwareUpdateDelay = settings.ManagedDeferredInstallDelay;
  } else {
    enforcedSoftwareUpdateDelay = settings.enforcedSoftwareUpdateDelay;
  }

  const booleanToAllowedOptions = {
    true: SoftwareUpdateService.ALLOWED_OPTIONS.alwaysOn.value,
    false: SoftwareUpdateService.ALLOWED_OPTIONS.alwaysOff.value,
  };

  const isDeferAll = 'enforcedSoftwareUpdateDelay' in settings;
  const isDeferByType = [
    'enforcedSoftwareUpdateMajorOSDeferredInstallDelay',
    'enforcedSoftwareUpdateMinorOSDeferredInstallDelay',
    'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
  ].some((key) => key in settings);

  const transformedSettings = {
    modernConfigurations: {
      Download:
        'AutomaticDownload' in settings
          ? booleanToAllowedOptions[settings.AutomaticDownload]
          : useInitialValueIfNotPresent(
              settings,
              'Download',
              initialState.modernConfigurations.Download,
            ),
      InstallOSUpdates:
        'AutomaticallyInstallMacOSUpdates' in settings
          ? booleanToAllowedOptions[settings.AutomaticallyInstallMacOSUpdates]
          : useInitialValueIfNotPresent(
              settings,
              'InstallOSUpdates',
              initialState.modernConfigurations.InstallOSUpdates,
            ),

      InstallSecurityUpdate:
        'CriticalUpdateInstall' in settings
          ? booleanToAllowedOptions[settings.CriticalUpdateInstall]
          : useInitialValueIfNotPresent(
              settings,
              'InstallSecurityUpdate',
              initialState.modernConfigurations.InstallSecurityUpdate,
            ),

      AutomaticCheckEnabled: useInitialValueIfNotPresent(
        settings,
        'AutomaticCheckEnabled',
        initialState.modernConfigurations.AutomaticCheckEnabled,
      ),
      AutomaticallyInstallAppUpdates: useInitialValueIfNotPresent(
        settings,
        'AutomaticallyInstallAppUpdates',
        initialState.modernConfigurations.AutomaticallyInstallAppUpdates,
      ),

      // Rapid Security Responses
      Enable: useInitialValueIfNotPresent(
        settings,
        'Enable',
        initialState.modernConfigurations.Enable,
      ),
      EnableRollback: useInitialValueIfNotPresent(
        settings,
        'EnableRollback',
        initialState.modernConfigurations.EnableRollback,
      ),

      // Defer iOS
      deferiOsUpdates: Boolean(settings.CombinedPeriodInDays),
      CombinedPeriodInDays: useInitialValueIfNotPresent(
        settings,
        'CombinedPeriodInDays',
        initialState.modernConfigurations.CombinedPeriodInDays,
      ),
      RecommendedCadence: useInitialValueIfNotPresent(
        settings,
        'RecommendedCadence',
        initialState.modernConfigurations.RecommendedCadence,
      ),

      // Defer macOS
      deferMacOSUpdates: isDeferAll
        ? SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferAll.value
        : isDeferByType
          ? SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferByType.value
          : SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.doNotDefer.value,

      // Defer all
      enforcedSoftwareUpdateDelay: isDeferAll
        ? enforcedSoftwareUpdateDelay
        : initialState.modernConfigurations.enforcedSoftwareUpdateDelay,

      // Defer by type
      forceDelayedMajorSoftwareUpdates: Boolean(
        settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay,
      ),
      enforcedSoftwareUpdateMajorOSDeferredInstallDelay:
        useInitialValueIfNotPresent(
          settings,
          'enforcedSoftwareUpdateMajorOSDeferredInstallDelay',
          initialState.modernConfigurations
            .enforcedSoftwareUpdateMajorOSDeferredInstallDelay,
        ),
      forceDelayedMinorSoftwareUpdates: Boolean(
        settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay,
      ),
      enforcedSoftwareUpdateMinorOSDeferredInstallDelay:
        useInitialValueIfNotPresent(
          settings,
          'enforcedSoftwareUpdateMinorOSDeferredInstallDelay',
          initialState.modernConfigurations
            .enforcedSoftwareUpdateMinorOSDeferredInstallDelay,
        ),
      forceDelayedAppSoftwareUpdates: Boolean(
        settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay,
      ),
      enforcedSoftwareUpdateNonOSDeferredInstallDelay:
        useInitialValueIfNotPresent(
          settings,
          'enforcedSoftwareUpdateNonOSDeferredInstallDelay',
          initialState.modernConfigurations
            .enforcedSoftwareUpdateNonOSDeferredInstallDelay,
        ),

      // Other settings
      Notifications: useInitialValueIfNotPresent(
        settings,
        'Notifications',
        initialState.modernConfigurations.Notifications,
      ),
      AllowPreReleaseInstallation: settings.AllowPreReleaseInstallation,

      'restrict-software-update-require-admin-to-install': Boolean(
        settings['restrict-software-update-require-admin-to-install'],
      ),
    },
  };

  return transformedSettings;
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToOldApi(model) {
  const isDeferiOsUpdates = model.modernConfigurations.deferiOsUpdates;
  const isDeferAllUpdates =
    model.modernConfigurations.deferMacOSUpdates ===
    SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferAll.value;
  const isDeferByType =
    model.modernConfigurations.deferMacOSUpdates ===
    SoftwareUpdateService.DEFER_MACOS_UPDATES_OPTIONS.deferByType.value;
  const isDeferMajorMacOsUpgrades =
    model.modernConfigurations.forceDelayedMajorSoftwareUpdates;
  const isDeferMinorMacOsUpgrades =
    model.modernConfigurations.forceDelayedMinorSoftwareUpdates;
  const isDeferSystemUpdates =
    model.modernConfigurations.forceDelayedAppSoftwareUpdates;

  const result: any = {
    name: model.name,
    is_active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    settings: {
      Download: model.modernConfigurations.Download,
      InstallOSUpdates: model.modernConfigurations.InstallOSUpdates,
      InstallSecurityUpdate: model.modernConfigurations.InstallSecurityUpdate,

      AutomaticCheckEnabled: model.modernConfigurations.AutomaticCheckEnabled,
      AutomaticallyInstallAppUpdates:
        model.modernConfigurations.AutomaticallyInstallAppUpdates,

      Enable: model.modernConfigurations.Enable,
      EnableRollback: model.modernConfigurations.EnableRollback,

      // Defer iOS
      CombinedPeriodInDays: isDeferiOsUpdates
        ? parseInt(model.modernConfigurations.CombinedPeriodInDays)
        : null,
      RecommendedCadence: model.modernConfigurations.RecommendedCadence,

      Notifications: model.modernConfigurations.Notifications,
      AllowPreReleaseInstallation:
        model.modernConfigurations.AllowPreReleaseInstallation,
      'restrict-software-update-require-admin-to-install':
        model.modernConfigurations[
          'restrict-software-update-require-admin-to-install'
        ],
    },
  };

  if (isDeferAllUpdates) {
    result.settings.forceDelayedSoftwareUpdates = true;
    result.settings.enforcedSoftwareUpdateDelay = parseInt(
      model.modernConfigurations.enforcedSoftwareUpdateDelay,
    );
  }

  if (isDeferByType) {
    if (isDeferMajorMacOsUpgrades) {
      result.settings.forceDelayedMajorSoftwareUpdates = true;
      result.settings.enforcedSoftwareUpdateMajorOSDeferredInstallDelay =
        parseInt(
          model.modernConfigurations
            .enforcedSoftwareUpdateMajorOSDeferredInstallDelay,
        );
    }
    if (isDeferMinorMacOsUpgrades) {
      result.settings.forceDelayedMinorSoftwareUpdates = true;
      result.settings.enforcedSoftwareUpdateMinorOSDeferredInstallDelay =
        parseInt(
          model.modernConfigurations
            .enforcedSoftwareUpdateMinorOSDeferredInstallDelay,
        );
    }
    if (isDeferSystemUpdates) {
      result.settings.forceDelayedAppSoftwareUpdates = true;
      result.settings.enforcedSoftwareUpdateNonOSDeferredInstallDelay =
        parseInt(
          model.modernConfigurations
            .enforcedSoftwareUpdateNonOSDeferredInstallDelay,
        );
    }
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs['Software Update'];

    result.type = itemConfig.type;
    result.identifier = itemConfig.identifier;
  }

  return result;
}

function transformToNewApi(model) {
  const toSend = transformToOldApi(model);
  toSend.active = toSend.is_active;
  delete toSend.is_active;
  toSend.data = toSend.settings;
  delete toSend.settings;

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  const runsOn = getRunsOnFromModel(model);
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  return toSend;
}

function transformToApi(model) {
  const transformer = transformToNewApi;

  return transformer(model);
}

export { transformToApi, transformFromApi };
