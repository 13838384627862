import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';

export const severityColorMap = {
  critical: '$red60',
  high: '$red50',
  medium: '$yellow60',
  low: '$yellow50',
  none: '$green50',
};

export const DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS = [
  {
    label: 'Last 24 hours',
    value: '24hours',
  },
  {
    label: 'Last 7 days',
    value: '7days',
  },
  {
    label: 'Last 30 days',
    value: '30days',
  },
  {
    label: 'Last 60 days',
    value: '60days',
  },
  {
    label: 'Last 90 days',
    value: '90days',
  },
  {
    label: 'All time',
    value: 'all_time',
  },
  {
    label: 'Custom date range',
    value: 'custom_date_range',
  },
] as const;

export const DEFAULT_DETECTION_DATE_FILTER = { value: '30days' };

export const defaultDateFilter: DetectionDateFilterFields = {
  value: '',
  operator: '',
  dateRangeFrom: '',
  dateRangeTo: '',
  selectedDate: '',
};

export const defaultAllVulnerabilitiesFilter = {
  term: '',
  severity: [],
  firstDetected: null,
  latestDetected: DEFAULT_DETECTION_DATE_FILTER,
};

export const defaultDeviceVulnerabilitiesFilter = {
  term: '',
  severity: [],
  detectionDate: DEFAULT_DETECTION_DATE_FILTER,
};
