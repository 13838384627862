import { TextInput, Toggle, useInputsValidators } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import { i18n } from 'src/i18n';

const Information = (props) => {
  const { update, setting, triggerValidate } = props;
  const { displayInfoBtn, infoBtnText, infoBtnUrl } = setting;
  const fieldsToValidate = ['informationLabel', 'informationUrl'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate(0)(displayInfoBtn && !infoBtnText);
    onInvalidate(1)(displayInfoBtn && !infoBtnUrl);
  }, [displayInfoBtn]);

  const trigger = ['onBlur', triggerValidate];
  const requiredValidator = (v) => ({
    message: i18n.t('Required field if toggle is on.'),
    invalid: () => !v,
    trigger,
  });

  return (
    <div className="k-ss2-drawer-section --info">
      <h3 className="b-h3">{i18n.t('Information')}</h3>
      <div className="k-ss2-drawer-setting">
        <div className="k-ss2-drawer-setting-with-toggle">
          <div>
            <p className="b-txt">{i18n.t('Display a link')}</p>
            <p className="b-txt-light">
              {i18n.t(
                'Include a support article or documentation that will display in the item detail page in Self Service.',
              )}
            </p>
          </div>
          <div>
            <Toggle
              checked={displayInfoBtn}
              onToggle={() => update('displayInfoBtn', !displayInfoBtn)}
            />
          </div>
        </div>
        {displayInfoBtn && (
          <div className="k-ss2-drawer-setting-extra b-form-grid">
            <div ref={refs[0]}>
              <TextInput
                label={i18n.t('Text button label')}
                maxLength={32}
                fieldsGrid
                placeholder={i18n.t('Ex. More information')}
                value={infoBtnText}
                validator={(v) => [requiredValidator(v)]}
                onInvalidate={onInvalidate(0)}
                onChange={(e) => update('infoBtnText', e.target.value)}
              />
            </div>
            <div ref={refs[1]}>
              <p className="b-txt b-mb-micro">Button URL </p>
              <p className="b-txt-light b-mb1">
                {i18n.t(
                  'Any URL scheme recognized by iOS can be used, including https://, mailto://, and file://.',
                )}
              </p>
              <TextInput
                value={infoBtnUrl}
                validator={(v) => [requiredValidator(v)]}
                onInvalidate={onInvalidate(1)}
                onChange={(e) => update('infoBtnUrl', e.target.value)}
                placeholder={i18n.t('https://, mailto://, and file://.')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Information;
