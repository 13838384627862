// istanbul ignore file
import { FilterButton, MultiSelect } from '@kandji-inc/nectar-ui';
import type { ColumnMetaFilterType, DeviceFamily } from '@tanstack/react-table';
import { i18n } from 'i18n';

const DEVICE_FAMILY_OPTIONS: { label: string; value: DeviceFamily }[] = [
  { label: 'Mac', value: 'Mac' },
  { label: 'iPhone', value: 'iPhone' },
  { label: 'iPad', value: 'iPad' },
  { label: 'Apple TV', value: 'AppleTV' },
];

export const DeviceFamilyFilter = ({
  deviceFamilyFilter,
  setDeviceFamilyFilter,
}: {
  deviceFamilyFilter: string[];
  setDeviceFamilyFilter: (values: string[]) => void;
}) => {
  return (
    <MultiSelect
      options={DEVICE_FAMILY_OPTIONS}
      onChange={(values) => setDeviceFamilyFilter(values)}
      value={deviceFamilyFilter}
      multi
      size="sm"
      componentCss={{ menu: { zIndex: 999, width: '100%' } }}
    >
      <FilterButton
        maxWidth={190}
        selectedFilters={DEVICE_FAMILY_OPTIONS.filter((option) =>
          deviceFamilyFilter.includes(option.value),
        )}
        showRemove={false}
      >
        {i18n.t('Device family')}
      </FilterButton>
    </MultiSelect>
  );
};

const DATA_TYPE_OPTIONS: { label: string; value: ColumnMetaFilterType }[] = [
  { label: 'Boolean', value: 'boolean' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date-time' },
  { label: 'Enum', value: 'enum' },
  { label: 'Text', value: 'string' },
  { label: 'Version', value: 'version' },
];

export const DataTypeFilter = ({
  dataTypeFilter,
  setDataTypeFilter,
}: {
  dataTypeFilter: string[];
  setDataTypeFilter: (values: string[]) => void;
}) => {
  return (
    <MultiSelect
      options={DATA_TYPE_OPTIONS}
      onChange={(values) => setDataTypeFilter(values)}
      value={dataTypeFilter}
      multi
      size="sm"
      componentCss={{ menu: { zIndex: 999, width: '100%' } }}
    >
      <FilterButton
        maxWidth={190}
        selectedFilters={DATA_TYPE_OPTIONS.filter((option) =>
          dataTypeFilter.includes(option.value),
        )}
        showRemove={false}
      >
        {i18n.t('Data type')}
      </FilterButton>
    </MultiSelect>
  );
};
