/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { VulnerableDevice } from '../vulnerability.types';

const useGetVulnerabilityDevices = (
  vulnerabilityId: string,
  appName: string,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerability', vulnerabilityId, ...keys],
    queryFn: () =>
      vulnerabilityApi(
        `vulnerabilities/${vulnerabilityId}/applications/${appName}/devices`,
      ).get(params),
  });

  const data = apiRes?.data;
  const devices: VulnerableDevice[] = data?.results || [];
  const count = data?.total;

  return { devices, count, isLoading };
};

export { useGetVulnerabilityDevices };
