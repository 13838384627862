import {
  Chip,
  Icon,
  IconUpload,
  Menu,
  TextInput,
  Toggle,
  setClass,
  useOnChange,
  // IconNames,
} from '@kandji-inc/bumblebee';
import { array, bool, func, object, string } from 'prop-types';
import React, { useCallback, useState } from 'react';

import { i18n } from 'src/i18n';
import { MENU_THEME_CLASSES } from './constants';
// import type { IconNames } from '../../atoms/icon/IconNames';
import type { IconNames } from './icon-names';
import { Ellipsis } from './icons';

const Header = (props: any) => {
  const {
    style,
    className,
    icon,
    defaultIcon,
    isIconEditable,
    name,
    placeholder,
    isNameEditable,
    isActive,
    isDisabled,
    nameValidator,
    onChange,
    isSubmitted,
    isEditing,
    quickActions,
    // component: Component,
  } = props;
  /* TODO: need to add two variations ? */
  // if (Component) {
  //   return <Component {...props} />;
  // }

  const [localModel, setLocalModel] = useState({
    isValid: true,
    validationResult: [],
    iconFile: undefined,
  });

  useOnChange(onChange, { ...localModel, name, isActive });
  const onFieldChange = (field: any, value: any) => {
    onChange({
      ...localModel,
      name,
      isActive,
      [field]: value,
    });
  };

  const onInvalidate = (v: any) =>
    setLocalModel((p) => ({ ...p, isValid: !v }));

  const titleMaxLength = 50;

  const defaultValidator = useCallback(
    (v) => {
      const trigger = ['onBlur', isSubmitted && 'onMount'];
      const r1 = {
        invalid: () => typeof v !== 'string' || !v.length,
        message: i18n.t('A title is required'),
        trigger,
      };
      const r2 = {
        message: i18n.t('Title is too long'),
        invalid: () => v.length > titleMaxLength,
        trigger,
      };
      return [r1, r2];
    },
    [isSubmitted],
  );

  const menuList = quickActions?.map(
    (action: {
      name: string;
      icon: string;
      theme: keyof typeof MENU_THEME_CLASSES;
      onClick: any;
      isDisabled: boolean;
    }) => (
      <button
        type="button"
        key={action.name}
        className={setClass(
          action.theme ? `${MENU_THEME_CLASSES[action.theme]}` : '',
        )}
        onClick={action.onClick}
        disabled={action.isDisabled}
      >
        <Icon name={action.icon as IconNames} className="b-mr-tiny" />
        {action.name}
      </button>
    ),
  );

  return (
    <div
      style={style}
      className={setClass('b-library-summary__header', className)}
    >
      {isIconEditable && (
        /* istanbul ignore next */ <IconUpload
          isDisabled={isDisabled}
          currentIcon={icon}
          defaultIcon={defaultIcon}
          onFileSelect={
            /* istanbul ignore next */ (file: any) =>
              setLocalModel((p) => ({
                ...p,
                iconFile: file,
              }))
          }
          onDelete={
            /* istanbul ignore next */ () =>
              setLocalModel((p) => ({
                ...p,
                iconFile: null,
              }))
          }
        />
      )}

      {!isIconEditable && <img src={defaultIcon || icon} alt="icon" />}

      <div className="b-library-summary__input">
        {!isDisabled && isNameEditable ? (
          <TextInput
            kind="library"
            validator={nameValidator || defaultValidator}
            onInvalidate={onInvalidate}
            onValidationResult={(r: any) =>
              setLocalModel((p) => ({ ...p, validationResult: r }))
            }
            placeholder={placeholder}
            value={name}
            onChange={(e: any) => {
              e.persist();
              onFieldChange('name', e.target.value);
            }}
            maxLength={titleMaxLength}
            hideMaxLength
            showMaxLengthMessage={false}
          />
        ) : (
          <div className="b-flex-row static-header">
            <h1 className="b-h1">{name}</h1>
            {/* The quick actions menu should only appear in the view state */}
            {!isEditing && quickActions.length > 0 && (
              <Menu
                className="b-ml"
                positionStyle={{ bottom: { top: '30px' } }}
              >
                <div>{menuList}</div>
                <button aria-label="quick-actions-menu" type="button">
                  <Ellipsis />
                </button>
              </Menu>
            )}
          </div>
        )}
      </div>

      <div className="b-library-summary__status">
        <Chip
          disabled={isDisabled}
          className="b-txt-ctrl8"
          kind={isActive ? 'active' : 'inactive'}
          text={isActive ? i18n.t('Active') : i18n.t('Inactive')}
        />
        <Toggle
          disabled={isDisabled}
          checked={isActive}
          onToggle={() => onFieldChange('isActive', !isActive)}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  style: object,
  className: string,
  icon: string,
  defaultIcon: string,
  isIconEditable: bool,
  name: string.isRequired,
  placeholder: string,
  isActive: bool.isRequired,
  nameValidator: func,
  onChange: func.isRequired,
  // component: oneOfType([node, element]),
  isDisabled: bool,
  isNameEditable: bool,
  isSubmitted: bool,
  isEditing: bool,
  quickActions: array,
};

Header.defaultProps = {
  style: {},
  className: null,
  icon: '',
  defaultIcon: null,
  isIconEditable: false,
  nameValidator: null,
  // component: null,
  isDisabled: false,
  isNameEditable: true,
  placeholder: 'Add a title',
  isSubmitted: undefined,
  isEditing: false,
  quickActions: [],
};

export default Header;
