import {
  BasePagination,
  Flex,
  PaginationPrimitives as Pagination,
  styled,
} from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import EDRDropdownButton from '../../../../common/components/EDRDropdownButton';

const PaginationIconButton = styled(Pagination.IconButton, {
  width: '28px',
  height: '28px',
  '& > svg': {
    height: '$4',
    width: '$4',
    flexShrink: 0,
  },
  '&:hover': {
    background: '$button_subtle_background_hover',
  },
  '&:active': {
    background: '$button_subtle_background_active',
  },
});

type ThreatListTablePaginationProps = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  bulkActions?: React.ReactNode;
  onPageChange: (page: number) => void;
};

const ThreatListTablePagination = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  bulkActions,
  onPageChange,
}: ThreatListTablePaginationProps) => {
  const newCurrentPage = currentPage > totalPages ? totalPages : currentPage;
  const [valueText, setValueText] = useState(
    Pagination.getPageString(newCurrentPage, totalPages),
  );
  const isLastPage = newCurrentPage === totalPages;
  const isFirstPage = newCurrentPage === 1;

  const handlePageChange = (page: number) => {
    setValueText(Pagination.getPageString(page, totalPages));
    onPageChange(page);
  };

  useEffect(() => {
    setValueText(Pagination.getPageString(newCurrentPage, totalPages));
  }, [newCurrentPage, totalPages]);

  const options = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <BasePagination data-testid="threats-table-pagination">
      <Flex>
        {bulkActions || (
          <Pagination.Summary
            currentPage={newCurrentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            subject=""
          />
        )}
      </Flex>
      <Flex gap="xs">
        <PaginationIconButton
          aria-label="select first page"
          type="button"
          onClick={() => handlePageChange(1)}
          disabled={isFirstPage}
          icon="arrow-left-to-line"
        />
        <PaginationIconButton
          aria-label="select previous page"
          type="button"
          onClick={() => handlePageChange(newCurrentPage - 1)}
          disabled={isFirstPage}
          icon="fa-arrow-left-to-line-control"
        />
        <EDRDropdownButton
          value={newCurrentPage.toString()}
          triggerLabel={valueText}
          options={options.map((option) => ({
            value: option.toString(),
            label: option.toString(),
          }))}
          onChange={handlePageChange}
          disabled={totalPages === 1}
          testId="threats-table-pagination-select"
        />
        <PaginationIconButton
          aria-label="select next page"
          type="button"
          onClick={() => handlePageChange(newCurrentPage + 1)}
          disabled={isLastPage}
          icon="fa-arrow-right-to-line-control"
        />
        <PaginationIconButton
          aria-label="select last page"
          type="button"
          onClick={() => handlePageChange(totalPages)}
          disabled={isLastPage}
          icon="arrow-right-to-line"
        />
      </Flex>
    </BasePagination>
  );
};

export default ThreatListTablePagination;
