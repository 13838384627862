import { parse, stringify } from 'query-string';
import { StrictMode } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import featureFlags from 'src/config/feature-flags';

import useAddBodyClass from 'features/util/hooks/use-add-body-class';
import ThreatListErrorBoundary from './common/components/ThreatListErrorBoundary';
import ThreatListSuspense from './common/components/ThreatListSuspense';
import ThreatListV2 from './threat-events/ThreatList';
import ThreatListV3 from './threat-groups/ThreatList';

import './styles.css';

const ThreatListFFSwitch = (props: any) => {
  const { isOnDeviceRecord } = props;
  useAddBodyClass('threat', !isOnDeviceRecord);
  const isFeatureFlagV3On = featureFlags.getFlag('edr_041724_threats-v3');

  return (
    <StrictMode>
      <ThreatListErrorBoundary css={{ position: 'absolute', inset: 0 }}>
        <ThreatListSuspense>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify,
              removeDefaultsFromUrl: true,
            }}
          >
            {isFeatureFlagV3On && !props.forceV2 ? (
              <ThreatListV3 {...props} />
            ) : (
              <ThreatListV2 {...props} />
            )}
          </QueryParamProvider>
        </ThreatListSuspense>
      </ThreatListErrorBoundary>
    </StrictMode>
  );
};

export default ThreatListFFSwitch;
