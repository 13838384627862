import {
  Button,
  sidePanel as SidePanel,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import featureFlags from 'src/config/feature-flags';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import styled from 'styled-components';

import msTeamsIcon from '../../assets/ms-teams-icon.svg';

import { mapNotificationsToSnakeCase } from '../../../../utils';
import useAddNewNotification from '../../hooks/use-add-new-notification';
import useUpdateNotification from '../../hooks/use-update-notification';
import ChannelSelect from './channel-select/channel-select';
import ChannelTypeaheadSelect from './channel-select/channel-typeahead-select';
import PanelSectionTitle from './components/panel-section-title';
import EventNotificationName from './event-notification-name/event-notificaiton-name';
import EventNotifications from './event-notifications/event-notifications';
import SendTestNotification from './send-test-notification/send-test-notification';
import {
  PanelButtonContainer,
  PanelSection,
  PanelTitle,
} from './styled-components';
import TeamsSelect from './teams-select/teams-select';

const StyledSidePanel = styled(SidePanel)``;

const PanelForm = styled.form`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  & h4 {
    font-size: 16px;
  }
`;

const PanelSectionContainer = styled.div`
  padding: var(--b-gap3);
  display: grid;
  gap: 48px;
`;

const StyledEventNotificationsContainer = styled(PanelSection)`
  & > ${EventNotifications} {
    margin-top: var(--b-gap2);
  }
`;

const MSTeamsPanel = (props) => {
  const {
    isVisible,
    isAdding,
    data,
    handlePanelToggle,
    onDeleteNotification,
    getMSTeamsIntegrations,
  } = props;

  // Turn on/of typeahead select for ms channels
  const channelsTypeaheadFeatureFlag = featureFlags.getFlag(
    'splint-ms-channels-typeahead',
  );

  const [formValues, setFormValues] = useState({});

  const updateNotification = useUpdateNotification();
  const { createNewNotification, loading } = useAddNewNotification();

  useAdjustSidebarChatBubble();

  const handleFormChange = (name, value) => {
    if (name === 'team') {
      setFormValues({
        ...formValues,
        channels: [], // specifically want to clear channels if new team is selected
        team: value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  /* istanbul ignore next */
  const handleSubmit = (event) => {
    event.preventDefault();

    const notificationId = data?.id;
    const channelIds = formValues.channels.map((channel) => channel.id);
    const { name, events } = formValues;

    const updatedData = {
      channels: channelIds,
      events,
      name,
    };

    if (isAdding) {
      createNewNotification(updatedData).then(() => {
        getMSTeamsIntegrations();
      });
    } else {
      updateNotification
        .mutateAsync({ id: notificationId, data: updatedData })
        .then(() => {
          getMSTeamsIntegrations();
        });
    }

    handlePanelToggle();

    const toastMessage = `${name} has been ${isAdding ? 'added' : 'updated'}.`;
    toaster(toastMessage);
  };

  /* istanbul ignore next */
  const handleEventNotificationUpdates = (value) => {
    // get snake cased values on updated toggle and update events array
    const mappedNotificationValues = mapNotificationsToSnakeCase(value);
    handleFormChange('events', mappedNotificationValues);
  };

  const handleDeleteNotification = (id, name) => {
    onDeleteNotification(id, name);
  };

  const isSubmitDisabled =
    !formValues?.channels?.length ||
    !formValues?.name ||
    !formValues?.events?.length;

  useEffect(() => {
    if (Object.keys(data).length) {
      // put event names into a flat array
      const events = data?.events?.map((event) => event.name);

      // handle setting initial form values
      setFormValues({ ...data, events });
    }
  }, [data]);

  return (
    <StyledSidePanel isVisible={isVisible}>
      <PanelForm onSubmit={handleSubmit}>
        <PanelTitle>
          <h2 className="b-h2">
            {isAdding ? 'Add' : 'Edit'} event notification
          </h2>
          <img src={msTeamsIcon} alt="MS Teams Icon" />
        </PanelTitle>

        <PanelSectionContainer>
          <PanelSection>
            <PanelSectionTitle title="General" />
            <div>
              <TeamsSelect
                value={formValues.team}
                integrationId={data?.integrationId}
                onChange={(value) => handleFormChange('team', value)}
              />

              {channelsTypeaheadFeatureFlag ? (
                <ChannelTypeaheadSelect
                  integrationId={data?.integrationId}
                  selectedTeamId={formValues?.team?.id}
                  onChange={(value) => handleFormChange('channels', value)}
                />
              ) : (
                <ChannelSelect
                  value={formValues.channels}
                  integrationId={data?.integrationId}
                  selectedTeamId={formValues?.team?.id}
                  onChange={(value) => handleFormChange('channels', value)}
                />
              )}

              <EventNotificationName
                value={formValues.name}
                onChange={(value) => handleFormChange('name', value)}
              />
            </div>
          </PanelSection>
          <StyledEventNotificationsContainer>
            <PanelSectionTitle
              title="Event triggers"
              description="Specify which events should trigger a notification."
            />

            <EventNotifications
              value={formValues.events}
              onChange={handleEventNotificationUpdates}
            />
          </StyledEventNotificationsContainer>

          <PanelSection>
            <PanelSectionTitle
              title="Test notification"
              description="Send a test notification before adding this event notification. We will provide a sample message to the channel/s specified above. "
            />

            <SendTestNotification
              integrationId={data?.integrationId}
              channels={formValues.channels}
            />
          </PanelSection>
        </PanelSectionContainer>

        <PanelButtonContainer>
          {!isAdding && (
            <Button
              kind="link"
              theme="error"
              onClick={() => handleDeleteNotification(data?.id, data?.name)}
            >
              Delete
            </Button>
          )}

          <div>
            <Button kind="outline" onClick={handlePanelToggle}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitDisabled}
              isProgress={loading}
            >
              Done
            </Button>
          </div>
        </PanelButtonContainer>
      </PanelForm>
    </StyledSidePanel>
  );
};

export default MSTeamsPanel;

MSTeamsPanel.propTypes = {
  isVisible: bool,
  isAdding: bool,
  data: object,
  onDeleteNotification: func.isRequired,
};

MSTeamsPanel.defaultProps = {
  isVisible: false,
  isAdding: false,
  data: {},
};
