import {
  Button,
  Checkbox,
  TextInput,
  Toaster as toaster,
} from '@kandji-inc/bumblebee';
import { bool, func, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { links } from 'app/common/constants';
import { INT_TYPES } from 'features/integrations/constants';
import Stepper from 'features/util/components/stepper';
import { useHistory } from 'react-router-dom';
import { newIntegrationsService } from '../../../data-service/new-integrations-service';
import {
  INITIAL_STATE as COMMON_STATE_FIELDS,
  getTickets as getCommonTickets,
} from '../../generic/user-integrations/welcome-modal';
import largeIcon from '../assets/large-icon.svg';
import tokenSlideIcons from '../assets/token-slide-icons.svg';
import './welcome-modal.styles.scss';
import { i18n } from 'i18n';

const INITIAL_STATE = { ...COMMON_STATE_FIELDS };
INITIAL_STATE.newIntegrationData.token = '';
INITIAL_STATE.newIntegrationData.tokenIsCopied = false;

const tickets = getCommonTickets('', true);

const SCIM_INTEGRATION_SHAPE = shape({
  name: string.isRequired,
  token: string.isRequired,
  tokenIsCopied: bool.isRequired,
});

const SCIM_SLIDE_PROP_TYPES = {
  newIntegrationData: SCIM_INTEGRATION_SHAPE.isRequired,
  updateField: func.isRequired,
  isActive: bool.isRequired,
};

function NameSlide({ newIntegrationData, updateField, isActive }) {
  const [isFetching, setIsFetching] = useState(true);
  const [configuredIntegrationsNames, setConfiguredIntegrationsNames] =
    useState([]);
  const nameIsEmpty = !newIntegrationData.name;
  const isDuplicate = configuredIntegrationsNames.includes(
    newIntegrationData.name,
  );

  useEffect(() => {
    updateField('nextButtonDisabled', (isDuplicate || nameIsEmpty) && isActive);
  }, [isActive, nameIsEmpty, isDuplicate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      let integrationNames = [];
      try {
        const response = await newIntegrationsService.listIntegrations();
        integrationNames = response?.data?.results.map((elem) => elem.name);
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
        setConfiguredIntegrationsNames(integrationNames);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="cmn-modal_info-section">
      <img src={largeIcon} alt="header-icon" className="b-mb2" />
      <h1 className="b-h1 b-mb1">Configure a SCIM user integration</h1>
      <p className="b-txt b-mb3">
        Provide a unique name for the SCIM integration and generate an API
        token.
      </p>
      <TextInput
        value={newIntegrationData.name}
        maxLength={32}
        placeholder="Enter a unique name"
        validator={(name) => [
          {
            message: 'Required',
            invalid: () => name.length === 0,
          },
          {
            message: 'Duplicate name found. Enter a different name.',
            invalid: () => configuredIntegrationsNames.includes(name),
          },
        ]}
        onChange={(e) => {
          e.persist();
          const {
            target: { value },
          } = e;
          updateField('newIntegrationData', (curr) => ({
            ...curr,
            name: value,
          }));
        }}
      />
    </div>
  );
}

NameSlide.propTypes = { ...SCIM_SLIDE_PROP_TYPES };

function TokenSlide({ newIntegrationData, updateField, isActive }) {
  useEffect(() => {
    updateField(
      'nextButtonDisabled',
      isActive && !newIntegrationData.tokenIsCopied,
    );
  }, [isActive, newIntegrationData.tokenIsCopied]);

  return (
    <div className="cmn-modal_info-section">
      <img src={tokenSlideIcons} alt="token-slide-icons" className="b-mb2" />
      <h1 className="b-h1 b-mb1">
        Copy token and configure with your identity provider
      </h1>
      <p className="b-txt b-mb3">
        Copy the access token below and store it somewhere safe. You will need
        to provide it to your IdP to authorize the integration. Once this
        connection is made, the user directory will begin importing users into
        Kandji.{' '}
        <a
          href="https://support.kandji.io/support/solutions/articles/72000560494"
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink b-decorate-off"
        >
          Learn more...
        </a>
      </p>
      <TextInput value={newIntegrationData.token} type="password" />
      <Button
        kind="link"
        icon="copy"
        onClick={() =>
          navigator.clipboard
            .writeText(newIntegrationData.token)
            .then(() => toaster(i18n.t('Copied to clipboard.')))
            .catch((e) => {
              console.log(e);
              toaster(i18n.t('Could not copy to clipboard.'));
            })
        }
        className="b-mb3 b-mt1"
      >
        Copy token
      </Button>
      <Checkbox
        label="I have copied the token and understand that I will have to rotate the token in order to see these details again."
        onChange={() =>
          updateField('newIntegrationData', (curr) => ({
            ...curr,
            tokenIsCopied: !curr.tokenIsCopied,
          }))
        }
        checked={newIntegrationData.tokenIsCopied}
        style={{ textAlign: 'left', alignItems: 'start' }}
      />
    </div>
  );
}

TokenSlide.propTypes = { ...SCIM_SLIDE_PROP_TYPES };

const NEXT_BUTTON_TEXT = ['Get started', 'Generate token', 'Done'];

function FooterButtons({
  onClose,
  currentStepIdx,
  setCurrentStepIdx,
  nextButtonDisabled,
  newIntegrationData,
  updateField,
}) {
  const history = useHistory();
  const [isGenerating, setIsGenerating] = useState(false);
  const onClickByStep = [
    () => setCurrentStepIdx((curr) => curr + 1),
    () => {
      setIsGenerating(true);
      newIntegrationsService
        .createScimIntegration(newIntegrationData.name)
        .then(({ data: { token } }) => {
          updateField('newIntegrationData', (curr) => ({ ...curr, token }));
          setCurrentStepIdx((curr) => curr + 1);
        })
        .catch((e) => {
          /* istanbul ignore next */
          if (e?.response?.data?.name?.includes('This field must be unique.')) {
            toaster(i18n.t('Name already exists. Please enter a unique name.'));
          } else {
            toaster(i18n.t('Encountered an error while generating token'));
          }
        })
        .finally(() => setIsGenerating(false));
    },
    () =>
      history.push(links.integrations.root, {
        type: INT_TYPES.scim,
        name: newIntegrationData.name,
      }),
  ];

  return (
    <div className="scim-welcome-modal_footer">
      {!!currentStepIdx && (
        <Stepper currentStepIdx={currentStepIdx - 1} stepsAmount={2} />
      )}
      <div>
        {currentStepIdx !== 2 && (
          <Button kind="outline" onClick={onClose} className="b-mr">
            Cancel
          </Button>
        )}
        <Button
          disabled={nextButtonDisabled || isGenerating}
          onClick={onClickByStep[currentStepIdx]}
          isProgress={isGenerating}
          icon={isGenerating ? 'arrows-rotate' : null}
        >
          {NEXT_BUTTON_TEXT[currentStepIdx]}
        </Button>
      </div>
    </div>
  );
}

FooterButtons.propTypes = {
  onClose: func.isRequired,
  currentStepIdx: number.isRequired,
  setCurrentStepIdx: func.isRequired,
  nextButtonDisabled: bool.isRequired,
  newIntegrationData: SCIM_INTEGRATION_SHAPE.isRequired,
  updateField: func.isRequired,
};

export { NameSlide, TokenSlide, FooterButtons, INITIAL_STATE, tickets };
