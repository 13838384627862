import { setI18nLocale, setI18nProvider } from '@kandji-inc/nectar-i18n';
import { i18n } from 'i18n';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FeatureFlags from '../config/feature-flags';

const mapStateToProps = ({ auth0, account }) => ({
  configlocale: auth0?.locale,
  accountLocale: account?.company?.locale,
});

function withLocale(BaseComponent) {
  function LocaleWrapper({ configlocale, accountLocale }) {
    // set provider
    useEffect(() => {
      const i18nProvider =
        localStorage.getItem('i18n-provider') ||
        FeatureFlags.getFlag('i18n-provider', i18n.DEFAULT_I18N_PROVIDER);
      setI18nProvider(i18nProvider);
    }, []);

    // set locale
    useEffect(() => {
      const initializeI18nLocale = async () => {
        // default locale for tenant is en
        const i18nLocale =
          localStorage.getItem('i18n-locale') || accountLocale || configlocale;
        await setI18nLocale(i18nLocale);
        document.documentElement.lang = i18nLocale;
      };

      // we update locale if ether the config or account data changes
      if (configlocale === accountLocale) {
        return;
      }

      initializeI18nLocale();
    }, [configlocale, accountLocale]);

    return <BaseComponent />;
  }
  return connect(mapStateToProps)(LocaleWrapper);
}

export default withLocale;
