import { Box, Flex, Loader } from '@kandji-inc/nectar-ui';
import { useContext, useState } from 'react';
import { DataTable } from 'src/components';
import { Pagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import { usePagination } from 'src/features/visibility/prism/hooks';
import { transformDeviceVulnerabilitiesFilterToApi } from '../all-vulnerabilities/transformers/transformToApi';
import { NoResultsFound } from '../common/table/no-results-found';
import { NoVulnerabilities } from '../common/table/no-vulnerabilities';
import { getColumns } from '../common/table/table-columns';
import { useGetDeviceVulnerabilities } from '../hooks/use-get-device-vulnerabilities';
import { useVulnerabilitySort } from '../hooks/use-vulnerability-sort';
import useVulnerability from '../store';
import { FilterBar } from './filter-bar';
import { SplitView } from './split-view';

const VulnerabilitiesTable = (props: { computerId: string }) => {
  const { computerId } = props;

  useAdjustSidebarChatBubble();
  const { paginationState: pagination, setPagination } = usePagination();
  const { sidebarDocked } = useContext(InterfaceContext);
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;

  const columns = getColumns({ isOnDeviceRecord: true });

  const [selectedVulnerability, setSelectedVulnerability] = useState(null);
  const filter = useVulnerability((state) => state.deviceVulnerabilitiesFilter);

  const { sort, sortBy, handleSort } = useVulnerabilitySort();

  const { vulnerabilities, count, isLoading } = useGetDeviceVulnerabilities(
    computerId,
    [pagination, sortBy, filter],
    {
      size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      sort_by: sortBy,
      filter: transformDeviceVulnerabilitiesFilterToApi(filter),
      search: filter.term,
    },
  );

  const getPaginationOffset = /* istanbul ignore next */ () => {
    let offset = 0;

    if (sidebarDocked) {
      offset += SIDEBAR_DOCKED_OFFSET;
    } else {
      offset += SIDEBAR_CLOSE_OFFSET;
    }

    if (selectedVulnerability) {
      offset += 448;
    }

    return offset;
  };

  const hasAtLeastOneNonDateFilterApplied =
    filter.term || filter.severity.length > 0;
  const hasVulnerabilities = vulnerabilities?.length > 0;
  const hasNoVulnerabilities = !isLoading && !hasVulnerabilities;

  return (
    <Box hFull css={{ position: 'relative' }}>
      {selectedVulnerability && (
        <SplitView
          selectedVulnerability={selectedVulnerability}
          clearSelectedVulnerability={() => setSelectedVulnerability(null)}
        />
      )}

      <FilterBar />

      <Box
        css={{
          // Provide extra space at the bottom of the scroll container so that
          // the ellipsis menus don't get overlapped by the chat bubble
          ':where(table)': {
            marginBottom: '100px',
          },

          // since we have space between last row and the bottom of the
          // scroll container, we must manually add a bottom border
          ':where(tbody) tr:last-of-type': {
            borderBottom: '1px solid $neutral30',
          },

          // Apply margin between left edge of the table and the first column header
          ':where(thead) th:first-of-type': {
            paddingLeft: '36px',
          },

          // Apply margin between left edge of the table and the first column data cell
          ':where(tbody) td:first-of-type': {
            paddingLeft: '36px',
          },

          // Ensure ellipsis menu does not increase the height of each row
          ':where(tbody) td:last-of-type': {
            padding: 0,
          },
        }}
      >
        <DataTable
          // @ts-ignore - type Vulnerability is specified which is more clear that the expected `any` type
          columns={columns}
          data={vulnerabilities}
          pinnedColumns={[]}
          offsets={{}}
          onRowClick={(row) => setSelectedVulnerability(row)}
          sort={{ sortState: sort, setSortState: handleSort }}
          css={{
            height: 'calc(100vh - 321px)',
            maxHeight: isLoading || hasNoVulnerabilities ? '36px' : '',
          }}
        />

        {isLoading && (
          <Flex
            hFull
            alignItems="center"
            justifyContent="center"
            css={{ paddingTop: '225px' }}
          >
            <Loader size="lg" data-testid="loader" />
          </Flex>
        )}

        {hasNoVulnerabilities && hasAtLeastOneNonDateFilterApplied && (
          <Flex pt9 justifyContent="center">
            <NoResultsFound />
          </Flex>
        )}

        {hasNoVulnerabilities && !hasAtLeastOneNonDateFilterApplied && (
          <Flex pt9 justifyContent="center">
            <NoVulnerabilities
              firstDetectedFilter={null}
              lastDetectedFilter={filter.detectionDate}
              onDeviceRecord
            />
          </Flex>
        )}

        <Box
          css={{
            position: 'fixed',
            bottom: 0,
            padding: '$3 $5',
            borderTop: '1px solid $neutral20',
            backgroundColor: '$neutral0',
            width: `calc(100% - ${getPaginationOffset()}px)`,
          }}
        >
          <Pagination
            currentPage={pagination.pageIndex + 1}
            totalItems={count}
            itemsPerPage={pagination.pageSize}
            onPageChange={
              /* istanbul ignore next */ (page) =>
                setPagination({ ...pagination, pageIndex: page - 1 })
            }
            onItemsPerPageChange={
              /*  istanbul ignore next */ (itemsPerPage) => {
                setPagination({
                  ...pagination,
                  pageIndex: 0,
                  pageSize: itemsPerPage,
                });
              }
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export { VulnerabilitiesTable };
