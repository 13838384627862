/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useNodes } from 'reactflow';
import { useShallow } from 'zustand/react/shallow';

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  type IconNames,
  Text,
  styled,
  theme,
} from '@kandji-inc/nectar-ui';
import Tippy from '@tippyjs/react';

import { useFlags } from 'src/config/feature-flags';
import {
  apiTypes,
  deviceTypes,
  installTypes,
  singularizeName,
  updateOnlyIconName,
} from 'src/features/library-items/library/common';
import type { LibraryItem } from '../blueprint-flow.types';
import ManualExclusions from './ManualExclusions';

import { deviceKinds, deviceToIcon } from '../constants';
import {
  HOTFIX__hasConflictingItemInMap,
  countLibraryItemInNodes,
  getDeviceRunsOnCamelCase,
  getItemConfig,
} from '../helpers';
import useComputers from '../services/use-computers';
import useBlueprintFlow from '../store';
import { TippyContainer } from '../theme';

const LIDrawer = styled(Flex, {
  position: 'absolute',
  right: 0,
  top: '43px',
  height: 'calc(100% - 45px)',
  width: '422px',
  overflowY: 'scroll',
  padding: '20px',
  gap: '30px',
  backgroundColor: '$neutral0',
  borderTop: '1px solid $neutral10',
  borderLeft: '1px solid $neutral10',
  boxShadow: '$elevation1',

  '& .tippy-content': {
    padding: 0,
  },
  '& .tippy-arrow::before': {
    color: '$neutral90',
  },

  variants: {
    isEditing: {
      true: { top: '43px' },
      false: { top: '53px', height: 'calc(100% - 53px)' },
    },
  },
});

const LIDetails = styled(Box, {});
const ManualExclusionsContainer = styled(Box, {});

type LibraryItemDrawerProps = {
  item: LibraryItem;
};

const LibraryItemDrawer = (props: LibraryItemDrawerProps) => {
  const { item } = props;

  const { 'dc-819-am-device-exclusions': LD_AM_DeviceExclusions } = useFlags([
    'dc-819-am-device-exclusions',
  ]);

  const nodes = useNodes();
  const [
    blueprint,
    isEditingAssignments,
    conflictingLibraryItems,
    libraryItems,
    clearSelectedAssignmentLibraryItems,
    model,
    setSelectedExclusionLibraryItemId,
    selectedExclusionLibraryItemId,
  ] = useBlueprintFlow(
    useShallow((state) => [
      state.blueprint,
      state.isEditingAssignments,
      state.conflictingLibraryItems,
      state.libraryItems,
      state.clearSelectedAssignmentLibraryItems,
      state.model,
      state.setSelectedExclusionLibraryItemId,
      state.selectedExclusionLibraryItemId,
    ]),
  );
  const libraryItemDeviceExclusions = model?.library_item_exclusions?.find(
    (exclusion) => exclusion.library_item_id === item.id,
  )?.computer_ids;

  // Fetch the excluded device details for this library item:
  const { computers: excludedDevicesResponse = [] } = useComputers(
    ['am-excluded-devices', item.id],
    {
      id__in: libraryItemDeviceExclusions?.join(','),
      sizePerPage: 1000, // there should never be more than 1000 devices excluded
    },
    libraryItemDeviceExclusions?.length > 0,
  );

  const itemConfig = getItemConfig(item);
  const itemInFlowCount = countLibraryItemInNodes(nodes, item.id);
  const deviceRunsOn = deviceKinds.filter(
    (device) => item[getDeviceRunsOnCamelCase(device)],
  );

  const TEMP__hasConflictingItemInMap = HOTFIX__hasConflictingItemInMap(
    item,
    nodes,
    libraryItems,
  );

  const blueprintLibraryItems = blueprint.libraryItems;

  const statusCountOptions = [
    {
      key: 'successCount',
      label: 'Success',
      icon: 'circle-check',
      theme: theme.colors.green50.value,
    },
    {
      key: 'failingCount',
      label: 'Error',
      icon: 'octagon-exclamation',
      theme: theme.colors.red50.value,
    },
    {
      key: 'pendingCount',
      label: 'Other',
      icon: 'octagon-minus',
      theme: theme.colors.neutral60.value,
    },
  ];

  const hasMiddleSection =
    // Install types
    item.isSelfService ||
    item.installEnforcement === installTypes.CONTINUOUS ||
    // Installs on devices
    deviceRunsOn.length > 0 ||
    // Map conflict
    TEMP__hasConflictingItemInMap;
  // conflictingLibraryItems.includes(item.flowId);

  if (!itemConfig) {
    return null;
  }

  return (
    <LIDrawer
      isEditing={isEditingAssignments}
      flow="column"
      justifyContent="space-between"
      gap="none"
    >
      <LIDetails>
        <Flex
          justifyContent="space-between"
          css={{ marginBottom: '30px', gap: '2px' }}
        >
          <Flex gap="md" flex={1} alignItems="start">
            <img
              style={{ width: '43px', height: '43px' }}
              src={item.icon || itemConfig.icon}
              alt="Icon"
              data-testid="drawer-icon"
            />
            <Box css={{ flex: 1, width: 0 }}>
              <Heading
                size="2"
                css={{
                  lineHeight: '36px',
                  wordWrap: 'break-word',
                }}
              >
                {item.name}
              </Heading>
              <Text
                size="1"
                variant="description"
                css={{ fontWeight: '$medium' }}
              >
                {itemConfig.name}
                {item.instanceName && ` - ${item.instanceName}`}
              </Text>
            </Box>
          </Flex>
          <Flex gap="xs" alignItems="start">
            <Link
              to={itemConfig.getUrl({ id: item.id })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                compact
                variant="subtle"
                icon={{ name: 'arrow-up-right-from-square' }}
              />
            </Link>
            <Button
              compact
              variant="subtle"
              icon={{ name: 'xmark' }}
              onClick={() => {
                if (selectedExclusionLibraryItemId) {
                  setSelectedExclusionLibraryItemId(null);
                } else {
                  clearSelectedAssignmentLibraryItems();
                }
              }}
              data-testid="close-drawer"
            />
          </Flex>
        </Flex>
        {!isEditingAssignments && item.type !== apiTypes.DEP_PROFILE && (
          <Box
            css={{
              borderBottom: '1px solid $neutral20',
              paddingBottom: '30px',
              marginBottom: '30px',
            }}
          >
            <Flex css={{ gap: '$6' }}>
              {statusCountOptions.map(({ key, label, icon, theme }) => {
                const itemStatus = blueprintLibraryItems.find(
                  (bpItem) => bpItem.id === item.id,
                );
                if (!itemStatus) {
                  return null;
                }
                return (
                  <Flex key={key} gap="xs">
                    <Icon size="sm" color={theme} name={icon as IconNames} />
                    <Text
                      size="1"
                      css={{ fontWeight: '$medium', color: '$neutral70' }}
                    >
                      {itemStatus[key]} {label}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </Box>
        )}
        {hasMiddleSection && (
          <Flex
            flow="column"
            css={{
              gap: '20px',
            }}
          >
            {(item.isSelfService ||
              item.installEnforcement === installTypes.CONTINUOUS) && (
              <Flex flow="column" css={{ gap: '6px' }}>
                {item.isSelfService && (
                  <Flex alignItems="center" gap="xs">
                    <Icon
                      style={{ height: '18px', width: '18px' }}
                      name="kandji-logo"
                    />
                    <Text css={{ lineHeight: '20px' }}>Self Service</Text>
                  </Flex>
                )}
                {item.installEnforcement === installTypes.CONTINUOUS && (
                  <Flex alignItems="center" gap="xs">
                    <Icon
                      style={{ height: '18px', width: '18px' }}
                      name={item.isUpdateOnly ? updateOnlyIconName : 'infinity'}
                    />
                    <Text css={{ lineHeight: '20px' }}>
                      {item.isUpdateOnly
                        ? 'Update Only'
                        : 'Continuously enforced'}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
            {deviceRunsOn.length > 0 && (
              <Box>
                <Text size="1" css={{ marginBottom: '6px' }}>
                  Installs on:
                </Text>
                <Flex>
                  {deviceRunsOn.map((device) => (
                    <Tippy
                      key={device}
                      content={
                        <TippyContainer gap="xs">
                          {deviceTypes[device.toUpperCase()]}
                        </TippyContainer>
                      }
                      appendTo="parent"
                    >
                      <Box>
                        <Icon
                          style={{ height: '20px', width: '20px' }}
                          name={deviceToIcon[device]}
                        />
                      </Box>
                    </Tippy>
                  ))}
                </Flex>
              </Box>
            )}
            {
              /* conflictingLibraryItems.includes(item.flowId) */ TEMP__hasConflictingItemInMap && (
                <Flex gap="xs" alignItems="center">
                  <Icon name="square-stack-slash-fill" size="sm" />
                  <Text>This Library Item is self-conflicting</Text>
                  <Tippy
                    appendTo="parent"
                    content={
                      <TippyContainer>{`Only one ${singularizeName(
                        itemConfig.name,
                      )} Library Item may be assigned per device. Devices which qualify for more than one will receive whichever is scoped to them last (farthest right) on the map.`}</TippyContainer>
                    }
                  >
                    <Flex>
                      <Icon name="circle-info" size="sm" />
                    </Flex>
                  </Tippy>
                </Flex>
              )
            }
          </Flex>
        )}
        {itemInFlowCount > 0 && (
          <Box
            css={{
              ...(hasMiddleSection && {
                borderTop: '1px solid $neutral20',
                paddingTop: '30px',
                marginTop: '30px',
                marginBottom: '$2',
              }),
            }}
          >
            <Text>
              Used in this map <b>{itemInFlowCount}</b> time
              {itemInFlowCount === 1 ? '' : 's'}
            </Text>
          </Box>
        )}
      </LIDetails>

      {LD_AM_DeviceExclusions && itemInFlowCount > 0 && (
        <ManualExclusionsContainer>
          <ManualExclusions
            libraryId={item.id}
            deviceIds={libraryItemDeviceExclusions}
            canEdit={isEditingAssignments}
            excludedDevicesRaw={excludedDevicesResponse}
          />
        </ManualExclusionsContainer>
      )}
    </LIDrawer>
  );
};

export default LibraryItemDrawer;
