/* istanbul ignore file */
import featureFlags from 'src/config/feature-flags';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import configs from '../../../library/library-item-configurations/items/profile';

import { getEnforcementOptions, getRestartOptions } from '../constants';
import initialState from '../initial-state';

function transformSettings(settings) {
  const LDFFfileVaultAde = featureFlags.getFlag('LIT-WWDC23-filevault-ade');

  const ENFORCEMENT_OPTIONS = getEnforcementOptions();
  const RESTART_OPTIONS = getRestartOptions();

  return {
    enforcement: {
      enforcementOption:
        settings?.DeferForceAtUserLoginMaxBypassAttempts > 0
          ? ENFORCEMENT_OPTIONS.ALLOW_DEFERRAL
          : ENFORCEMENT_OPTIONS.ENFORCE_IMMEDIATELY,
      DeferForceAtUserLoginMaxBypassAttempts:
        settings?.DeferForceAtUserLoginMaxBypassAttempts,
      DeferDontAskAtUserLogout: settings?.DeferDontAskAtUserLogout,
      ...(LDFFfileVaultAde && {
        ForceEnableInSetupAssistant: Boolean(
          settings?.ForceEnableInSetupAssistant,
        ),
      }),
      restartOption: settings?.ForceRestartAfter
        ? RESTART_OPTIONS.FORCE
        : RESTART_OPTIONS.REMIND,
      ForceRestartAfter:
        settings?.ForceRestartAfter ||
        initialState().enforcement.ForceRestartAfter,
      RemindRestartEvery:
        settings?.RemindRestartEvery ||
        initialState().enforcement.RemindRestartEvery,
    },
    recoveryKeys: {
      ShowRecoveryKey: settings?.ShowRecoveryKey,
      EscrowRecoveryKey: settings?.EscrowRecoveryKey,
      autoRotateKeys: Boolean(
        settings?.EscrowRecoveryKey && settings?.AutomaticallyRotateKeysAfter,
      ),
      AutomaticallyRotateKeysAfter:
        settings?.AutomaticallyRotateKeysAfter ||
        initialState().recoveryKeys.AutomaticallyRotateKeysAfter,
    },
  };
}

async function transformFromNewApi(apiData, preset) {
  const result = {
    ...preset,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return result;
}

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    rules: apiData.rules,
  };

  const transformer = transformFromNewApi;

  const result = await transformer(apiData, commonData);

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const LDFFfileVaultAde = featureFlags.getFlag('LIT-WWDC23-filevault-ade');

  const ENFORCEMENT_OPTIONS = getEnforcementOptions();
  const RESTART_OPTIONS = getRestartOptions();

  const toSend = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    rules: model.rules || null,
    data: {
      // Recovery Keys:
      ...(model.recoveryKeys?.ShowRecoveryKey ? { ShowRecoveryKey: true } : {}),
      ...(model.recoveryKeys?.EscrowRecoveryKey
        ? { EscrowRecoveryKey: true }
        : {}),
      ...(model.recoveryKeys?.EscrowRecoveryKey &&
      model.recoveryKeys?.autoRotateKeys
        ? {
            AutomaticallyRotateKeysAfter:
              model.recoveryKeys?.AutomaticallyRotateKeysAfter,
          }
        : {}),
    },
  };

  // Enforcement settings:
  if (
    model.enforcement.enforcementOption.value ===
    ENFORCEMENT_OPTIONS.ENFORCE_IMMEDIATELY.value
  ) {
    // Note: This ignores the `DeferForceAtUserLoginMaxBypassAttempts` setting
    toSend.data.DeferDontAskAtUserLogout =
      model.enforcement.DeferDontAskAtUserLogout;

    if (model.enforcement.DeferDontAskAtUserLogout) {
      if (
        model.enforcement.restartOption.value === RESTART_OPTIONS.FORCE.value
      ) {
        toSend.data.ForceRestartAfter = model.enforcement.ForceRestartAfter;
      }

      if (
        model.enforcement.restartOption.value === RESTART_OPTIONS.REMIND.value
      ) {
        toSend.data.RemindRestartEvery = model.enforcement.RemindRestartEvery;
      }
    }

    // Reset the `DeferForceAtUserLoginMaxBypassAttempts` to 0:
    toSend.data.DeferForceAtUserLoginMaxBypassAttempts = 0;

    if (LDFFfileVaultAde) {
      toSend.data.ForceEnableInSetupAssistant = Boolean(
        model.enforcement.ForceEnableInSetupAssistant,
      );
    }
  } else {
    toSend.data.DeferForceAtUserLoginMaxBypassAttempts =
      model.enforcement.DeferForceAtUserLoginMaxBypassAttempts;
  }

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  // Add necessary properties when creating new Profile:
  if (!model.id) {
    const itemConfig = configs.FileVault;

    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
