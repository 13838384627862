import {
  Box,
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Icon,
  ScrollArea,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { usePrismUrlContext } from '../../contexts/PrismUrlContext';
import type { SavedView } from './hooks/use-saved-views-query';

const StyledIconButton = styled(Button, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  '& > svg': {
    height: '20px',
    width: '20px',
    color: '$neutral70',
  },
  '&:focus': {
    boxShadow: 'none!important',
  },
});

const StyledInnerButton = styled('span', {
  fontSize: '$4',
  color: '$neutral90',
  fontWeight: '$medium',
  border: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const CheckboxItem = React.forwardRef(
  (
    {
      children,
      checked = false,
      onCheckedChange,
    }: {
      children: React.ReactNode;
      checked: boolean;
      onCheckedChange: (checked: boolean) => void;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <DropdownMenu.CheckboxItem
      ref={ref}
      checked={checked}
      onCheckedChange={onCheckedChange}
      css={{
        all: 'unset',
        display: 'flex',
        padding: '6px 12px',
        gap: '$1',
        border: '2px solid transparent',
        position: 'relative',
        userSelect: 'none',
        '&[data-highlighted]': {
          border:
            '2px solid var(--Dropdown-dropdown-ring-focus, rgba(16, 106, 242, 0.36))',
          background:
            'var(--Dropdown-dropdown-surface-selected-enabled, rgba(170, 202, 255, 0.36))',
          color: 'var(--Dropdown-dropdown-content-selected, #106AF2)',
        },
      }}
    >
      <Box
        css={{
          width: '175px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Box>
      <DropdownMenu.ItemIndicator
        css={{
          '& > svg': {
            height: '20px',
            width: '20px',
          },
        }}
      >
        <Icon name="fa-check-14px" />
      </DropdownMenu.ItemIndicator>
    </DropdownMenu.CheckboxItem>
  ),
);

interface SavedViewsDropdownProps {
  savedViewsQuery: any;
}

export const SavedViewsDropdown = ({
  savedViewsQuery,
}: SavedViewsDropdownProps) => {
  const [selectedView, setSelectedView] = React.useState<SavedView | null>(
    null,
  );
  const [savedViewsSearch, setSavedViewsSearch] = React.useState<string>('');
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const firstItemRef = React.useRef<HTMLDivElement>(null);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSavedViewsSearch(event.target.value);
  };
  const shouldShowSearch = savedViewsQuery.data?.length > 5;
  const { data, isPending } = savedViewsQuery;
  const { viewId, setViewId } = usePrismUrlContext();

  React.useEffect(() => {
    if (data?.length > 0 && viewId) {
      setSelectedView(data.find((view) => view.id === viewId));
    } else {
      setSelectedView(null);
    }
  }, [data, viewId]);

  if (isPending) {
    return null;
  }

  if (data?.length === 0) {
    return null;
  }

  const views = data ?? [];
  const filteredViews = views.filter((view) =>
    view.name.toLowerCase().includes(savedViewsSearch.toLowerCase()),
  );

  const handleDropdownOpenChange = (open: boolean) => {
    setDropdownOpen(open);
    if (open && shouldShowSearch) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
    if (!open) {
      setSavedViewsSearch('');
    }
  };

  return (
    <DropdownMenu.Root
      open={dropdownOpen}
      onOpenChange={handleDropdownOpenChange}
    >
      <DropdownMenu.Trigger asChild>
        <StyledIconButton
          variant="subtle"
          compact
          css={{
            backgroundColor: 'transparent',
            border: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              border: 'none',
            },
            '&:focus': {
              border: 'none',
            },
            '&:active': {
              border: 'none',
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          {selectedView?.name ? (
            <StyledInnerButton>{selectedView.name}</StyledInnerButton>
          ) : (
            ''
          )}
          <Icon name="fa-caret-down-solid-small" />
        </StyledIconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        css={{
          zIndex: 3,
        }}
        align="start"
      >
        <DropdownMenu.Label
          css={{
            padding: '6px 12px',
            textTransform: 'none',
            letterSpacing: 'normal',
          }}
        >
          {i18n.t('Saved views')}
        </DropdownMenu.Label>
        {shouldShowSearch && (
          <TextField
            css={{
              width: '216px',
              padding: '4px',
            }}
            placeholder={i18n.t('Search views')}
            value={savedViewsSearch}
            onChange={handleSearchChange}
            ref={searchInputRef}
            onKeyDown={(event) => {
              if (event.key === 'ArrowDown') {
                event.preventDefault();
                firstItemRef.current?.focus();
              } else {
                event.stopPropagation();
              }
            }}
            id="saved-views-search"
            name="saved-views-search"
          />
        )}
        <ScrollArea
          css={{
            maxHeight: '240px',
            height: `${filteredViews.length * 38}px`,
            width: '216px',
          }}
          scrollbarPosition="right"
          compact
        >
          {filteredViews
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((view, index) => (
              <CheckboxItem
                ref={index === 0 ? firstItemRef : undefined}
                key={view.id}
                checked={view.id === viewId}
                onCheckedChange={() => {
                  // istanbul ignore next
                  if (view.id) {
                    setViewId(view.id);
                  }
                }}
              >
                {view.name}
              </CheckboxItem>
            ))}
          {filteredViews.length === 0 && (
            <DropdownMenu.Item
              css={{
                padding: '6px 12px',
                textTransform: 'none',
                letterSpacing: 'normal',
              }}
            >
              {i18n.t('No saved views')}
            </DropdownMenu.Item>
          )}
        </ScrollArea>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
