import { securityWithLoginWindowConfigurationValues } from '../../../protocols-card/protocols-card.model';
/* prettier-ignore */
import WifiService from '../../wifi-service';

const { keys } = WifiService;
const authKeys = keys.authentication;
const certTrustKeys = keys.trust;

const getSetupModes = (
  model,
  { isEnterprise, isAd, isOd, isUsernamePassword },
) => {
  const { authentication } = model;
  const setupModes = new Set();

  const isScep =
    isEnterprise &&
    authentication[authKeys.identityCertificate] ===
      WifiService.identityCertificates.SCEP;

  if (isEnterprise && authentication[authKeys.useLoginWindow]) {
    setupModes.add('Loginwindow');
  }

  if (isScep || isAd || isOd || isUsernamePassword) {
    setupModes.add('System');
  }

  return setupModes.size ? Array.from(setupModes) : null;
};

const getAuthentication = (model) => {
  const { authentication, trust } = model;

  const isEnterprise = securityWithLoginWindowConfigurationValues.includes(
    authentication[authKeys.securityType],
  );

  const hasAuthentication =
    isEnterprise &&
    [
      WifiService.protocols.TLS,
      WifiService.protocols.TTLS,
      WifiService.protocols.LEAP,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((protocol) =>
      authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => protocol === value,
      ),
    );

  const isAd =
    hasAuthentication &&
    authentication[authKeys.acceptedEapTypes.authentication] ===
      WifiService.authentications.COMPUTER_AD;

  const isOd =
    hasAuthentication &&
    authentication[authKeys.acceptedEapTypes.authentication] ===
      WifiService.authentications.COMPUTER_OD;

  const isUsernamePassword =
    isEnterprise &&
    hasAuthentication &&
    authentication?.[authKeys.acceptedEapTypes.authentication] ===
      WifiService.authentications.USERNAME_AND_PASSWORD;

  const isTTLS =
    isEnterprise &&
    authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => WifiService.protocols.TTLS === value,
    );
  const hasOuterIdentity =
    isEnterprise &&
    [
      WifiService.protocols.TTLS,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((protocol) =>
      authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => protocol === value,
      ),
    );

  const hasTLSVersions =
    isEnterprise &&
    [
      WifiService.protocols.TLS,
      WifiService.protocols.TTLS,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((protocol) =>
      authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => protocol === value,
      ),
    );

  const hasPac =
    isEnterprise &&
    authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => WifiService.protocols.EAP_FAST === value,
    );

  const hasRandValues =
    isEnterprise &&
    authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
      ({ value }) => WifiService.protocols.EAP_SIM === value,
    );

  const hasTwoFactorAuth =
    isEnterprise &&
    [
      WifiService.protocols.TTLS,
      WifiService.protocols.PEAP,
      WifiService.protocols.EAP_FAST,
    ].some((protocol) =>
      authentication[authKeys.acceptedEapTypes.acceptedEapTypes].find(
        ({ value }) => protocol === value,
      ),
    ) &&
    !authentication[authKeys.useLoginWindow] &&
    authentication[authKeys.acceptedEapTypes.twoFactorAuth];

  const transformTrustedCertsToApi = (certs = []) =>
    certs.map(({ name, size, type, data }) => ({
      fileName: name,
      fileSize: size,
      type,
      data,
    }));

  return {
    isEnterprise,
    trustedCertificates:
      isEnterprise && trust[certTrustKeys.specifyCertificates]
        ? transformTrustedCertsToApi(trust[certTrustKeys.certificates])
        : [],
    settings: {
      SetupModes: getSetupModes(model, {
        isEnterprise,
        isAd,
        isOd,
        isUsernamePassword,
      }),
      EAPClientConfiguration: isEnterprise
        ? {
            AcceptEapTypes: authentication[
              authKeys.acceptedEapTypes.acceptedEapTypes
            ]?.map(({ value }) => value),
            SystemModeCredentialsSource: isAd ? 'ActiveDirectory' : null,
            SystemModeUseOpenDirectoryCredentials: isOd || null,
            UserName:
              isUsernamePassword &&
              authentication[authKeys.acceptedEapTypes.username]
                ? authentication[authKeys.acceptedEapTypes.username]?.trim()
                : null,
            UserPassword:
              isUsernamePassword &&
              authentication[authKeys.acceptedEapTypes.password]
                ? authentication[authKeys.acceptedEapTypes.password]
                : null,
            OneTimeUserPassword: isUsernamePassword
              ? authentication[authKeys.acceptedEapTypes.promptForPassword] ===
                WifiService.promptPasswordValues.EVERY_TIME
              : null,
            TTLSInnerAuthentication: isTTLS
              ? authentication[authKeys.acceptedEapTypes.innerAuthentication]
              : null,
            OuterIdentity:
              hasOuterIdentity &&
              authentication[authKeys.acceptedEapTypes.outerIdentity]
                ? authentication[
                    authKeys.acceptedEapTypes.outerIdentity
                  ]?.trim()
                : null,
            TLSMinimumVersion: hasTLSVersions
              ? authentication[authKeys.acceptedEapTypes.tlsMinimumVersion]
              : null,
            TLSMaximumVersion: hasTLSVersions
              ? authentication[authKeys.acceptedEapTypes.tlsMaximumVersion]
              : null,
            EAPFASTUsePAC: hasPac
              ? authentication[authKeys.acceptedEapTypes.usePac]
              : null,
            EAPFASTProvisionPAC: hasPac
              ? authentication[authKeys.acceptedEapTypes.provisionPac]
              : null,
            EAPFASTProvisionPACAnonymously: hasPac
              ? authentication[
                  authKeys.acceptedEapTypes.provisionPacAnonymously
                ]
              : null,
            EAPSIMNumberOfRANDs: hasRandValues
              ? authentication[authKeys.acceptedEapTypes.randValue]
              : null,
            TLSCertificateIsRequired: hasTwoFactorAuth || null,
            // Certificate Trust fields
            TLSTrustedServerNames:
              isEnterprise && trust[certTrustKeys.specifyServerCertificateNames]
                ? trust[certTrustKeys.serverCertificateNames]?.map(
                    ({ value }) => value?.trim(),
                  )
                : null,
          }
        : null,
    },
  };
};

export default getAuthentication;
