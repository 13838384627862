import { z } from 'zod';

const ThreatsGroupSchema = z.object({
  data: z.array(
    z.object({
      latest_file_name: z.string(),
      threat_id: z.string(),
      latest_process_name: z.string().nullable(),
      classification: z.string(),
      last_detection_date: z.string(),
      first_detection_date: z.string(),
      device_count: z.number(),
      quarantined_count: z.number(),
      not_quarantined_count: z.number(),
      released_count: z.number(),
      resolved_count: z.number(),
    }),
  ),
  malware_count: z.number(),
  pup_count: z.number(),
  benign_count: z.number(),
  unknown_count: z.number(),
  count: z.number(),
  limit: z.number(),
  offset: z.number(),
});

export default ThreatsGroupSchema;
