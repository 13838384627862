import { timezones } from 'src/app/common/constants';
import {
  DEFAULT_DELAY_OPTION,
  DEFAULT_TIME_IDX,
  DELAY_OPTIONS,
  OLD_DELAY_VALUES,
  RSR_ENFORCEMENT_DELAY_OPTIONS,
  RSR_ENFORCEMENT_OPTIONS,
  TIME_OPTIONS,
  VERSION_ENFORCEMENT_OPTIONS,
} from './updates-card-constants';
import type {
  RSREnforcementKeys,
  RawUpdates,
  RawVersionDateRsr,
  SelectOption,
  VersionAutoAppsOption,
  VersionDateAutoApps,
} from './updates-card.types';

function buildVersionAutoAppOptions(
  versionDatesAutoApps: VersionDateAutoApps[],
): VersionAutoAppsOption[] {
  return (versionDatesAutoApps ?? []).map((versionDateAutoApp) => ({
    value: {
      version: versionDateAutoApp.version,
      build: versionDateAutoApp.build,
    },
    label: versionDateAutoApp.version_display,
  }));
}

function buildVersionOptions(
  versionDates: Record<string, unknown>,
): SelectOption[] {
  return Object.keys(versionDates ?? {}).map((version) => ({
    value: version,
    label: version,
  }));
}

function buildMinimumVersion(
  versionOptions: SelectOption[],
  minimumAppVersion: string,
) {
  return (
    versionOptions.find((option) => option.value === minimumAppVersion) ||
    versionOptions[0]
  );
}

function buildVersionEnforcement(versionEnforcement: string) {
  return (
    VERSION_ENFORCEMENT_OPTIONS.find(
      (option) => option.value === versionEnforcement,
    ) || VERSION_ENFORCEMENT_OPTIONS[0]
  );
}

function buildEnforcementDeadline(enforceAfter: string | number | Date) {
  return enforceAfter ? new Date(enforceAfter) : null;
}

function buildMinimumAutoAppVersion(
  versionAutoAppOptions: VersionAutoAppsOption[],
  minimumAutoAppVersion: VersionDateAutoApps | undefined,
  minimumAppVersion: string,
) {
  if (versionAutoAppOptions.length < 1) {
    return {};
  }

  const minimumVersionAutoAppVersion = versionAutoAppOptions.find(
    (option) =>
      option.value.version === minimumAutoAppVersion?.version &&
      option.value.build === minimumAutoAppVersion?.build,
  );

  const minimumVersionAppVersion = versionAutoAppOptions.find(
    (option) => option.value.version === minimumAppVersion,
  );

  return (
    minimumVersionAutoAppVersion ||
    minimumVersionAppVersion ||
    versionAutoAppOptions[0]
  );
}

const IMMEDIATE_ENFORCEMENT_DELAY = { value: '0', label: 'Immediate [DEV]' };

function buildEnforcementDelay(delay: string) {
  return (
    [IMMEDIATE_ENFORCEMENT_DELAY, ...DELAY_OPTIONS].find(
      (opt) => opt.value === delay,
    ) || DEFAULT_DELAY_OPTION
  );
}

function buildEnforcementTime(enforcementTime: string) {
  return (
    TIME_OPTIONS.find((opt) => opt.value === enforcementTime) ||
    TIME_OPTIONS[DEFAULT_TIME_IDX]
  );
}

function buildEnforcementTimezone(enforcementTimezone: string) {
  return timezones.find((tz) => tz.value === enforcementTimezone);
}

function buildRSREnforcement(rsrEnforcement: RSREnforcementKeys) {
  return (
    RSR_ENFORCEMENT_OPTIONS.find((option) => option.value === rsrEnforcement) ||
    RSR_ENFORCEMENT_OPTIONS[0]
  );
}
function buildRSREnforcementDelay(delay: string) {
  return (
    RSR_ENFORCEMENT_DELAY_OPTIONS.find((opt) => opt.value === delay) ||
    DEFAULT_DELAY_OPTION
  );
}

function buildRsrVersions(rsrVersions: RawVersionDateRsr[] = []) {
  const rsrMap = new Map();

  for (let i = 0; i < rsrVersions.length; i++) {
    const rsrVersion = rsrVersions[i];
    const rsrKey = `${rsrVersion.os_version}:${rsrVersion.extra_version}`;

    if (!rsrMap.has(rsrKey)) {
      rsrMap.set(rsrKey, {
        osVersion: rsrVersion.os_version,
        extraVersion: rsrVersion.extra_version,
        releaseDate: Math.round(
          new Date(rsrVersion.release_date).getTime() / 1000,
        ),
      });
    }
  }

  return Array.from(rsrMap.values());
}

export const getUpdatesModel = (customData: RawUpdates = {}) => {
  const {
    _version_dates: versionDates,
    _version_dates_auto_apps: versionDatesAutoApps,
    _rsr_version_dates: rsrVersions,
    enforcement_delay: enforcementDelay,
    version_enforcement: versionEnforcement,
    minimum_app_version: minimumAppVersion,
    minimum_auto_app_version: minimumAutoAppVersion,
    enforce_after: enforceAfter,
    enforcement_timezone: enforcementTimezone,
    enforcement_time: enforcementTime,
    rsr_enforcement: rsrEnforcement,
    rsr_enforcement_delay: rsrEnforcementDelay,
    rsr_enforcement_time: rsrEnforcementTime,
    details_url: detailsUrl,
  } = customData;

  const versionOptions = buildVersionOptions(versionDates);

  const versionAutoAppOptions =
    buildVersionAutoAppOptions(versionDatesAutoApps);

  const delay = OLD_DELAY_VALUES[enforcementDelay] || enforcementDelay;

  return {
    versions: versionDates ?? [],
    versionsAutoApps: versionDatesAutoApps ?? [],
    versionEnforcement: buildVersionEnforcement(versionEnforcement),
    minimumVersion: buildMinimumVersion(versionOptions, minimumAppVersion),
    versionOptions,
    versionAutoAppOptions,
    minimumAutoAppVersion: buildMinimumAutoAppVersion(
      versionAutoAppOptions,
      minimumAutoAppVersion,
      minimumAppVersion,
    ),
    enforcementDelay: buildEnforcementDelay(delay),
    enforcementDeadline: buildEnforcementDeadline(enforceAfter),
    enforcementTime: buildEnforcementTime(enforcementTime),
    enforcementTimezone: buildEnforcementTimezone(enforcementTimezone),
    rsrEnforcement: buildRSREnforcement(rsrEnforcement),
    rsrEnforcementDelay: buildRSREnforcementDelay(rsrEnforcementDelay),
    rsrEnforcementTime: buildEnforcementTime(rsrEnforcementTime),
    rsrVersions: buildRsrVersions(rsrVersions),
    detailsUrl,
  };
};
