// src/constants.ts
var DEFAULT_LOCALE = "en";
var REACT_APP_TRANSIFEX_CDS_HOST = window.REACT_APP_TRANSIFEX_CDS_HOST || process.env.REACT_APP_TRANSIFEX_CDS_HOST;
var REACT_APP_TRANSIFEX_TOKEN = window.REACT_APP_TRANSIFEX_TOKEN || process.env.REACT_APP_TRANSIFEX_TOKEN;

// src/providers/NoopProvider.tsx
import {
  MessageFormatRenderer,
  escape as txEscape,
  isString as txIsString
} from "@transifex/native";
import React from "react";

// src/providers/BaseProvider.tsx
import { jsx } from "react/jsx-runtime";
var BaseProvider = class {
  constructor() {
    this.currentLocale = DEFAULT_LOCALE;
  }
  setLocale(localeCode) {
    this.currentLocale = localeCode;
    return Promise.resolve();
  }
  getLocale() {
    return this.currentLocale;
  }
  translate(str, params) {
    throw new Error("Not implemented");
  }
  translateNode(str, params) {
    throw new Error("Not implemented");
  }
  translateWithHtml(str, params) {
    const newParams = { ...params, _escapeVars: true };
    const htmlStr = this.translate(str, newParams);
    return /* @__PURE__ */ jsx("span", {
      dangerouslySetInnerHTML: { __html: htmlStr }
    });
  }
};
BaseProvider.provider_name = "";

// src/providers/NoopProvider.tsx
import { Fragment, jsx as jsx2 } from "react/jsx-runtime";
var renderer = new MessageFormatRenderer();
var NoopProvider = class extends BaseProvider {
  translate(str, params) {
    let safeParams = {};
    if (params === void 0) {
      safeParams = {};
    } else if (params._escapeVars) {
      Object.keys(params).forEach((param) => {
        const value = params[param];
        safeParams[param] = txIsString(value) ? txEscape(value) : value;
      });
    } else {
      safeParams = params;
    }
    return renderer.render(str, this.currentLocale, safeParams);
  }
  translateNode(str, params) {
    const actualProps = {};
    const reactElements = [];
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (React.isValidElement(value)) {
          actualProps[key] = `__txnative__${reactElements.length}__txnative__`;
          reactElements.push(value);
        } else {
          actualProps[key] = value;
        }
      });
    }
    const translation = this.translate(str, actualProps);
    const result = [];
    let lastEnd = 0;
    let lastKey = 0;
    const regexp = RegExp("__txnative__(\\d+)__txnative__", "g");
    let match = regexp.exec(translation);
    while (match !== null) {
      const chunk2 = translation.slice(lastEnd, match.index);
      if (chunk2) {
        result.push(/* @__PURE__ */ jsx2(React.Fragment, {
          children: chunk2
        }, lastKey));
        lastKey += 1;
      }
      result.push(
        React.cloneElement(reactElements[parseInt(match[1], 10)], {
          key: lastKey
        })
      );
      lastKey += 1;
      lastEnd = match.index + match[0].length;
      match = regexp.exec(translation);
    }
    const chunk = translation.slice(lastEnd);
    if (chunk) {
      result.push(/* @__PURE__ */ jsx2(React.Fragment, {
        children: chunk
      }, lastKey));
    }
    if (result.length === 0) {
      return "";
    }
    if (result.length === 1) {
      return result[0].props.children;
    }
    return /* @__PURE__ */ jsx2(Fragment, {
      children: result
    });
  }
};
NoopProvider.provider_name = "noop";

// src/providers/QAProvider.tsx
var QAProvider = class extends NoopProvider {
  translate(str, params) {
    return `//${super.translate(str, params)}//`;
  }
};
QAProvider.provider_name = "qa";

// src/providers/TransifexProvider.tsx
import { t, tx } from "@transifex/native";
import { T } from "@transifex/react";
import { jsx as jsx3 } from "react/jsx-runtime";
var TransifexProvider = class extends BaseProvider {
  constructor(cdsHost, token) {
    super();
    tx.init({ cdsHost, token });
  }
  setLocale(localeCode) {
    this.currentLocale = localeCode;
    return tx.setCurrentLocale(localeCode);
  }
  translate(str, params) {
    return t(str, params);
  }
  translateNode(str, params) {
    return /* @__PURE__ */ jsx3(T, {
      _str: str,
      ...params
    });
  }
};
TransifexProvider.provider_name = "transifex";

// src/index.ts
var provider;
var getI18nProviderByName = (providerName) => {
  switch (providerName) {
    case QAProvider.provider_name:
      return new QAProvider();
    case TransifexProvider.provider_name: {
      const cdsHost = REACT_APP_TRANSIFEX_CDS_HOST;
      const token = REACT_APP_TRANSIFEX_TOKEN;
      if (cdsHost && token) {
        return new TransifexProvider(cdsHost, token);
      }
      console.error("Missing Transifex environment variables");
    }
  }
  return new NoopProvider();
};
var setI18nProvider = (providerName) => {
  provider = getI18nProviderByName(providerName);
};
var setI18nLocale = (localeCode) => {
  if (!provider) {
    throw new Error("i18n provider must be set before using setI18nLocale");
  }
  return provider.setLocale(localeCode);
};
var getI18nLocale = () => {
  if (!provider) {
    throw new Error("i18n provider must be set before using getI18nLocale");
  }
  return provider.getLocale();
};
var render = (cb) => {
  if (!provider) {
    throw new Error("i18n provider must be set before using render");
  }
  return cb(getI18nLocale());
};
var t2 = (str, params) => {
  if (!provider) {
    throw new Error(
      `i18n provider must be set before using t('${str}'). Is it executing before runtime?`
    );
  }
  return provider.translate(str, params);
};
var $t = (str, params) => {
  if (!provider) {
    throw new Error(
      `i18n provider must be set before using $t('${str}'). Is it executing before runtime?`
    );
  }
  return provider.translateNode(str, params);
};
var ut = (str, params) => {
  if (!provider) {
    throw new Error(
      `i18n provider must be set before using ut('${str}'). Is it executing before runtime?`
    );
  }
  return provider.translateWithHtml(str, params);
};
export {
  $t,
  getI18nLocale,
  render,
  setI18nLocale,
  setI18nProvider,
  t2 as t,
  ut
};
